@import "../../../abstract/mixins";
@import "./_filter.scss";
@import "./filterModal";
@import "./subCategories";
.products-category {
    min-height: 60vh;
    margin-bottom: 80px;
    .products-filter-component-ads {
        width: 100%;
        margin-top: 20px;
    }
}
.CategoryTitle {
    padding: 40px 0;
    span {
        color: $primary-text-color;
        font-family: "poppinsRegular";
        font-size: 16px;
    }

    h1 {
        font-size: 30px;
        font-family: "poppinsRegular";
        color: #515151;
        margin: 0;
        text-transform: capitalize;
    }

    h6 {
        font-size: 11px;
        color: #a8a8a8;
        font-family: "poppinsRegular";
    }
}
.products-category__ads {
    width: 100%;
    margin: 25px 0 50px 0;
}
.Category-ViewOption {
    align-items: center;
}
.ProductView {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 30px;
    // gap: 5px;
    // flex-wrap: wrap;
    // padding: 6px 10px;
    .sortBy-and-display-select {
        display: flex;
        // justify-content: space-between;
        align-items: center;
        background-color: $primary-background-color;
        padding: 0 10px;
        column-gap: 5px;
        // width: 192px;
        width: 230px;

        p {
            // width: 50px;
            margin: 0;
            font-size: 13px;
            color: #fff;
            font-family: $PoppinsSemiBold;
            white-space: nowrap;
        }
        .dropdown {
            width: inherit;
            .dropdown-toggle {
                &::after {
                    background-image: url("../../../../../12.svg");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    border: 0;
                    width: 8px;
                    height: 14px;
                    // transform: rotate(90deg);
                    // opacity: 1;
                    // filter: brightness(5000);
                }
            }
            .btn-primary {
                color: #515151;
                background-color: transparent;
                transition: ease-in-out all 0.2s;
                // width: 200px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #fff;
                padding: 0;
                font-size: 13px;
                // padding-bottom: 3px;
                width: 100%;
            }

            .dropdown-menu a {
                display: flex;
                justify-content: unset;
                grid-gap: 10px;
                gap: 10px;
                height: 34px;
                padding: 6px 10px;
            }
            .dropdown-menu {
                inset: 0px 0px auto 0px !important;

                // inset: 0px 0px auto -94px !important;
                min-width: 163px !important;
                // min-width: 136px !important;
                padding: 0;
                // transform: translate(-14px, 25px) !important;
                transform: translate(-3px, 25px) !important;

                // display: flex;
                // flex-direction: column;
                button {
                    width: 100%;
                    text-align: left;
                    padding: 5px;
                    border-bottom: 0.5px solid #fff;
                    background-color: $primary-background-color;
                    transition: all ease-in-out 0.3s;
                    color: #fff;
                    font-size: 12px;

                    &:hover {
                        background-color: #fff;
                        color: $primary-text-color;
                        transition: all ease-in-out 0.3s;
                    }
                }
            }
        }
        // DropDown End
    }
    .ProductView__items-count {
        column-gap: 5px;
        h3 {
            font-family: $PoppinsSemiBold;
            font-size: 20px;
            margin-bottom: 0;
        }
        span {
            font-family: $poppinsExtraLight-font;
            font-size: 20px;
        }
    }
}

.products-category-arabic {
    .ProductView .sortBy-and-display-select .dropdown .dropdown-menu {
        transform: translate(-11px, 25px) !important;
    }
}
.ProductView {
    .grid-filter-full-ul {
        li {
            background-color: #e9e9e9;
        }
    }
}

.FullViewCard {
    display: flex;
    column-gap: 24px;
    // padding: 17px;
    width: 100%;

    h5 {
        color: $primary-text-color;
    }
    .product__price {
        display: flex;
        justify-content: space-between;
        margin-top: 14px;
    }
    .media-body {
        padding: 17px 0;
    }
    .product__img {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0;
        width: 100%;
    }
}

.ProductCategoriesWrap {
    .product {
        width: 100%;
        margin-bottom: 24px;
        box-shadow: none;
    }
}

.Pagination {
    // background: #f8f8f8;
    // padding: 5px;
    // margin-bottom: 36px;
    // margin-top: 32px;
    .page-item:first-child .page-link,
    .page-item:last-child .page-link {
        background-color: $primary-background-color;
        color: #fff;
        white-space: pre !important;
    }
    .page-item.active {
        border: 0;
        .page-link {
            z-index: 3;
            color: $primary-text-color;
            background-color: #fff;
            border-color: $primary-text-color;
        }
    }

    ul li a {
        // color: $primary-text-color !important;
        font-weight: bold;
        font-size: 16px !important;
    }
}
.page-link {
    padding: 11px 15px;
    // margin: 0 6px !important;
    margin: 0 !important;
    min-width: 35px !important;
    // background-color: $primary-background-color;
    color: $primary-text-color;
    &:hover {
        color: #fff;
    }
}
// .page-link {
//     color: #c7c7c7;
//     text-decoration: none;
//     background-color: #fff;
//     border: 1px solid #dee2e6;
//     width: 100%;
// }
// .pagination {
//     display: flex;
//     gap: 5px;
//     padding-left: 0;
//     list-style: none;
//     padding-top: 0;
//     margin: auto;
//     justify-content: center;
//     padding: 5px 0;
// }

.main-link {
    background: #fff;
    padding: 7px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    font-weight: 600;
}

.main-link:hover {
    color: $primary-text-color;
    background-color: #e9ecef;
    transition: 0.3s;
}
.sub-link a {
    height: 0 !important;
    width: 0 !important;
    padding: 0 !important;
    display: none;
}

.filter-icon {
    display: none;
}
// Arabic Style

.ProductView-arabic {
    .sortBy-and-display-select .dropdown .dropdown-menu button {
        text-align: right;
    }
}
.product-arabic {
    .FullViewCard {
        .product__img {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;

            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}
@include respond(laptop) {
}
@include respond(small-laptop) {
}
@include respond(tablet) {
    .filter-icon {
        display: block;
        a {
            display: block;
            padding: 5px;
            background-color: #000;
            border-radius: 5px;

            img {
                width: 20px;
                height: 20px;
                filter: brightness(500);
            }
        }
    }
    .d-none-tablet,
    .products-filter-component {
        display: none !important;
    }
    .products-category__ads {
        margin: 15px 0 40px 0;
    }
    .ProductView .sortBy-and-display-select .dropdown .btn-primary,
    .ProductView .sortBy-and-display-select p {
        font-size: 12px;
    }
    .ProductView .sortBy-and-display-select {
        padding: 5px 10px;
    }
}
@include respond(large-phone) {
    .Pagination ul {
        grid-column-gap: 0 !important;
        column-gap: 0 !important;

        // max-width: 100px !important;
    }

    .page-link {
        padding: 11px 15px;
        // margin: 0 6px !important;
        margin: 0 !important;
        min-width: 25px !important;
    }
}
@include respond(phone) {
    .CategoryTitle {
        h1 {
            font-size: 17px;
        }
    }
    .ProductView ul li:nth-child(3) {
        margin-left: 0px;
    }
    .ProductView ul li .sortBy-and-display-select .dropdown .btn-primary {
        width: 73px;
        font-size: 11px;
    }
}
