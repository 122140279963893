.home-carousel {
    // a {
    //     img {
    //         min-height: 450px;
    //     }
    // }
    .carousel-control-prev,
    .carousel-control-next {
        &:hover {
            opacity: 1 !important;
            transition: ease-in-out 0.3s;
        }
    }
    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        background-color: $primary-background-color;
        padding: 15px !important;
        height: 45px !important;
        width: 45px;
        background-size: 70% !important;
        opacity: 1 !important;
    }

    .carousel-indicators {
        align-items: center;
    }

    .carousel-indicators [data-bs-target] {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 0;
        background-color: $primary-background-color;
        opacity: 1 !important;
    }
    .carousel-indicators .active {
        width: 14px;
        height: 14px;
        background-color: $white-color;
    }
    .carousel-btn {
        min-width: 161px;
        height: 42px;
        border-radius: 20px;
        font-size: 15px;
        font-weight: bold;
        background-color: $primary-background-color;
        color: $white-color;
        text-transform: uppercase;
        margin-bottom: 40px;
        transition: ease-in-out 0.4s;

        &:hover {
            background-color: #fff;
            color: $primary-text-color;
            transition: ease-in-out 0.4s;
        }
        p {

        }
    }
    .home-carousel__img {
        max-height: 520px;
        height: 520px;
        object-fit: cover;
        min-height: 520px;
    }
    .home-carousel__img_skeleton, .home-carousel__img_skeleton *{
        max-height: 520px;
        height: 520px;
        min-height: 520px;
    }
    .carousel-item {
        max-height: 520px;
    }
}

@include respond(smallDesktop) {
}
@include respond(laptop) {
}

@include respond(small-laptop) {
}

@include respond(tablet) {
    .home-carousel .carousel-btn {
        margin-bottom: 15px;
    }
}

@include respond(large-phone) {
    .home-carousel .home-carousel__img {
        min-height: 250px;
        height: 250px;
        max-height: 250px;

    }
    .home-carousel__img_skeleton, .home-carousel__img_skeleton *{
        max-height: 250px !important;
        height: 250px !important;
        min-height: 250px !important;
    }
    .home-carousel .carousel-btn {
        min-width: 120px;
        height: 35px;
        p {
            font-size: 13px;
        }
    }
    .home-carousel .carousel-control-next-icon,
    .home-carousel .carousel-control-prev-icon {
        width: 30px !important;
        height: 30px !important;
    }
}

@include respond(phone) {
    .home-carousel .carousel-btn {
        min-width: 110px;
        height: 30px;
        p {
            font-size: 11px;
        }
    }
    .boxes .react-multi-carousel-list .react-multi-carousel-dot--active button,
    .boxes .react-multi-carousel-list .react-multi-carousel-dot button,
    .categories-block .react-multi-carousel-list .react-multi-carousel-dot--active button,
    .categories-block .react-multi-carousel-list .react-multi-carousel-dot button,
    .home-carousel .carousel-indicators [data-bs-target],
    .home-carousel .carousel-indicators .active {
        width: 10px;
        height: 10px;
    }
}
