@import "../../../abstract/mixins";

.compare-block {
    margin: 30px 0;
    .compare-block__header {
        margin: 0 15px;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        margin-bottom: 25px;
        h3 {
            font-size: 30px;
            color: #752157;
            font-family: "poppinsBold", sans-serif;
            white-space: nowrap;
            margin-bottom: 0;
        }
        .hr {
            width: -webkit-fill-available;
            height: 2px;
            background-color: #e2e2e2;
            margin: 0 20px;
            margin-bottom: 7px;
            margin-top: auto;
        }
    }
}
// Arabic style
.categories-block-arabic .categories-block__header .view-all img {
    transform: scale(-1);
}
// compare start
.compare-rate {
    column-gap: 15px;
    justify-content: space-between;
    // padding: 5px 10px;
    color: $primary-text-color;
    ul {
        padding: 0;
        margin: 0;
        i {
            color: $forth-text-color;
        }
    }
}
.compare-price {
    // padding: 5px 10px;

    font-size: 18px;
    color: $primary-text-color;
    font-family: $poppinsBold-font;
}
.btn-table-header {
    padding: 0 !important;
    border-right: 4px solid #fff;
    border-left: 4px solid #fff;
}

.compare-header {
    width: 20%;
}

.compare-header,
.compare-value {
    color: $secondary-text-color;
    font-size: 15px;
    border-right: 4px solid #fff;
    border-left: 4px solid #fff;
    padding: 0.5rem 1rem;
}

// compare end
.compare-table {
    overflow: scroll;
    display: flex;
    // justify-content: center;
    &::-webkit-scrollbar {
        width: 0.2rem;
        height: 0.2rem;
    }

    &::-webkit-scrollbar-thumb {
        background: darken($primary-text-color, 10%);
        border-radius: 20px;
    }
}
.compare-result-table {
    width: auto;
}
.table > :not(caption) > * > * {
    border-bottom-width: 0 !important;
}

///
.compare-product {
    background-color: #f3f3f3;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: relative;
    padding: 5px 5px;
    img {
        width: 100%;
        object-fit: scale-down;
        padding: 0 10px;
        max-height: 210px !important;
        // max-width: 210px !important;
        cursor: pointer;
    }
    h6 {
        color: $primary-text-color;
        font-family: $poppinsBold-font;
        font-size: 14px;
        padding: 0 10px;
        cursor: pointer;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 36px;
        margin-top: 10px !important;
    }
    p {
        color: $secondary-text-color;
        font-size: 13px;
        padding: 0 10px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 45px;
    }
    .compare-product__fav {
        position: absolute;
        cursor: pointer;
        right: 12px;
        top: 12px;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #dbdbdb;
        color: #752157;
        border-radius: 50%;
    }

    .compare-product__compare {
        position: absolute;
        cursor: pointer;
        left: 12px;
        top: 12px;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #dbdbdb;
        color: #752157;
        border-radius: 50%;
        img {
            width: 205px !important;
        }
    }
    .compare-product__img--container {
        height: 210px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        .compare-product__title {
            color: red !important;
        }
    }
}
.btn-table-header {
    padding-bottom: 0px !important;
    min-width: 200px;
    max-width: 260px;
}

.compare-product-button {
    width: 100%;
    background-color: $primary-text-color;
    padding: 10px;
    justify-content: center;
    column-gap: 10px;
    color: #fff;
    img {
        width: 20px;
        height: 20px;
        padding: 0;
    }
}

@include respond(tablet) {
    .btn-table-header {
        min-width: 180px;
    }
}

@include respond(large-phone) {
    .btn-table-header {
        min-width: 150px;
    }
    .compare-product-button {
        span {
            font-size: 12px;
        }
    }
}
