.selectBlock {
  margin-bottom: 0;
  // font-family: $helvetica-neue-font;
}

.mainSelect .select__indicator .css-tj5bde-Svg {
  fill: $primary-background-color !important;
  width: 30px;
  height: 35px;
  // font-weight: 100;
}

.mainSelect .css-1okebmr-indicatorSeparator {
  width: 0 !important;
}

.mainSelect .css-1uccc91-singleValue {
  color: $primary-background-color !important;
}

.mainSelect .css-1gtu0rj-indicatorContainer,
.mainSelect .css-tlfecz-indicatorContainer {
  padding: 0 5px !important;
}

.mainSelect .css-yk16xz-control {
  background-color: #fff;
}
.select__value-container {
  padding: 8px 15px !important;
}

.css-1s2u09g-control {
  border-radius: 0 !important;
  border-color: $primary-background-color !important;
  color: $primary-background-color !important;

  .select__placeholder {
    color: $primary-background-color !important;
  }

  .select__value-container--has-value .select__single-value {
    color: $primary-background-color !important;
    // font-weight: 100 !important;
  }
}

// select active  style start
.css-1pahdxg-control {
  box-shadow: none !important;
  border-radius: 0 !important;
  border-color: $primary-text-color !important;
  height: 42px;
  color: $primary-text-color !important;
  // background-color: #000 !important;
}
// select active  style end

// .css-14jk2my-container,
// .css-1fhf3k1-control {
//     cursor: no-drop !important;
//     pointer-events: initial;
//     border-color: #000 !important;
// }
// .css-1fhf3k1-control:hover {
//     border-color: #000 !important;
// }

// .select_label {
//     margin-bottom: 10px;
//     /* margin-left: 10px; */
// }

// .select_label span {
//     // color: $secondary-color;
//     letter-spacing: 1px;
// }

@include respond(small-laptop) {
  .select_label span {
    font-size: 15px;
  }
}

@include respond(phone) {
  .dateBlock,
  .textWithLabel,
  .text-input-block,
  .selectBlock {
    // margin-bottom: 15px;
  }
  .select_label span {
    font-size: 14px;
  }
  .select_label img {
    width: 15px;
    height: 15px;
    margin-left: 10px;
  }
  .mainSelect .css-1uccc91-singleValue {
    font-size: 14px;
  }
}
