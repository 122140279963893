@import "../../../abstract/mixins";

.newCheckOut {
    .phone-number,
    .select-field {
        margin-bottom: 0 !important;
    }
    .text-input-block {
        .input-group {
            border: 1px solid #707070;
            border-radius: 0;
            margin-bottom: 0 !important;
        }
    }
    .phone-number {
        .text-input-block {
            .input-group {
                height: 100% !important;
                min-height: auto !important;
            }
        }
    }
    .shipping-method-footer,
    .special-instruction-container {
        textarea {
            min-height: 85px;
        }
    }
}

@include respond(tablet) {
    .c-f-u-c {
        margin-bottom: 15px !important;
    }
}
