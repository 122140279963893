.input-group,
.select__control,
.css-1s2u09g-control {
  border: 1px solid #707070 !important;
}

.phone-zipcode,
.text-center,
.input-group-text,
.phone-number .text-input-block {
  border-color: #707070 !important;
  border-width: 1px !important;
}

* {
  line-height: 1.5 !important;
}

.spinner-border {
  margin: 0 !important;
}
.add-promo-input-group {
  border-radius: calc(0.375rem + 2px) !important;

  * {
    border-color: transparent !important;
  }
}
.without-arrow-accordion {
  .accordion-button::after {
    background-image: url("../images/right.svg") !important;
  }
}

html[dir="rtl"] {
  .add-promo-input-group {
    > :not(:first-child) {
      border-top-left-radius: 0.375rem !important;
      border-bottom-left-radius: 0.375rem !important;

      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    > :not(:last-child) {
      border-top-right-radius: 0.375rem !important;
      border-bottom-right-radius: 0.375rem !important;

      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }

  .without-arrow-accordion {
    .accordion-button::after {
      background-image: url("../images/left.svg") !important;
    }
  }
}
.swal-button--mycancel {
  color: #555;
  background-color: #e8e8e8;
}

html[dir="rtl"] {
  .swal-text {
    text-align: right !important;
  }
}

.border-black {
  border-color: black !important;
}

.invoice-to-print {
  p {
    margin: 0 !important;
  }
  * {
    font-size: 16px !important;
  }
  .subtitle1 {
    margin: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.75;
    letter-spacing: 0.00938em;
    margin-bottom: 0.35em;
  }
  h1 {
    margin: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    font-size: 1.25rem !important;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    margin-bottom: 0.35em;
  }
}

.invoice-to-print.rtl {
  direction: rtl;
}

@media print {
  .pagebreak {
    page-break-before: always;
  } /* page-break-after works, as well */
}
.invoice-btns-container {
  .show-on-print {
    position: absolute;
    width: 800px !important;
    left: calc(-100vw - 800px) !important;
  }
}

.swal2-select{
  width: auto !important;
}