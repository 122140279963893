.login-modal {
    .modal-lg {
        max-width: 1000px !important;
    }
    .modal-content {
        border-radius: 0 !important;
        margin: auto;
    }
    .modal-header {
        border-bottom: none !important;
    }
    .login-modal__body {
        padding: 30px;
        padding-top: 0 !important;
        .login-modal__body__header {
            font-weight: 600;
            font-size: 40px;
            text-transform: uppercase;
            border-bottom: none !important;
        }
        .login-modal__body__left {
            .login-form {
                .red-astrick {
                    color: red !important;
                }
                .form-label {
                    font-size: 16px;
                }
                .signup-login__form-container__field {
                    .text-input-block .input-group {
                        .form-control {
                            font-size: 16px !important;
                        }
                        border: 1px solid #707070;
                        border-radius: 0 !important;
                        height: 49px !important;
                    }
                }
                .remember-me {
                    font-size: 14px;
                    text-transform: capitalize;
                }
                .forgot-pass {
                    font-size: 14px;
                    text-decoration: underline !important;
                    cursor: pointer;
                }
                .submit-form-btn {
                    background-color: $primary-background-color;
                    color: #fff;
                    font-size: 20px;
                    font-weight: 600;
                    height: 49px;
                    text-transform: uppercase;
                    transition: 0.7s;

                    &:hover {
                        background-color: #fff;
                        color: #000;
                        transition: 0.7s;
                        border: 1px solid #000;
                    }
                }
                a {
                    color: #f3656b;
                }
            }
            .socialLoginTexr {
                color: #999999;
                font-size: 16px;
            }
        }
        .login-modal__body__right {
            .reg-header {
                font-size: 25px;
                margin-bottom: 20px;
            }

            .reg-text {
                margin-bottom: 6px;
            }

            .reg-btn {
                background-color: $primary-background-color;
                color: #fff;
                font-size: 20px;
                font-weight: 600;
                height: 49px;
                text-transform: uppercase;
                transition: 0.7s;
                &:hover {
                    background-color: #fff;
                    color: #000;
                    transition: 0.7s;
                    border: 1px solid #000;
                }
            }
        }
    }

    .social-btns {
        column-gap: 40px;
        .social-btn {
            height: 49px;
            color: #fff;
            font-size: 20px;
            font-weight: 500;
            font-family: inherit;
            padding: 20px;
        }
        #facebook {
            background-color: #3c66c4;
            column-gap: 10px;
        }
        #google {
            background-color: #cf4332;
        }
    }
}

@include respond(large-phone) {
    .reg-btn {
        margin-bottom: 30px !important;
    }
}

@include respond(large-phone) {
    .login-modal .login-modal__body {
        padding: 10px !important;
        .login-modal__body__header {
            font-size: 30px !important;
        }
    }
    .login-modal__body__right {
        padding: 1rem !important;
        .reg-header {
            font-size: 20px !important;
        }
        .reg-btn {
            font-size: 17px !important;
        }
    }
    .login-modal__body__left {
        .submit-form-btn {
            font-size: 17px !important;
        }
    }
    .signup-login {
        .signup-login__form-container {
            padding: 30px 10px;
        }
    }

    .signup-login {
        .phone-number {
            .phone-number__zipcode {
                width: 49% !important;
            }
        }
    }
    .social-btns {
        flex-direction: column;
        .social-btn {
            margin-bottom: 10px;
            font-size: 17px;
            #facebook {
                column-gap: 24px;
            }
        }
    }
    .login-modal__body__left {
        .login-form {
            .remember-me {
                font-size: 12px !important;
            }
            .forgot-pass {
                font-size: 10px !important;
                text-decoration: underline !important;
            }
        }
    }
}
