@import "../../../abstract/mixins";
@import "./homeCarousel";
@import "./homeCategories";
.home {
    // padding-bottom: 70px;
    .home-main-container {
        background-color: #000 !important;
        margin: 0 !important;
        display: flex;
        justify-content: center;
        width: 100% !important;
        max-width: 100% !important;
        padding: 40px 0 !important;
    }
}
.ads-block {
    padding: 50px 0;

    img {
        width: 100%;
        max-height: 220px;
    }
}

@include respond(large-phone) {
    .ads-block {
        padding: 30px 0;
    }
}
