.product-horizontal {
    border-radius: 5px;
    // box-shadow: 0 0 2px 0 rgba(192, 192, 192, 0.281);
    box-shadow: 0 0 5px 0 rgba(192, 192, 192, 0.38);

    position: relative;
    margin-bottom: 15px;
    // display: flex;
    // align-items: flex-end;
    // justify-content: space-between;
    .product-label {
        position: absolute;
        left: 0;
        top: 15px;
        height: 20px;
        font-family: $poppinsBold-font;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        font-size: 18px;
        padding: 0 10px;

        @media(max-width:767px) {
            font-size: 16px;
        }
    }


    .product-label-soldOut {
        background: #000 !important;
        color: #fff !important;
    }
    .product-horizontal-label,
    .product-fullVeiw-label {
        position: absolute;
        left: 0;
        top: 15px;
        // background-color: #ffa700;
        // color: #fff;
        min-width: 105px !important;
        height: 30px !important;
        color: #fff;
        font-family: "poppinsBold", sans-serif;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        font-size: 15px;
        padding: 10px !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .product-horizontal-fav {
        position: absolute;
        cursor: pointer;
        right: 12px;
        top: 12px;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: #dbdbdb;
        color: $primary-text-color;
        border-radius: 50%;
        i {
            font-weight: bold;
            font-size: 18px;
        }
    }
    .product__img {
        background-color: #f5f5f5;
        padding: 10px;
        cursor: pointer;
        height: 100%;
        img {
            max-width: 100%;
            width: 200px;
            height: 200px;
            object-fit: cover;
        }
    }
    .product__body {
        padding-left: 30px;
        display: block;
        padding-bottom: 10px;
        margin-top: 65px;
        h5 {
            color: $secondary-text-color;
            font-family: $poppinsBold-font;
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            min-height: 0;
            margin: 0;
            cursor: pointer;
        }
        p {
            color: $secondary-text-color;
            font-size: 12px;
            padding: 2px 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            // display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            height: 38px;
            margin-bottom: 0;
        }
        .product__body__rate {
            display: flex;
            align-items: center;
            column-gap: 10px;
            margin-bottom: 5px;
            ul {
                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;
                column-gap: 3px;
                color: $forth-text-color;
            }
            span {
                font-size: 14px;
                color: $secondary-text-color;
            }
        }
        .product__price__new-price {
            span {
                color: $primary-text-color;
                font-family: $poppinsBold-font;
                font-size: 20px;
                &:first-child {
                    margin-right: 10px;
                }
            }
        }

        .product__price__old-price {
            display: flex;
            align-items: center;
            column-gap: 10px;
            margin-top: -5px;
            span {
                &:first-child {
                    text-decoration: line-through;
                    font-size: 13px;
                }
                &:last-child {
                    color: #069644;
                    font-size: 13px;
                }
            }
        }
    }
    .product-horizontal__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 44px;
    }
    .product__cart-compare {
        display: flex;
        justify-content: space-between;
        align-items: center;
        div {
            display: flex;
            align-items: center;
            div {
                cursor: pointer;
            }
            img {
                width: 20px;
                height: 20px;
            }
            &:first-child {
                column-gap: 10px;
            }
            &:last-child {
                column-gap: 20px;

                i {
                    color: #ffa700;
                }
                font-family: $poppinsBold-font;
            }
        }
    }
    // arabic style
    &.product-horizontal-arabic {
        .product__cart-compare,
        .product__body .product__price__old-price {
            flex-direction: row-reverse;
        }
        .product__price__new-price span:first-child {
            margin-left: 10px;
            margin-right: 0px;
        }
        .product__body {
            padding-left: 0px;
            padding-right: 30px;
        }

        .product-horizontal-label {
            left: auto;
            top: 15px;
            right: 0;

            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
        .product-horizontal-fav {
            right: auto;
            top: 12px;
            left: 12px;
        }
    }
}

@include respond(laptop) {
    .product-horizontal {
        .product__cart-compare div img {
            width: 20px;
            height: 20px;
        }
    }
}

@include respond(small-laptop) {
    .product-horizontal {
        .product-horizontal-label {
            font-size: 12px;
            width: 87px;
            height: 27px;
        }
        .product__img img {
            width: 170px;
            height: 170px;
        }

        .product__cart-compare div img {
            width: 20px;
            height: 20px;
        }

        .product__body {
            padding-left: 20px;
            .product__body__rate {
                column-gap: 5px;
                margin-bottom: 2px;
                i {
                    font-size: 11px;
                }
            }
            .product__price__new-price {
                span {
                    font-size: 17px;
                }
            }
        }

        // arabic style
        &.product-horizontal-arabic {
            .product__body {
                padding-left: 0px;
                padding-right: 20px;
            }
        }
    }
}

@include respond(small-laptop) {
    .product-horizontal {
        .product__body h5 {
            font-size: 13px;
            min-height: 30px;
        }
        .product-horizontal-label {
            font-size: 12px;
            width: 87px;
            height: 25px;
            top: 11px;
        }
        .product__img img {
            width: 170px;
            height: 170px;
        }

        .product__cart-compare div {
            &:last-child {
                column-gap: 10px;
            }
            img {
                width: 20px;
                height: 20px;
            }
        }

        .product__body {
            padding-left: 20px;
            .product__body__rate {
                column-gap: 5px;
                margin-bottom: 2px;
                i {
                    font-size: 11px;
                }
            }
            .product__price__new-price {
                span {
                    font-size: 15px;
                }
            }
            p {
                font-size: 11px;
                min-height: 34px;
            }

            .product__price__old-price span {
                font-size: 12px;
            }
        }
        .product-horizontal .product-horizontal__footer {
            height: 36px;
        }
    }
}

@include respond(large-phone) {
    .product-horizontal {
        .product__body {
            padding-left: 10px;
        }

        // arabic style
        &.product-horizontal-arabic {
            .product__body {
                padding-left: 0px;
                padding-right: 10px;
            }
        }
    }
}

.FullViewCard {
    .product-horizontal-label {
        position: absolute;
        left: 0;
        top: 15px;
        // background-color: #ffa700;
        // color: #fff;
        width: 105px;
        height: 30px;
        color: #fff;
        font-family: "poppinsBold", sans-serif;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        font-size: 15px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
