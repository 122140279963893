@mixin respond($breakpoint, $min: 0, $max: 100vw) {
    @if $breakpoint==custom-media {
        @media (min-width: $min) and (max-width: $max) {
            @content;
        }
    }

    @if $breakpoint==phone {
        @media (max-width: 425px) {
            @content;
        }
    }

    @if $breakpoint==large-phone {
        @media (max-width: 576px) {
            @content;
        }
    }

    @if $breakpoint==tablet {
        @media (max-width: 768px) {
            @content;
            .mobile-header.active ~ *{
                display: none !important;
            }
        }
    }

    @if $breakpoint==small-laptop {
        @media (max-width: 992px) {
            @content;
        }
    }

    @if $breakpoint==laptop {
        @media (max-width: 1200px) {
            @content;
        }
    }

    @if $breakpoint==smallDesktop {
        @media (max-width: 1400px) {
            @content;
        }
    }

    @if $breakpoint==desktop {
        @media (min-width: 1400px) {
            @content;
        }
    }
}
