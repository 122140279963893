@import "../../../abstract/mixins";
.paymentsMethods {
    .paymentsMethods__header {
        font-size: 20px;
        margin-bottom: 30px;
    }
    .payment-card {
        .checkbox-container {
            border: 1px solid #707070;
            flex-direction: column;
            padding: 20px;
            margin: 0;
            height: auto;
            margin-bottom: 23px;
            .checkmark {
                top: 8px;
                left: 8px;
                border-radius: 3px;
            }
            img {
                width: 145px;
                height: 145px;
                object-fit: scale-down;
                max-width: 100%;
            }
        }
        p {
            font-size: 16px;
            font-family: $poppinsBold-font;
        }
    }
    .payment-invoice {
        border-top: 1px solid #d1d1d1;
        border-bottom: 1px solid #d1d1d1;
        padding: 25px 0;
        span {
            font-size: 20px;
        }
        .checkbox-container {
            height: auto;
            margin: 0;
            p {
                margin: 0;
            }
            .checkmark {
                top: 4px;
                border-radius: 3px;
            }
        }
    }
    .payment-promocode {
        padding: 30px 25px;
        padding-bottom: 35px;
        background-color: #f2f2f2;
        .flex {
            border: 1px solid;

            .text-input-block {
                width: calc(100% - 130px);
                .input-group {
                    border-radius: 0;
                    height: 50px;
                    margin-bottom: 0 !important;
                    input {
                        &::placeholder {
                            color: #999999;
                        }
                    }
                }
            }
            .submit-btn {
                // margin-bottom: 1rem;
                width: 130px;
                height: 50px;
                font-size: 16px;
                text-transform: capitalize;
            }
        }
    }
    .paymentsMethods-arabic {
        .checkbox-container {
            padding-left: 0px;
            padding-right: 35px;
            .checkmark {
                right: 5px;
                left: 0px;
            }
        }
    }
}

@include respond(tablet) {
    .paymentsMethods .payment-card .checkbox-container {
        margin-bottom: 15px;
    }
}

@include respond(large-phone) {
    .paymentsMethods .payment-invoice .checkbox-container {
        padding-left: 25px;
        margin: 0 10px;
        .checkmark {
            height: 17px;
            width: 17px;

            &::after {
                left: 5px;
                top: 2px;
            }
        }
    }

    .paymentsMethods-arabic {
        .checkbox-container {
            padding-right: 25px;
        }
    }

    .paymentsMethods .payment-invoice span {
        font-size: 17px;
    }

    .paymentsMethods .payment-promocode .flex .text-input-block {
        width: calc(100% - 100px);

        .input-group {
            height: 35px;
            input {
                font-size: 13px;
            }
        }
    }

    .paymentsMethods .payment-promocode .flex .submit-btn {
        height: 35px;
        width: 100px;
    }
}
