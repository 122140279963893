.feedback-modal {
    // max-width: 620px;
    // padding: 20px !important;
    text-align: center;
    .modal-content {
        max-width: 600px;
        margin: auto;
    }
    .feedback-modal__header {
        align-items: flex-start;
        padding: 20px 10px;
        font-size: 28px;
        .feedback-modal__header__title {
            padding: 26px 0;
        }
        .btn-close {
            color: #000 !important;
        }
    }

    .feedback-modal__body {
        // Review Section Start
        // Send Review Start
        padding: 0 !important;
        .send-review {
            // padding: 30px 75px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            .send-review__title {
                border-bottom: 2px solid #7070702a;
                padding-bottom: 10px;
                margin-bottom: 10px;
                h5 {
                    font-family: $PoppinsSemiBold;
                    font-size: 25px;
                    margin-bottom: 0;
                }
                span {
                }
            }

            .bar-row {
                margin-bottom: 10px;
                .bar {
                    padding: 0;
                    margin: 0;
                    column-gap: 10px;
                    li {
                        // padding: 0 3px;
                        i {
                            color: #d1d1d1;
                            font-size: 61px;
                        }
                        .selectedRate {
                            color: $primary-text-color;
                        }
                    }
                }
            }

            .send-review__input {
                width: 100% !important;
                text-align: center;
                justify-content: center;
                column-gap: 40px;
                textarea {
                    margin-top: 35px;
                    height: 150px;
                    width: 435px;
                    border: 1px solid #707070;
                    padding: 10px 15px;
                    font-size: 16px;
                    &::placeholder {
                        color: #70707080;
                    }
                }
                .submit-btn {
                    width: 35%;

                    height: 60px;
                }
            }
        }
        // Send Review End
        // comment-content Start
        .reviews-section__comment-container {
            .comment-content {
                display: flex;
                padding: 25px 30px;
                column-gap: 45px;
                border-bottom: 2px solid #707070;
                .comment-head {
                    .user {
                        .user-name-rate {
                            .user-name {
                                color: $primary-text-color;
                                font-size: 18px;
                                text-transform: capitalize;
                                font-family: $PoppinsSemiBold;
                                white-space: nowrap;
                            }
                        }
                    }
                    .date {
                        white-space: nowrap;
                        font-size: 13px;
                        font-family: $poppinsLight-font;
                    }
                }
                .user-rate {
                    .bar-row {
                        margin-bottom: 10px;
                        display: flex;
                        gap: 10px;
                        .bar {
                            border-bottom: none;
                            padding-bottom: 0;
                            display: flex;
                            align-items: center;
                            padding: 0;
                            margin: 0;
                            gap: 3px;

                            li {
                                i {
                                    color: $primary-background-color;
                                }
                            }
                        }
                        .evaluator-number {
                            display: flex;
                            gap: 5px;
                        }
                    }
                }
                .comment-text {
                    font-size: 14px;
                    color: #565656;
                    margin: 0;
                    word-break: break-all;
                }
            }
            .reviews-section__comment-container__more-comment-loader {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;

                button {
                    background-color: transparent;
                    color: $primary-text-color;
                    font-weight: bold;
                    padding: 30px;
                }
            }
            // comment-content End
        }
        // Review Section End
    }

    .feedback-modal__footer {
        justify-content: center;
        width: 100%;
        padding-bottom: 40px;
        .feedback-modal__footer__submit {
            width: 435.3px;
            .feedback-modal__footer__submit__btn {
                width: 100%;
                background-color: #000;
                color: #fff;
                font-size: 22px;
                font-family: $PoppinsSemiBold;
                border: 1px solid #000;
                transition: 0.3s;
                padding: 11px;
                margin-top: 40px;
                &:hover {
                    background-color: #fff;
                    color: #000;
                    transition: 0.3s;
                }
            }
        }
    }
}
