.box-card {
    // background-image: url("../../../../Rectangle\ 9\ copy.png");
    height: 240px;
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;
    transition: ease-in-out 0.3s;

    padding: 15px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    // border: 4px solid #000;
    box-shadow: 0 0 0 1px #c0c0c038;
    position: relative;
    &:hover {
        .box-card__overlay {
            opacity: 1;
            transition: ease-in-out 0.3s;
        }
    }
    .box-card__contents {
        text-align: right;
        margin-bottom: 5px;

        p {
            margin-bottom: 10px;
            font-size: 16px;
        }
        h2 {
            font-family: $PoppinsSemiBold;
        }
        h5 {
            margin-top: 20px;
            text-decoration: underline;
            font-size: 20px;
        }
    }
    .box-card__overlay {
        transition: ease-in-out 0.3s;
        padding: 20px;
        opacity: 0;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.9);
        top: 0;
        bottom: 0;
        // left: 15px;
        // right: 15px;
        left: 0;
        right: 0;
        color: #fff;
        text-align: center;
        margin: auto;
        display: flex;
        // align-items: center;
        flex-direction: column;
        justify-content: center;
        p {
            font-size: 13px;
        }
        a {
            width: fit-content;
            text-decoration: underline !important;
            margin: 0 auto;
        }
    }
}

@include respond(laptop) {
    .box-card {
        height: 210px;

        .box-card__contents {
            margin-bottom: 5px;
            p {
                margin-bottom: 6px;
                font-size: 14px;
            }
            h2 {
                font-family: $PoppinsSemiBold;
                font-size: 24px;
            }
            h5 {
                margin-top: 15px;
                text-decoration: underline;
                font-size: 18px;
            }
        }
    }
}

@include respond(tablet) {
    .box-card {
        height: 170px;

        .box-card__contents {
            margin-bottom: 5px;
            p {
                margin-bottom: 5px;
                font-size: 13px;
            }
            h2 {
                font-family: $PoppinsSemiBold;
                font-size: 22px;
            }
            h5 {
                margin-top: 15px;
                text-decoration: underline;
                font-size: 17px;
            }
        }

        .box-card__overlay {
            // left: 10px;
            // right: 10px;
            padding: 15px;
            p {
                font-size: 12px;
            }
            a {
                font-size: 13px;
            }
        }
    }
}

// background-position
@include respond(phone) {
    // .box-card {
    //     background-position: center 0 !important;
    // }
}
