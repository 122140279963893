.steps {
    color: #555464;
    margin-bottom: 90px;
    ul {
        display: flex;
        align-items: center;
        list-style: none;
        padding: 0;
        justify-content: center;
        li {
            &:last-child {
                margin-left: -20px;
            }
        }
        .step {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: max-content;
            position: relative;
            // margin-right: -54px;
            span {
                width: 33px;
                height: 33px;
                background-color: #d1d1d1;
                border-radius: 50%;
                z-index: 9;
                /* /////// */
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 18px;
                cursor: pointer;
                margin-bottom: 5px;
                div {
                    width: 25px;
                    height: 25px;
                    background-color: #fff;
                    border-radius: 50%;
                }
            }
        }
        .step-hr {
            // width: 30%;
            width: 25%;
            height: 8px;
            background-color: #d1d1d1;
            margin-bottom: 5px;
        }
        .stepActive {
            color: $primary-text-color;
            // font-weight: 700;

            span {
                width: 36px;
                height: 36px;
            }
        }
        .bg-stepActive {
            background-color: $primary-text-color !important;
        }
        i {
            font-size: 18px;
        }
        p {
            font-size: 18px;
            margin-bottom: 0;
            position: absolute;
            bottom: -30px;
            white-space: nowrap;
            text-transform: uppercase;
            font-family: $poppinsMedium-font;
        }
    }
}

@include respond(tablet) {
    .steps {
        margin-bottom: 70px;
    }
    .steps ul p {
        font-size: 13px;
    }
    .steps i {
        font-size: 13px;
    }
}
@include respond(large-phone) {
    .steps ul .step span {
        width: 27px;
        height: 27px;
        div {
            width: 21px;
            height: 21px;
        }
    }
    .steps ul p {
        font-size: 11px;
    }
    .steps ul .step i {
        font-size: 12px;
    }
    .steps ul .step-hr {
        width: 20%;
    }
}

@include respond(phone) {
    .steps ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        li {
            &:last-child {
                margin: 0;
            }
        }
        .step {
            flex-direction: row;
            align-items: center;
            grid-gap: 10px;
            gap: 10px;
            // align-items: center;
            p {
                position: relative;
                bottom: 0;
            }
        }
    }
    .steps ul .step span {
        margin-bottom: 0;
    }
    .steps ul .step-hr {
        height: 45px;
        width: 5px;
        margin: 0 11px;
    }

    // .steps ul .step span {
    //     width: 27px;
    //     height: 27px;
    //     div {
    //         width: 21px;
    //         height: 21px;
    //     }
    // }
    // .steps ul p {
    //     font-size: 10px;
    // }
    // .steps i {
    //     font-size: 11px;
    // }
    // .steps ul .step-hr {
    //     width: 15%;
    // }
}
