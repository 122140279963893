@import "../../../abstract/mixins";

.signup-login {
    width: 100%;
    .signup-login__form-container {
        max-width: 1000px;
        margin: auto;
        padding: 30px;
        border: 1px solid #d8d8d8;
        border-radius: 2px;
        font-family: inherit;
        .header-paragraph {
            font-size: 18px !important;
        }
        .sign-up-login__switcher {
            color: #f3656b;
            text-decoration: underline !important;
            cursor: pointer;
        }
        .red-astrick {
            color: red;
        }

        .form-label {
            font-size: 16px;
        }
        .signup-login__form-container__field {
            margin: 0 !important;
            border-radius: 0 !important;
            .text-input-block .input-group {
                .form-control {
                    font-size: 16px !important;
                    &::placeholder {
                        color: #707070 !important;
                        font-family: inherit !important;
                        font-size: 14px;
                    }
                }
                border: 1px solid #707070;
                border-radius: 0 !important;
                height: auto !important;
            }
        }

        .social-btns {
            height: 49px;
            .social-btn {
                color: #fff;
                font-size: 20px;
                font-weight: 500;
                font-family: inherit;
            }
            #facebook {
                background-color: #3c66c4;
                column-gap: 10px;
            }
            #google {
                background-color: #cf4332;
            }
        }
        .agree-check-container {
            .false-checkbox {
                input {
                    border: 1px solid red !important;
                }
            }
            .terms_error {
                color: red;
            }
        }
    }
    .forget-pass-container {
        max-width: 569px !important;
        padding: 45px !important;
        .header-paragraph {
            font-size: 18px;
        }
    }
    .ads-container {
        text-align: center !important;
        img {
            max-width: 100% !important;
        }
    }

    // Confirm Checkout information

    .confirm-checkout-container {
        box-shadow: none;
        border: none;
        padding: 0;
        .confirm-checkout__header {
            font-size: 20px;
            margin-bottom: 20px;
        }
        .signup-login__form-container__field {
            .text-input-block .input-group {
                .form-control {
                    &::placeholder {
                        color: #000 !important;
                        font-size: 14px !important;
                    }
                }
            }
            .select-field {
                height: 49px !important;
                padding: 0;
                border: 1px solid #707070;
                border-radius: 0;
                .select-field__options {
                    margin-bottom: 0 !important;
                    height: 100%;
                    width: 100%;
                    border-radius: 0;
                    border: none;
                    font-size: 14px;
                    color: #000;
                }
            }
        }
        .phone-number .text-input-block .input-group .form-control {
            &::placeholder {
                color: #000 !important;
                font-size: 14px !important;
            }
        }
        .textarea-field {
            display: flex;
            justify-content: baseline !important;

            .text-input-block {
                width: 100% !important;
                .input-group {
                    height: 87px !important;
                    display: flex;
                    align-items: baseline !important;
                    padding-top: 15px;
                }
            }
        }
        input[type="file"] {
            display: none;
        }
        .custom-file-upload-container {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            border: 1px solid #707070;
            height: 42px;
            width: 100%;
            // margin-top: 21px;
            .custom-file-upload {
                height: 42px;
                text-align: center;
                width: 42px;
                display: inline-block;
                padding: 8px 12px;
                cursor: pointer;
                background-color: #000;
                color: #fff;
                font-size: 16px;
            }
            .custom-file-upload__uploaded-files {
                padding: 0 10px !important;
            }
        }

        .file-preview {
            margin: 0 10px;
        }
        .shipping-method-container {
            .shipping-method-header {
                font-size: 20px;
                sup {
                    font-size: 20px;
                }
            }
            .shipping-method-body {
                column-gap: 100px;
                .checkbox-container {
                    margin-top: 25px;
                    padding-left: 30px;
                    p {
                        font-size: 16px;
                    }
                    .checkmark {
                        left: 1px;
                    }
                }
            }
        }
    }
}

.custom-file-upload-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    border: 1px solid #707070;
    height: 42px;
    width: 100%;
    // margin-top: 21px;
    .custom-file-upload {
        height: 42px;
        text-align: center;
        width: 42px;
        display: inline-block;
        padding: 8px 12px;
        cursor: pointer;
        background-color: #000;
        color: #fff;
        font-size: 16px;
    }
    .custom-file-upload__uploaded-files {
        padding: 0 10px !important;
    }
}
.custom-file-upload__uploaded-files {
    padding: 0 10px !important;
}
.confirm-checkout-container input[type=file]{
    display: none;
}

// Arabic design

.signup-login-arabic {
    .signup-login__form-container {
        .phone-number {
            .phone_field {
                border-right: none !important;
                border-left: 1px solid #707070 !important ;
            }
            .text-input-block {
                border-left: 1px solid #707070 !important;
                border-right: none !important;
            }
            .phone-number__zipcode {
                .phone-number__zipcode-select {
                    border-right: 1px solid #707070 !important;
                    border-left: none !important;
                }
            }
        }
    }
}


@media (max-width: 1199px) {
    .confirm-checkout-container {
        .shipping-method-container {
            .shipping-method-body {
                flex-direction: column !important;
                .checkbox-container {
                    margin: 0 !important;
                    margin-top: 15px !important;
                }
            }
        }
    }
}

@include respond(phone) {
    // .social-btns {
    //     flex-direction: column;
    //     .social-btn {
    //         margin-bottom: 10px;
    //         font-size: 17px;
    //         #facebook {
    //             column-gap: 24px;
    //         }
    //     }
    // }
}

@include respond(large-phone) {
    .signup-login {
        .signup-login__form-container {
            padding: 30px 10px;
            .header-paragraph {
                font-size: 16px !important;
            }
        }
    }
    .agree-signup {
        margin-top: -23px !important;
    }
    .signup-login {
        .phone-number {
            .phone-number__zipcode {
                width: 49% !important;
            }
        }
        .forget-pass-container {
            padding: 15px !important;
        }
    }
}

@include respond(tablet) {
    .signup-login {
        .phone-number {
            .phone-number__zipcode {
                width: 55% !important;
            }
        }
    }
}
// // ================ SignUp And Login Style
// .signup-login {
//     display: flex;
//     align-items: center;
//     flex-direction: column;
//     margin: 80px 0;
//     overflow: hidden;
//     // create new account title start
//     h3 {
//         color: $primary-background-color;
//         font-size: 30px;
//         text-transform: capitalize;
//         font-family: $poppinsMedium-font;
//     }
//     // create new account title end
//     // signup-login form start
//     .signup-login__form {
//         margin-top: 70.5px;
//         width: 600px;
//         justify-content: center;
//         max-width: 100%;
//     }
//     // signup-login form end
//     // button start
//     .submit-button {
//         margin-top: 34px;
//         margin-bottom: 0px;
//         min-width: 371px;
//         height: 38px;
//         padding: 0;
//         border: 1px solid #e6e6e6;
//         color: #878787;
//         font-size: 20px;
//         text-transform: capitalize;
//         background-color: white;
//         text-align: center;

//         &:hover {
//             background-color: #b31983;
//             color: white;
//             transition: ease-in-out 0.4s;
//         }
//     }

//     // button end
//     // Login with facebook or google section start
//     h4 {
//         margin-top: 55.5px;
//     }
//     .signup-login__by-fb-google {
//         margin-top: 15px;
//         max-width: 624px !important;
//         i {
//             color: $dark_title !important;
//             transition: ease-in-out 0.4s;
//             margin: 0;
//         }
//     }
//     // Login with facebook or google section end
//     // Already have account section start
//     h5 {
//         margin-top: 63px;
//         &::after,
//         &::before {
//             display: inline-block;
//             content: "";
//             border-top: 2px solid #e6e6e6;
//             width: 77px;
//             transform: translateY(-0.3rem);
//         }
//         &::after {
//             margin-left: 30px;
//         }
//         &::before {
//             margin-right: 30px;
//         }
//     }
//     .login-button {
//         margin-top: 25px;
//     }
//     // Already have account section end
// }
// .socialBtn {
//     width: 180px;
//     height: 38px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     background-color: #f9f9f9 !important;
//     border-radius: 5px;
//     padding: 7px;
//     transition: ease-in-out 0.4s;
//     box-shadow: none !important;
//     overflow: hidden;
//     border-radius: 5px !important;

//     div {
//         background-color: #f9f9f9 !important;
//         margin: 0 !important;
//         padding: 0 !important;
//         width: 100%;
//         &:hover {
//             background-color: red !important;
//             color: white !important;
//             transition: ease-in-out 0.3s;
//             color: #fff !important;
//         }
//     }
//     div::after {
//         content: "G";
//         height: 0 !important;
//         color: $dark_title;
//         font-size: 30px;
//         font-family: inherit !important;
//         font-weight: 700;
//     }
//     svg {
//         display: none;
//     }
//     span {
//         display: none !important;
//     }
// }

// .kep-login-facebook {
//     background-color: #f9f9f9 !important;
//     width: 180px;
//     height: 38px;
//     padding: 7px !important;
//     border: none !important;
//     font-size: 15px !important;
//     border-radius: 5px !important;
//     &:hover {
//         background-color: #1877f2 !important;
//         color: white !important;
//         transition: ease-in-out 0.4s;
//         i {
//             color: white;
//         }
//     }
// }

// // ================== Login style only Start

// .signup-login {
//     .mt-px {
//         margin-top: 20px;
//     }

//     .text-note {
//         display: block;
//         font-size: 12px;
//         margin-top: -12px;
//     }
//     .checkbox-ForgetPassword {
//         display: flex;
//         justify-content: space-between;
//         .checkbox-container {
//             display: flex;
//             gap: 5px;
//             align-items: center;
//             p {
//                 color: $secondary-text-color;
//                 font-size: 16px;
//             }
//             .checkbox {
//                 width: 18px;
//                 height: 18px;
//                 border: 2px solid #cccccc;
//                 border-radius: 5px;
//                 margin-top: 3px;
//                 cursor: pointer;
//                 transition: ease-in-out 0.2s;
//                 display: flex;
//                 justify-content: center;
//                 align-items: center;
//                 &:hover {
//                     border-color: $primary-text-color;
//                     transition: ease-in-out 0.2s;
//                 }
//                 i {
//                     font-size: 11px;
//                     color: $primary-text-color;
//                     color: $secondary-text-color;
//                 }
//             }
//         }
//         .ForgetPassword {
//             font-size: 12px;
//         }
//     }

//     .h5-login {
//         margin-top: 63px;
//         &::after,
//         &::before {
//             width: 65px;
//         }
//         &::after {
//             margin-left: 20px;
//         }
//         &::before {
//             margin-right: 20px;
//         }
//     }
// }

// // ================== Login style only End
// // Arabic language Start
// .signup-login-arabic {
//     h5,
//     .h5-login {
//         margin-top: 63px;
//         &::after,
//         &::before {
//             width: 77px;
//         }
//         &::after {
//             transform: translateX(-20px);
//         }
//         &::before {
//             transform: translateX(20px);
//         }
//     }
//     .submit-button2 {
//         border-radius: 5px;
//         margin-left: 0;
//         margin-right: auto;
//     }
// }
// // Arabic language End

// // ================== Media Query

// @include respond(tablet) {
//     .signup-login {
//         // signup-login form
//         .signup-login__form {
//             margin-top: 30px;
//         }
//     }
// }
// @include respond(large-phone) {
//     .signup-login {
//         // signup-login form
//         .signup-login__form {
//             padding: 0 40px;
//             align-items: center;
//             flex-direction: column;
//         }
//         .h5-login {
//             &::after,
//             &::before {
//                 width: 90px;
//             }
//         }
//     }
// }
// @include respond(phone) {
//     .signup-login {
//         // signup-login form
//         .signup-login__form {
//             padding: 0 20px;
//         }
//         // button
//         .submit-button {
//             min-width: 300px;
//         }
//         // Login with facebook or google
//         .signup-login__by-fb-google {
//             a {
//                 width: 145px;
//             }
//         }
//         // Already have account title
//         h5 {
//             &::after,
//             &::before {
//                 width: 60px;
//             }
//         }
//         .h5-login {
//             &::after,
//             &::before {
//                 width: 50px;
//             }
//         }
//     }
// }
