.footer {
    // footer upper side start
    .footer__upper-side {
        background-color: #e9e9e9;
        padding: 50px 0 5px 0;
        text-align: center;

        .footer-logo {
            margin-bottom: 25px;
            display: inline-block;
        }
        p {
            margin: auto;
            max-width: 75%;
            margin-bottom: 20px;
        }
        .public-page-list {
            justify-content: center;
            column-gap: 10px;
            border-top: 1px solid #bbbbbb;
            border-bottom: 1px solid #bbbbbb;
            padding: 27px 0;
            margin-bottom: 40px;
        }
        .public-page-list__hr {
            height: 18px;
            width: 1px;
            background-color: $primary-background-color;
        }
        .social-media-list {
            justify-content: center;
            column-gap: 10px;
            margin-bottom: 40px;
            li {
                background-color: $primary-background-color;
                padding: 5.5px;
                img {
                    width: 22px;
                    height: 22px;
                    filter: brightness(500);
                    object-fit: scale-down;
                }
                &:hover {
                    background-color: #fff;
                    transition: ease-in-out 0.3s;

                    img {
                        filter: brightness(0.5);
                        transition: ease-in-out 0.3s;
                    }
                }
            }
        }
    }
    // footer upper side end
    // footer lower side start
    .footer__lower-side {
        background-color: $primary-background-color;
        .coppyrights {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px 0;
            p {
                margin: auto 0;
                font-size: 13px;
                color: #cccccc;
            }
        }
    }
    // footer lower side end
}
@include respond(laptop) {
}
@include respond(small-laptop) {
}

@include respond(tablet) {
    .footer .footer__upper-side .social-media-list,
    .footer .footer__upper-side .public-page-list {
        margin-bottom: 25px;
    }
    .footer .footer__upper-side .public-page-list {
        flex-direction: column;
        padding: 15px 0;
        .public-page-list__hr {
            display: none;
        }
    }
    .footer__lower-side {
        margin-bottom: 69px;
    }
}
@include respond(large-phone) {
    .footer .footer__upper-side p {
        max-width: 100%;
        font-size: 12px;
    }
}

@include respond(phone) {
    .footer .footer__upper-side .public-page-list a {
        font-size: 13px;
    }
    .footer .footer__lower-side .coppyrights {
        padding: 15px 0;

        p {
            font-size: 11px;
        }
    }
}
