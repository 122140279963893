.subCategories {
    // background-color: red;

    .subCategories__accordion {
        margin: 10px 0;
        .accordion-button {
            padding: 8px 13px;
            font-family: $poppinsBold-font;
            // background-color: #000000;
            // color: #fff;
            // font-family: "PoppinsSemiBold";
            // border: 1px solid #000000;
        }
        .accordion-body {
            padding: 10px !important;
            ul {
                a {
                    i {
                        transition: ease-in-out 0.3s;
                    }
                    li {
                        padding: 10px !important;
                        font-size: 14px !important;
                    }
                    .activeCategoryAccordion {
                        color: $primary-text-color;
                        font-weight: bold;
                        i {
                            margin-right: 20px !important;
                            transition: 0.5s;
                        }
                    }

                    &:hover {
                        li {
                            color: $primary-text-color;
                            i {
                                margin-right: 20px !important;
                                transition: 0.5s;
                            }
                        }
                    }
                }
            }
        }
    }

    &.subCategories-arabic {
        .accordion-button::after {
            margin-left: 0;
            margin-right: auto;
        }

        .accordion-button::after {
            margin-left: 0;
            margin-right: auto;
        }

        .accordion-body {
            ul {
                a {
                    i {
                        transition: ease-in-out 0.3s;
                    }
                    &:hover {
                        li {
                            i {
                                transition: ease-in-out 0.3s;

                                margin-right: 0 !important;
                                margin-left: 20px !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.accordion-button:not(.collapsed) {
    color: $primary-text-color;
    background-color: transparent;
    // font-family: "poppinsBold";
}

.accordion-button {
    padding: 1rem 0;
}

.accordion-body {
    padding: 1rem 9px;

    ul {
        margin: 0;
        padding: 0;
        margin-bottom: 32px;
        margin-top: 13px;
    }
}
