@import "../../../abstract/mixins";

.contact-us {
    .page-title {
        margin-bottom: 70px;
    }
    // contact form start
    .contact-us__form {
        background-color: #f2f2f2;
        padding: 35px;

        h4 {
            font-family: $poppinsBold-font;
            font-size: 22px;
            margin-bottom: 20px;
        }

        .text-input-block .input-group {
            height: auto;
            min-height: 49px;
            border-color: #707070;
            textarea,
            .form-control {
                &::placeholder {
                    color: #707070;
                }
            }
            textarea {
                height: 130px;
            }
        }
        .css-1s2u09g-control {
            border-color: #707070 !important;
            .select__placeholder {
                color: #707070 !important;
            }
        }
        button {
            height: 61px;
        }
    }
    // contact form end

    .contact-us__info {
        padding: 50px;
        background-image: url("../../../../../cc.svg");
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;

        h3 {
            color: #fff;
            font-family: $poppinsBold-font;
            font-size: 22px;
            margin-bottom: 50px;
        }
        ul {
            padding: 0;
            margin: 0;
            li {
                margin-bottom: 50px;
                a {
                    column-gap: 17px;
                    div {
                        span {
                            color: #f3656b;
                            font-size: 14px;
                        }
                        p {
                            color: #fff;
                            font-size: 14px;
                            margin-bottom: 0;
                        }
                        &:first-child {
                            background-color: #fff;
                            width: 45px;
                            height: 45px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            img {
                                width: 26px;
                                height: 26px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include respond(laptop) {
    .contact-us .contact-us__info {
        padding: 30px;
        ul li a div {
            p,
            span {
                font-size: 13px;
            }
            &:first-child {
                width: 40px;
                height: 40px;
                img {
                    width: 23px;
                    height: 23px;
                }
            }
        }
    }
}
@include respond(small-laptop) {
    .contact-us .contact-us__info {
        padding: 25px 20px;
        h3 {
            font-size: 19px;
        }
        ul li a {
            column-gap: 10px;
            div {
                p,
                span {
                    font-size: 11px;
                }
                &:first-child {
                    width: 30px;
                    height: 30px;
                    img {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }
    }
    .text-input-block .input-group .form-control,
    .text-input-block .input-group textarea,
    .contact-us .contact-us__form .css-1s2u09g-control {
        font-size: 14px;
    }

    .contact-us .contact-us__form .css-1s2u09g-control {
        height: 40px;
    }
    .contact-us .contact-us__form .text-input-block .input-group {
        min-height: 40px;
    }
    .contact-us .contact-us__form button {
        height: 50px;
    }
}
