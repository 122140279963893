.cart-summary {
  border: 1px solid #000;
  width: 375px;
  height: fit-content;
  .cart-summary__section {
    .cart-summary__section__header {
      background-color: $primary-background-color;
      height: 49px;
      display: flex;
      align-items: center;
      h5 {
        color: $white-color;
        font-size: 18px;
        font-weight: 500;
        margin: 15px !important;
        text-transform: uppercase;
        font-weight: bold;
      }
    }
    .cart-summary__section__body {
      padding: 15px;
      .signup-login__form-container__field {
        margin: 0 !important;
        border-radius: 0 !important;
        .text-input-block .input-group {
          .form-control {
            font-size: 16px !important;
            &::placeholder {
              font-size: 14px;
              font-family: inherit !important;
              color: #707070;
            }
          }
          border: 1px solid #707070;
          border-radius: 0 !important;
          height: auto !important;
        }
      }
      .submit-promocode {
        height: 49px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        // padding: 0 !important;
        padding: 0 10px;
        background-color: #000;
        color: #fff;
        p {
          font-size: 18px;
        }
      }
      .section-text {
        font-size: 15px;
        font-weight: bold;
      }
      .flex-col {
        flex-direction: column;
      }
      .cart-summary__section__body__summary-field {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border-bottom: 1px solid #d1d1d1;
        .summary-field__title {
          text-transform: capitalize;
          font-size: 15px;
        }
        .summary-field__value {
          font-size: 16px;
        }
      }

      .total-field {
        border: none;
        .summary-field__title,
        .summary-field__value {
          font-size: 19px;
          font-weight: 600;
        }
      }
    }

    .cart-summary__section__footer {
      padding: 15px !important;
      margin-top: 140px;
      .CheckoutButt {
        margin: 0 !important;
        a,
        .CheckoutButt-btn {
          height: 49px !important;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          text-transform: uppercase;
          font-weight: 600;
          transition: 0.3s;
        }
        a:last-child,
        .CheckoutButt-btn:last-child {
          background: #d1d1d1;
          color: #fff;
          cursor: pointer;
          color: #000;
          margin-top: 10px;
        }

        a:last-child:hover,
        .CheckoutButt-btn:last-child:hover {
          background: $primary-text-color;
          color: #fff !important;
          transition: 0.3s;
        }

        a:first-child,
        .CheckoutButt-btn:first-child {
          background: $primary-text-color;
          color: #fff;
          margin-top: 10px;
          border: 1px solid #000;
        }

        a:first-child:hover,
        .CheckoutButt-btn:first-child:hover {
          background: $white-color;
          color: #000 !important;
          transition: 0.5s;
        }
      }
    }
  }
}

// @media (max-width: 991px) {
//     .cart-summary {
//         width: 100% !important;
//         margin-top: 1rem;
//     }
// }

@media (max-width: 1400px) {
  .cart-summary {
    width: 100% !important;
    margin-top: 1rem;
  }
}

@include respond(tablet) {
}
