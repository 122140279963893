.mobile-header {
    // position: absolute;
    // width: 100%;
    display: none;
    z-index: 9999;
    background-color: #fff;
    height: 100%;
    // overflow-y: auto;
    overflow-y: overlay;
    .mobile-header__logo {
        text-align: center;
        display: block;
        padding: 10px;
        background-color: silver;
        img {
            max-width: 150px;
            height: 40px;
        }
    }
    &:lang(ar) {
        .language-mobile-header {
            .accordion-button::after {
                margin-right: auto;
                margin-left: 0;
            }
        }
        .mobile-header__contents {
            .header-mobile__categories .header-mobile__categories__all-category .accordion-button {
                &::after {
                    margin-right: auto;
                    margin-left: 0;
                }
            }
            .header__search .dropdown-toggle {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
            .header__search .input-group-text {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-top-left-radius: 5px !important;
                border-bottom-left-radius: 5px !important;
            }
        }
    }
}
.mobile-header__tabs {
    // display: none;
    // background: whitesmoke;
    // position: fixed;
    // bottom: 0;
    // width: 100%;
    // height: 70px;
    // padding-top: 7px;
    // border-top: 1px solid rgba(179, 195, 211, 0.356);
    // z-index: 100000;

    z-index: 999999999;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #fff;
    justify-content: space-around;
    align-items: center;
    border-top: 1px solid #eaf1f5;
    display: none;
    -webkit-backface-visibility: hidden;
    ul {
        display: -webkit-box;
        list-style: none;
        padding: 10px 0 0;
        li {
            margin-right: 16%;
            color: #4f5864;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0;
            outline: 0;
            background: none;
            border: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            cursor: pointer;

            // width: 16.5%;
            width: 20%;
            margin: 0;
            &:hover {
                color: $primary-background-color;
                a img {
                    // filter: invert(52%) sepia(54%) saturate(3141%) hue-rotate(-117deg) brightness(60%) contrast(131%);
                    filter: brightness(0);
                }
            }
            a {
                text-align: center;
                color: #4f5864;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0;
                outline: 0;
                background: none;
                border: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                cursor: pointer;
                font-size: 11px;

                .img-container {
                    position: relative;
                }
                img {
                    margin-bottom: 4px;
                    width: 25px;
                    height: 25px;
                }
                span {
                    position: absolute;
                    background-color: $primary-text-color;
                    color: $third-text-color;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 15px;
                    height: 15px;
                    border-radius: 50px;
                    font-size: 10px;
                    top: -7px;
                    right: -9px;
                    border: 1px solid white;
                    padding-top: 2px;
                    padding-left: 0.5px;
                }
            }
        }
    }
}
.language-mobile-header {
    padding: 2px 3px;

    .accordion-header {
        button {
            padding: 0 !important;
        }
    }
    ul {
        margin: 0;
    }
}

.mobile-header__contents {
    min-height: 100vh;
    background-color: #fff;
    // padding-top: 30px;
    padding-bottom: 70px;

    .header__search {
        width: 90%;
        margin: auto;
        display: flex !important;
        box-shadow: 0 0 0 1px #c0c0c040;
        border-radius: 5px;
        .dropdown-toggle {
            min-width: 100px;
            background-color: #eeeeee;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #752157;
        }

        .input-group-text {
            background-color: #a7a7a7;
            border: 0;
            cursor: pointer;
            padding: 0.375rem 0.375rem;
        }
        input {
            border: 0;
        }
    }
    .header-mobile__categories {
        .top-categories {
            .top-category__single-card {
                width: 100%;
                display: block;
                border: 1px solid #e2e2e2;
                border-radius: 5px;
                // height: 130px;
                text-align: center;
                margin-bottom: 10px;
                img {
                    width: 100%;
                    border-radius: 5px;
                    margin-bottom: 5px;
                    height: 140px;
                    padding: 10px;
                }
                p {
                    margin-bottom: 10px;
                }
            }
        }

        .header-mobile__categories__all-category {
            h5 {
                color: $primary-text-color;
                font-size: 13px;
            }
            ul {
                padding: 0;
                a {
                    color: $secondary-text-color;
                    font-size: 13px;
                    padding: 5px;
                    display: block;
                    &:hover {
                        background-color: #61144546;
                    }
                }
            }
            .accordion-button {
                color: $secondary-text-color;
                font-size: 14px;
                padding: 12px 10px;
            }
            .accordion-button:not(.collapsed) {
                color: #fff;
                background-color: $primary-background-color;
            }
            .accordion-button:not(.collapsed)::after {
                filter: brightness(500);
            }
            .accordion-item:first-of-type {
                border-radius: 0;
                // border: 0;
            }
        }
    }

    .header-mobile__account {
        margin-top: -30px;

        ul {
            padding: 0;
            li {
                border-bottom: 1px solid #c0c0c040;

                a {
                    padding: 10px;
                    color: $secondary-text-color;
                    display: block;
                    img {
                        width: 25px;
                        height: 25px;
                    }
                }
            }
        }
    }
}

@include respond(tablet) {
    .mobile-header,
    .mobile-header__tabs {
        display: block;
    }
}

