// push menu start
.mobile-push-menu {
    display: none;
    color: $primary-text-color;
    ul {
        padding: 0;
        li {
            // div {
            //     padding-bottom: 10px;
            // }
        }
    }

    &.mobile-push-menu-arabic {
        .sideMenu_List li .accordion .accordion-button::after {
            margin-right: auto;
            margin-left: 0;
        }
    }
}

.bm-overlay {
    top: 0;
    left: 0;
    z-index: 9888888 !important;
}
.bm-cross-button {
    display: none;
}
.bm-burger-button {
    position: relative !important;
    margin-right: auto;
}

.bm-menu-wrap {
    top: 0;
    left: 0;
    z-index: 9999999999 !important;
    width: 40% !important;
    background-color: $primary-text-color;
}

.sideMenu_logo {
    text-align: center;
    background-color: #fff;

    img {
        margin: auto;
        padding: 8px 0;
    }

    &:focus {
        outline: none !important;
    }
}

.sideMenu_List {
    margin-top: 20px;
    padding: 0 10px;

    li {
        text-transform: capitalize;
        border-bottom: 1px solid #ffffff6e;
        padding: 7px 0;

        &:not(:last-of-type) {
            // margin-bottom: 1rem;
        }

        h3 {
            color: #fff;
            border-bottom: 1px solid #fff;
            padding-bottom: 10px;
            margin-left: -20px;
            padding-left: 5px;
        }
        a,
        button {
            padding: 5px;
        }
        span,
        a,
        button {
            display: block;
            // color: white !important;
            color: #ffffff !important;
            text-align: left;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: transparent !important;

            span {
                border-radius: 50px;
                // width: 35px;
                // height: 35px;
                background-color: $primary-text-color;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border: 0;
                font-size: 16px;
                font-weight: 100;
                i {
                    font-size: 10px;
                    // margin-left: 20px;
                    // margin-right: 3px;
                }
            }
        }

        span {
            font-weight: bold;
            letter-spacing: 1px;
        }

        button {
            width: 100%;
            text-align: left;
            background: transparent;
            column-gap: 0;
            width: 100%;
        }
        .sideMenu_List__img {
            justify-content: flex-start;
            img {
                width: 20%;
                border-radius: 50%;
            }
            span {
                width: 100%;
                display: block;
                text-align: start;
            }
        }
        // Accordion  Style(tablet and less) Start
        .accordion {
            // border-bottom: 1px solid #ffffff6e;
            // margin-bottom: 16px;
            .accordion-item {
                background-color: transparent !important;
                a {
                    // border-bottom: 1px solid #ffffff6e;
                }
            }
            .accordion-button:not(.collapsed) {
                background-color: #7cbb55 !important;
            }
            .accordion-button::after {
                filter: brightness(500) !important;
                // margin: auto;
            }
            .accordion-button:not(.collapsed) {
                box-shadow: none;
            }
            .cart-and-favourite-mobile {
                p {
                    margin: 0;
                }
                .cart-and-favourite-mobile__qty {
                    border-radius: 20px;
                    background-color: $gray_light;
                    width: 25px;
                    height: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                }
            }
        }
    }
}
.mobile-push-menu .bm-icon {
    font-size: 25px;
}
.sideMenu_loginButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;

    span {
        width: 50%;
        text-align: center;
        border: 0;

        &:first-child {
            padding-right: 5px;
            border-right: 2px solid white;
        }
    }
}

// push menu end

@include respond(tablet) {
    .mobile-push-menu {
        display: block;
    }

    .mobile-push-menu .bm-icon {
        font-size: 22px;
    }
}

@include respond(large-phone) {
    // push menu start
    .bm-menu-wrap {
        width: 60% !important;
    }

    .bm-menu-wrap {
        width: 60% !important;
    }

    .sideMenu_logo img {
        max-width: 150px;
    }
    .sideMenu_List li span span,
    .sideMenu_List li a span,
    .sideMenu_List li button span,
    .sideMenu_List a,
    .sideMenu_List button,
    .sideMenu_List span {
        font-size: 14px;
    }
    // push menu end
}
