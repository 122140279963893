@import "../../../abstract/mixins";
.addresses__block {
    display: block;
    border: 1px solid #d1d1d1;
    padding: 15px;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    h5 {
        font-family: $poppinsMedium-font;

        white-space: nowrap;
        width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    p {
        margin-bottom: 5px;
        font-size: 16px;
    }
    .addresses__block__phone {
        column-gap: 7px;
        span {
            font-size: 18px;
            &:first-child {
                font-family: $PoppinsSemiBold;
            }
        }
    }
    .address__block__buttons {
        column-gap: 7px;
        button {
            min-width: 90px;
            margin-bottom: 0;
            padding: 4px;
            &:last-child {
                background-color: #d1d1d1;
                color: $primary-text-color;
                border-color: transparent;
                &:hover {
                    background-color: $primary-background-color;
                    color: #fff;
                }
            }
        }
    }

    /// checkmark start

    /* Hide the browser's default checkbox */
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        text-transform: capitalize;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 13px;
        right: 13px;
        left: auto;
        height: 20px;
        width: 20px;
        background-color: #fff;
        border: 1px solid #000;
        border-radius: 4px;
    }

    /* When the checkbox is checked, add a blue background */
    input:checked ~ .checkmark {
        background-color: #000;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .checkmark:after {
        left: 6px;
        top: 3px;
        width: 6px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    /// checkmark end

    .head {
        display: flex;
        justify-content: space-between;
        background-color: $primary-text-color;
        border-radius: 10px 10px 0 0;
        padding: 8px 10px 8px 27px;
        h5 {
            font-size: 16px;
            color: white;
            white-space: nowrap;
            width: 80%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        img {
            cursor: pointer;
            object-fit: scale-down;
        }
    }
    .body {
        border-radius: 0 0 10px 10px;
        background-color: #f7f7f7;
        padding: 20px 27px 20px;
        img {
            object-fit: scale-down;
            width: 19px;
            height: 19px;
        }
        h6 {
            color: black;
        }
        .email,
        .phone {
            display: flex;
            gap: 20px;
        }
        .phone {
            margin-top: 9px;
        }
        p {
            margin-top: 27px;
            font-size: 15px;
        }
        .delete-btn {
            display: flex;
            justify-content: right;
            img {
                cursor: pointer;
            }
        }
    }
}
.addresses {
    // button start
    .button-container {
        display: flex;
        justify-content: flex-start;
        margin-top: 30px;
        .submit-button2 {
            text-transform: uppercase;
            padding: 5px 20px;
            border-radius: 0px;
            font-size: 18px;
            font-family: $poppinsMedium-font;
        }
    }
    // button end
}

.add-edit-add {
    .submit-button2 {
        text-transform: uppercase;
        border-radius: 5px;
        font-size: 18px;
        font-family: "PoppinsMedium", sans-serif;
    }
}

.add-edit-title {
    h2 {
        font-size: 22px;
        font-family: "PoppinsMedium", sans-serif;
        text-transform: uppercase;
        margin-bottom: 23px;
        color: #000000;
    }
}
// Arabic Style Start
.addresses-arabic {
    .button-container {
        justify-content: left;
    }

    .addresses__block .checkmark {
        right: auto;
        left: 13px;
    }
}

.places-delete-btn {
    background-color: transparent;
    color: $primary-text-color;
}
// Arabic style End
@include respond(small-laptop) {
    .addresses {
        .margin-top {
            margin: 15px 0;
        }
        .margin-top {
            margin-top: -5px;
        }
    }
}
