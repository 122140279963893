.answered-form {
    background-color: #fff;
    .answered-form__body__row {
        border-bottom: 1px solid #d1d1d1;
        .answered-form__body__row__cell {
            text-align: start;
            width: 352px !important;
            padding: 17px 29px !important;
            font-size: 16px;
            white-space: break-spaces;
            overflow-wrap: anywhere;
            span {
                display: block;
                font-family: $PoppinsSemiBold;
            }
        }
    }
}
