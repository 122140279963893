.rec-item-wrapper {
    display: block !important;
}

.categories-block .rec-arrow-left {
    background-color: $primary-background-color !important;
    border-radius: 0 !important;
    max-height: 45px !important;
    width: 45px !important;
    z-index: 1 !important;
    left: 30px !important;
    color: #fff !important;
    background-image: "/images/left-arrow" !important;
    top: 200px !important;
}
.categories-block .rec-arrow-right {
    right: 30px !important;
    background-color: $primary-background-color !important;
    border-radius: 0 !important;
    max-height: 45px !important;
    width: 45px !important;
    z-index: 1 !important;
    color: $white-color !important;
    top: 200px !important;
}
.categories-block .gQIqMI {
    background: $gray-color !important;
    border: none !important;
    width: 9px !important;
    height: 9px !important;
}

.categories-block .jJKuoL {
    width: 9px !important;
    height: 9px !important;
    background-color: #fff !important;
}
.home-categories {
    margin: 30px 0;
    .react-multi-carousel-list {
        padding-top: 55px;
        margin-top: -55px;
    }

    .react-multiple-carousel__arrow {
        right: 0;
        top: 0;
        background-color: transparent !important;
        font-weight: 700;
    }
    .react-multiple-carousel__arrow--left {
        right: 40px;
        left: auto;
    }
    .react-multiple-carousel__arrow::before {
        color: #717171;
    }

    .home-categories__single-item {
        background-color: #f1f1f1;
        overflow: hidden;
        padding: 10px;
        border-radius: 5px;
        display: block;
        width: 95%;
        margin: auto;

        &:hover {
            img {
                margin-right: 0%;
                transition: ease-in-out 0.3s;
            }
        }
        img {
            margin-left: auto;
            display: block;
            width: 100%;
            margin-right: -30%;
            width: 120px;
            height: 80px;
            object-fit: scale-down;
            transition: ease-in-out 0.3s;
        }
        p {
            font-size: 18px;
            margin-top: 20px;
            margin-bottom: 0px;
            text-transform: capitalize;
            font-family: $poppinsBold-font;
            color: #515151;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            padding-bottom: 5px;
        }
        span {
            color: $secondary-text-color;
            font-size: 11px;
        }
    }
    .react-multi-carousel-track {
        // column-gap: 15px;
        justify-content: flex-start;
    }

    // arabic style
    &.home-categories-arabic {
        .react-multiple-carousel__arrow {
            left: 40px;
            right: auto;
        }
        .react-multiple-carousel__arrow--left {
            left: 0;
            right: auto;
        }

        .home-categories__single-item {
            text-align: right;
            img {
                margin-right: auto;
                margin-left: -30%;
            }
        }
        .react-multi-carousel-list {
            direction: rtl !important;
            flex-direction: row-reverse;
            // margin: 0 10px;
        }
    }
    .react-multi-carousel-item--active {
        margin: 0;
    }
}

@include respond(laptop) {
}
@include respond(small-laptop) {
    .home-categories .home-categories__single-item img {
        width: 100px;
        height: 70px;
    }
    .home-categories .home-categories__single-item p {
        font-size: 16px;
        margin-top: 10px;
    }
    .home-categories .home-categories__single-item {
        padding: 5px;
    }
}
@include respond(tablet) {
    .home-categories .home-categories__single-item img {
        width: 70px;
        height: 50px;
    }
    .home-categories .home-categories__single-item p {
        font-size: 14px;
        margin-top: 7px;
    }
    .home-categories .home-categories__single-item span {
        font-size: 10px;
    }
}

@include respond(large-phone) {
    .react-multiple-carousel__arrow::before {
        font-size: 16px;
    }
}

.home-categories {
    .home-categories__single-item-arabic {
        img {
            margin-left: -30%;
        }
        &:hover {
            img {
                margin-left: 0;
                transition: ease-in-out 0.3s;
            }
        }
    }
}
