@import "../../abstract/mixins";

.summary-product {
    .summary-product__img {
        img {
            width: 100%;
            height: 95px;
            object-fit: contain;
        }
    }
    .summary-product__info {
        .summary-product__info__title {
            p {
                margin: 0;
                font-size: 16px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: initial;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                text-transform: capitalize;
            }
        }
        .summary-product__info__pre-order {
            color: #707070;
            font-weight: bold !important;
        }
        .summary-product__info__options {
            height: 47px;
            p {
                margin: 0;
                font-size: 13px;
                color: #707070;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: initial;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                word-break: break-all;
            }
        }
        .summary-product__info__qty-price {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .qty {
                font-size: 13px;
            }
            .price {
                .total-word {
                    font-size: 13px;
                    font-weight: normal;
                    text-align: end;
                    display: block;
                }
                font-size: 15px;
                font-weight: 600;
            }
            .duration {
                span {
                    display: block;
                    max-width: 112px;
                    font-size: 12px;
                    &:nth-child(2) {
                        color: #707070;
                    }
                }
            }
        }
    }
}
