.categories-block {
    position: relative;
    // margin: 0 -15px;
    padding-top: 55px;
    .react-multiple-carousel__arrow:hover,
    .react-multiple-carousel__arrow--left,
    .react-multiple-carousel__arrow--right {
        background-color: $primary-background-color;
        border-radius: 0;
    }
    .react-multi-carousel-list {
        padding-bottom: 60px;
        // margin-bottom: 45px;

        .react-multi-carousel-dot {
            button {
                width: 14px;
                height: 14px;
                background-color: #d1d1d1;
                border: 0;
            }
        }
        .react-multi-carousel-dot--active {
            button {
                // background-color: $primary-background-color;
                // border: 1px solid #fff;
                background-color: #fff;
                width: 15px;
                height: 15px;
            }
        }
    }

    // .rec-arrow-left {
    //     position: absolute;
    //     left: -30px !important;
    //     background-color: transparent !important;
    //     border: 0;
    //     box-shadow: none !important;
    //     color: $primary-text-color !important;
    //     width: auto;
    //     height: auto;
    //     min-width: 0;
    // }
    // .rec-arrow-right {
    //     position: absolute;
    //     right: -30px !important;
    //     background-color: transparent !important;
    //     border: 0;
    //     box-shadow: none !important;
    //     color: $primary-text-color !important;
    //     width: auto;
    //     min-width: 0;
    //     height: auto;
    // }
    // .jJKuoL {
    //     background-color: $primary-text-color !important;
    //     box-shadow: 0 0 1px 3px $primary-text-color !important;
    //     width: 5px !important;
    //     height: 5px !important;
    // }
    // .gQIqMI {
    //     width: 5px !important;
    //     height: 5px !important;
    // }
    // .cjDndS {
    //     background-color: $primary-text-color !important;
    //     box-shadow: 0 0 1px 3px $primary-text-color !important;
    //     width: 5px;
    //     height: 5px;
    // }

    &.categories-block-2 {
        .kVtMtA,
        .bhRnqO {
            background-color: $primary-background-color;
            border-radius: 0;
            opacity: 1;
            width: 43px;
            height: 43px;
            &::before {
                // color: #fff;
                border-color: #fff !important;
                width: 14px;
                height: 14px;
            }
        }
        .kVtMtA {
            left: 60px !important;
        }
        .bhRnqO {
            right: 60px !important;
        }
        .categories-block__header {
            .hr {
                background-color: $primary-background-color;
            }
            h3,
            span {
                color: $primary-text-color;
            }
        }
    }

    .carousel-item-margin-20-px {
        padding: 0 10px;
    }
    &.categories-block-arabic {
        .rec-arrow-left,
        .rec-arrow-right {
            transform: rotate(180deg);
        }
        .categories-block__header {
            h3 {
                // font-family: $PoppinsLanguage-font;
                font-family: inherit;
            }
            span {
                letter-spacing: 0;
            }
        }
        .bqyzQp {
            direction: ltr !important;
        }
    }
}
.categories-block__viewAll {
    text-align: center;
    color: $primary-text-color;
    font-family: $poppinsBold-font;
    margin-top: 40px;
}
.categories-block__header {
    text-align: center;
    span {
        color: #e9e9e9;
        font-size: 16px;
        letter-spacing: 8px;
    }
    h3 {
        font-size: 32px;
        color: $primary-text-color;
        // font-family: $PoppinsSemiBold;

        white-space: nowrap;
        margin-bottom: 15px;
        color: #fff;
        text-transform: capitalize;
    }
    .hr {
        width: 30%;
        max-width: 100px;
        height: 2px;
        background-color: #e2e2e2;
        margin: auto;
        margin-bottom: 50px;
    }
}
@include respond(smallDesktop) {
    .categories-block {
        .rec-arrow-left {
            left: -20px !important;
        }
        .rec-arrow-right {
            right: -20px !important;
        }
    }
}

@include respond(laptop) {
    .categories-block {
        .rec-arrow-left {
            left: -10px !important;
        }
        .rec-arrow-right {
            right: -10px !important;
        }
    }
    .categories-block .item-brand img {
        width: 100%;
        object-fit: scale-down;
        height: 120px;
        max-width: 150px;
    }
}

@include respond(tablet) {
    .categories-block {
        .categories-block__header {
            h3 {
                font-size: 22px;
            }
            .view-all {
                column-gap: 8px;
                img {
                    width: 15px;
                }
                span {
                    font-size: 14px;
                }
            }
        }
    }
    .categories-block .item-brand img {
        height: 110px;
    }
}

@include respond(large-phone) {
    .categories-block {
        margin: 0;
        // margin-top: 30px;

        .categories-block__header {
            margin: 0;
            margin-bottom: 25px;

            h3 {
                font-size: 18px;
            }
            .hr {
                width: -webkit-fill-available;
                height: 2px;
                background-color: #e2e2e2;
                // margin: 0 10px;
                margin-bottom: 7px;
                margin-top: auto;
            }
            .view-all {
                column-gap: 5px;
                img {
                    width: 10px;
                }
                span {
                    font-size: 13px;
                }
            }
        }
    }
    .categories-block .item-brand img {
        // height: 130px;
        height: 90px;
    }
    .categories-block.categories-block-2 .bhRnqO {
        right: 35px !important;
    }
    .categories-block.categories-block-2 .kVtMtA {
        left: 35px !important;
    }
    .categories-block.categories-block-2 .kVtMtA,
    .categories-block.categories-block-2 .bhRnqO {
        width: 30px !important;
        height: 30px !important;
    }
    .categories-block .react-multiple-carousel__arrow:hover,
    .categories-block .react-multiple-carousel__arrow--left,
    .categories-block .react-multiple-carousel__arrow--right {
        min-width: 30px !important;
        min-height: 30px !important;
    }
}
