@import "../../../abstract/mixins";

.giftCardPage {
    .gift-card-block {
        .gift-card-block__content {
            img {
                width: 100%;
                border-radius: 10px;
                height: 250px;
            }
        }
        .gift-card-block__footer {
            padding: 15px;
            justify-content: space-between;
            border: 1px dashed #d49556;
            border-radius: 10px;
            box-shadow: 0 0 4px 1px #c0c0c070;

            span {
                font-size: 17px;
            }
            div {
                font-size: 23px;
                font-family: $PoppinsSemiBold;
                text-decoration: underline !important;
                cursor: pointer;
            }
        }
    }
    &.giftCardPage-Arabic {
        .pr-3 {
            padding-right: 0px;
            padding-left: 25px;
        }
        .pl-3 {
            padding-left: 0px;
            padding-right: 25px;
        }
        .gift-card-block .gift-card-block__footer div {
            font-family: inherit;
        }
    }
}
.giftCard-modal {
    .login-modal__body {
        padding: 30px 60px;
    }
    .btn-close {
        width: auto;
        margin-left: auto;
        margin-right: 0px;
    }
    ul {
        padding: 0;
        margin-top: 20px;
        margin-bottom: 0px;

        column-gap: 65px;
        img {
            height: 40px;
            max-width: 70px;
        }
    }
    .checkbox-container {
        margin-top: 0;
        height: auto;
        .checkmark {
            top: 10px;
        }
    }
    button {
        margin-top: 30px;

        width: 100%;
    }
    &.giftCard-modal-arabic {
        .btn-close {
            margin-right: auto;
            margin-left: 0;
        }
        .checkbox-container {
            padding-left: 0;
            padding-right: 35px;
            .checkmark {
                right: 0;
            }
        }
    }
}

@include respond(laptop) {
    .giftCardPage {
        .gift-card-block {
            .gift-card-block__footer {
                span {
                    font-size: 15px;
                }
                div {
                    font-size: 20px;
                }
            }
        }
    }
    .giftCard-modal ul img {
        height: 30px;
    }
    .giftCard-modal .checkbox-container .checkmark {
        top: 4px;
    }
}

@include respond(laptop) {
    .giftCardPage .gift-card-block .gift-card-block__footer {
        padding: 10px 15px;
    }
}
@include respond(small-laptop) {
    .giftCardPage {
        .gift-card-block {
            .gift-card-block__footer {
                // padding: 15px;
                span {
                    font-size: 14px;
                }
                div {
                    font-size: 18px;
                }
            }
            .gift-card-block__content img {
                height: 135px;
            }
        }
    }
}
@include respond(tablet) {
    .giftCardPage {
        .gift-card-block {
            .gift-card-block__footer {
                padding: 10px;
                span {
                    font-size: 12px;
                }
                div {
                    font-size: 15px;
                }
            }
        }
    }
}
@include respond(large-phone) {
    .giftCardPage.giftCardPage-Arabic .pr-3,
    .giftCardPage.giftCardPage-Arabic .pl-3 {
        padding: 0 0.75rem;
    }
    .giftCardPage .gift-card-block .gift-card-block__content img {
        height: auto;
        max-height: 200px;
    }
}
