@import "../../abstract/mixins";

.notfound {
    position: relative;
    height: 70vh;
    margin-top: 125px;
    background-size: 100% 100%;

    a {
        font-size: 1rem;
        font-weight: bold;
        position: absolute;
        bottom: -10px;
        left: 50%;
        padding: 1rem 2.5rem;
        letter-spacing: 1px;
        text-transform: capitalize;
        color: white;
        background-color: darken($primary-text-color, 10%);
        border-radius: 10px;
        transform: translateX(-50%);
        transition: background-color 0.3s;

        &:hover,
        &:active {
            background-color: darken($primary-text-color, 1%);
        }
    }
}
