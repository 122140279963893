.private-collections {
    background-image: url("../../../../22.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 364px;
    margin-top: 50px;
    .private-collections__content {
        justify-content: space-between;
        .private-collections__content__details {
            p {
                font-size: 24px;
                text-transform: uppercase;
            }
            h2 {
                font-family: $poppinsBold-font;
                margin-bottom: 25px;
                font-size: 70px;
            }
            button {
                font-family: $PoppinsSemiBold;
                font-size: 14px;
                height: 40px;
                max-width: 130px;
            }
        }
        .private-collections__content__img {
            width: 305px;
            height: 364px;
            max-height: 100%;
            max-width: 100%;
        }
    }
    &.private-collections-arabic {
    }
}

@include respond(laptop) {
    .private-collections .private-collections__content {
        .private-collections__content__details {
            p {
                font-size: 20px;
            }
            h2 {
                margin-bottom: 20px;
                font-size: 55px;
            }
            button {
                font-size: 13px;
                height: 35px;
                max-width: 120px;
            }
        }
    }
}
@include respond(small-laptop) {
    .private-collections {
        height: 300px;
        .private-collections__content {
            .private-collections__content__details {
                p {
                    font-size: 17px;
                }
                h2 {
                    margin-bottom: 20px;
                    font-size: 40px;
                }
                button {
                    font-size: 13px;
                    height: 35px;
                    max-width: 120px;
                }
            }
            .private-collections__content__img {
                width: 250px;
                height: 300px;
            }
        }
    }
}
@include respond(tablet) {
    .private-collections {
        height: 500px;
        .private-collections__content {
            flex-direction: column;

            .private-collections__content__details {
                p {
                    font-size: 17px;
                }
                h2 {
                    margin-bottom: 20px;
                    font-size: 40px;
                }
                button {
                    font-size: 13px;
                    height: 35px;
                    max-width: 120px;
                }
            }
            .private-collections__content__img {
                width: 250px;
                height: 280px;
                margin-bottom: 20px;
            }
        }

        // &.private-collections-arabic {
        //     .private-collections__content {
        //         .private-collections__content__img {
        //             right: calc(50% - 180px);
        //             left: auto;
        //         }
        //     }
        // }
    }
}
@include respond(phone) {
    .private-collections {
        height: 450px;
        .private-collections__content {
            .private-collections__content__details {
                p {
                    font-size: 15px;
                }
                h2 {
                    margin-bottom: 20px;
                    font-size: 25px;
                }
                button {
                    font-size: 12px;
                    height: 30px;
                    max-width: 100px;
                }
            }
            .private-collections__content__img {
                width: 250px;
                height: 280px;
                margin-bottom: 15px;
            }
        }
    }
}
