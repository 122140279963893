@import "../../../abstract/mixins";
@import "./compare-block";
.product-Details {
  position: relative;
  .Breadcrumb {
    background-color: #fcfcfc;
    box-shadow: 0 0 2px 0 silver;
    // height: 5px;
    padding: 20px 0;
    margin-bottom: 40px;
    .Breadcrumb__contents {
      column-gap: 5px;

      div {
        width: 2px;
        height: 25px;
        background-color: #515151;
        margin: 0 10px 0 5px;
        // margin-bottom: -3px;
      }
      span {
        color: $secondary-text-color;
        font-size: 16px;
        // border-right: 2px solid $secondary-text-color;
        // padding-right: 10px;
        // margin-right: 10px;
      }
      img {
        width: 10px;
        height: 9px;
        margin-bottom: -4px;
      }
      a {
        color: $primary-text-color;
        font-size: 15px;
      }
    }
  }
  .product-details__contents {
    .image-gallery {
      //height: 500px;
      .iiz__hint {
        display: none;
      }
      .image-gallery-left-nav {
        left: 15px;
      }
      .image-gallery-right-nav {
        right: 15px;
      }
      .image-gallery-right-nav,
      .image-gallery-left-nav {
        padding: 0;
        color: $primary-background-color;
        filter: none;
        svg {
          height: 48px;
          width: 26px;
          font-weight: bold;
        }
      }
      .image-gallery-content,
      .image-gallery-slide-wrapper,
      .image-gallery-thumbnails-wrapper,
      .image-gallery-thumbnail {
        height: 100%;
      }
      .image-gallery-thumbnail.active {
        outline: none;
        border: 3px solid $primary-background-color !important;
        // border-radius: 5px;
      }
      .image-gallery-thumbnail {
        padding: 5px;
        background-color: #f9f9f9;
        width: 90px;
        height: 90px;
        margin-bottom: 7px;
        border: 2px solid #707070 !important;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .active {
          outline: none;
          border: 3px solid $primary-background-color !important;
          // border-radius: 5px;
        }
        // &:active,
        // &:focus,
        // &:hover {
        //     outline: none;
        //     border: 4px solid $primary-background-color !important;
        //     // border-radius: 5px;
        // }
      }

      // .image-gallery-slide-wrapper {
      //     height: 400px !important;
      // }
      .image-gallery-swipe {
        // background-color: #f9f9f9;
        border-radius: 5px;
        height: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
      }
      .image-gallery-bullets {
        bottom: 10px;
        @media (min-width: 768px) {
          bottom: -25px;
        }
        .image-gallery-bullet {
          box-shadow: none;
          background-color: $primary-background-color;
          border-color: $primary-background-color;
        }
      }
      .active {
        background-color: #fff !important;
        transform: scale(1);
      }
    }
    .product-details__contents__compare-fav {
      justify-content: flex-end;
      column-gap: 15px;
      margin-top: 10px;
      margin-bottom: 10px;
      button {
        border-radius: 5px;
        width: 50px;
        height: 40px;
        background-color: #f9f9f9;

        i {
          font-size: 20px;
          color: $primary-text-color;
          font-weight: 700;
        }
        img {
          width: 23px;
          height: 23px;
          // object-fit: scale-down;
        }
      }
    }
    .product-details__contents__center-details {
      .submit-btn {
        height: 60px;
      }
      .inStock-share {
        justify-content: space-between;
      }
      .inStock-label {
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 5px;
        background-color: $primary-background-color;
        color: #fff;
        padding: 2px 7px;
        padding-top: 5px;
        min-width: 70px;
        font-size: 15px;
        margin-bottom: 15px;
      }
      .share-button {
        margin-bottom: 15px;
        cursor: pointer;
      }
      .store-name {
        display: flex;
        align-items: center;
        column-gap: 5px;
        margin-bottom: 10px;

        div {
          width: 2px;
          height: 15px;
          background-color: $secondary-text-color;
          margin-bottom: -3px;
        }
        span {
          color: $primary-text-color;
          font-family: $poppinsBold-font;
          font-size: 18px;
          // border-right: 2px solid $secondary-text-color;
          // padding-right: 10px;
          // margin-right: 10px;
        }
        a {
          color: $secondary-text-color;
          font-family: $poppinsBold-font;
          font-size: 18px;
        }
      }
      .product-details__title {
        color: $primary-text-color;
        font-family: $poppinsBold-font;
        font-size: 29px;
        // margin-bottom: 0;
      }
      .product-details__subTitle {
        color: $primary-text-color;
        font-size: 14px;
      }
      .product-rates-orders-count {
        column-gap: 20px;
        margin-bottom: 10px;
        margin-top: 5px;
        ul {
          padding: 0;
          margin: 0;
          column-gap: 5px;
          i {
            color: $primary-background-color;
            font-size: 20px;
          }
        }
        span {
          color: #666666;
          font-size: 13px;
        }
        a {
          margin-left: auto;
          display: block;
          text-decoration: underline !important;
          color: #444444;
          font-size: 14px;
          opacity: 0.8;

          &:hover {
            opacity: 1;
          }
        }
      }
      .product-details__price {
        color: $primary-text-color;
        font-size: 21px;
        font-family: $PoppinsSemiBold;
      }
      .product-details__price_old {
        color: $primary-text-color;
        font-size: 16px;
        font-family: $PoppinsSemiBold;
      }

      .product-details__options {
        .option-title {
          justify-content: space-between;
          border-bottom: 1px solid #c6c6c6;
          padding-bottom: 5px;
          margin-bottom: 15px;
          margin-top: 10px;
          span {
            color: $secondary-text-color;
            font-family: $PoppinsSemiBold;
            font-size: 16px;
            display: block;
            text-transform: capitalize;
          }
          .Sizes-Gide-button {
            color: #444444;
            font-size: 14px;
            cursor: pointer;
            width: fit-content;
            text-decoration: underline;
            font-family: $poppinsMedium-font;
            margin-bottom: 0;
            &:hover {
            }
          }
        }
        ul {
          padding: 0;
          margin-bottom: 2px;
          column-gap: 6px;
          flex-wrap: wrap;
          li {
            cursor: pointer;
            padding: 5px;
            border: 1px solid #707070;
            opacity: 0.7;
            min-width: 90px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 5px;
            border-radius: 10px;
            span {
              padding-top: 3px;
            }
            &.option-img {
              width: 27px !important;
              height: 27px !important;
              min-width: 0 !important;
              border-radius: 50%;
              padding: 0;
              overflow: hidden;
              // border-color: transparent;
              border-style: hidden;
            }
            img {
              width: 100%;
              height: 100%;
            }
            &:hover {
              opacity: 1;
            }
            &.optionSelected {
              background-color: $primary-background-color;
              border-color: $primary-background-color;

              border-style: solid;
              opacity: 1;
              color: #fff;
            }
          }
        }
      }
      .product-details-quantity {
        border-top: 1px solid #f0f0f0;
        padding-top: 10px;
        margin-top: 10px;

        p {
          font-family: $PoppinsSemiBold;
          // margin-bottom: 0;
          font-size: 16px;
        }
        .qty {
          // border: solid 1px #d8d8d8;
          // padding: 5px 10px;
          column-gap: 7px;
          // justify-content: center;
          // font-size: 14px;
          font-family: $poppinsBold-font;
          // border-radius: 2px;
          input {
            border: solid 1px $primary-background-color;
            padding: 5px 10px;
            border-radius: 8px;
            text-align: center;
            color: #222222;
            font-size: 14px;
            width: 66px;
            height: 35px;
            font-family: $poppinsMedium-font;
          }
          span {
            background-color: $primary-background-color;
            color: #fff;
            cursor: pointer;
            font-weight: 100;
            border-radius: 13px;
            font-size: 15px;
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: $poppinsMedium-font;
            padding-top: 2px;
          }
        }
      }
    }
    .product-details__checkout-info-parent {
      z-index: 9;
      height: 100%;
      box-shadow: 0 0 2px 0 silver;
      width: 100%;
      align-items: flex-start;
      display: flex;
      .overlay {
        width: 70%;
        background-color: rgba(0, 0, 0, 0.5);
        height: 100%;
      }
    }
    .product-details__checkout-info {
      background-color: #f9f9f9;
      padding: 20px;
      border-radius: 5px;
      h3 {
        color: $primary-text-color;
        font-family: $poppinsBold-font;
        font-size: 35px;
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #e7e7e7;
        text-align: center;
      }

      .estimated-delivery,
      .in-stock,
      .location-delivery {
        align-items: flex-start;

        column-gap: 10px;
        font-size: 14px;
        margin: 15px 0;
        img {
          width: 20px;
          height: 20px;
          object-fit: scale-down;
        }
      }
      .estimated-delivery {
        color: $forth-text-color;
      }
      .in-stock,
      .location-delivery {
        color: $secondary-text-color;
      }
      .in-stock {
        padding-bottom: 15px;
        border-bottom: 1px solid #e7e7e7;
      }

      .product-details__buttons {
        border-bottom: 1px solid #e7e7e7;
        .add-to-cart-button,
        .submit-button2 {
          width: 100%;
          border-radius: 5px;
        }

        .add-to-cart-button {
          justify-content: center;
          column-gap: 10px;
          width: 100%;
          margin-bottom: 15px;
          background-color: #89336e;
          color: #fff;
          min-width: 180px;
          padding: 8px;
          transition: ease-in-out 0.3s;
          border: 1px solid #89336e;
          &:hover {
            background-color: $forth-text-color;
            transition: ease-in-out 0.3s;
            border-color: $forth-text-color;
          }
        }
      }

      .security-checkout-note {
        padding-bottom: 15px;
        border-bottom: 1px solid #e7e7e7;
        .location-delivery {
          color: $primary-text-color;
        }
      }
      h5 {
        margin-top: 15px;
        color: $secondary-text-color;
        font-size: 16px;
        font-family: $poppinsBold-font;
      }

      .product-details__checkout-info__product-rates {
        column-gap: 20px;
        align-items: flex-start;
        span {
          color: #3d3d3d;
          font-size: 18px;
          font-family: $poppinsBold-font;
        }
        ul {
          column-gap: 2px;
          padding: 0;
          margin: 0;
          i {
            color: $forth-text-color;
          }
        }
        p {
          color: #b9b9b9;
          font-size: 10px;
          margin-bottom: 0;
          margin-top: -5px;
        }
      }
      .more-info {
        column-gap: 10px;
        color: #c2c2c2;
        margin-top: 30px;
        font-family: $poppinsBold-font;
        &:hover {
          text-decoration: underline !important;
        }
        img {
          width: 15px;
          height: 12px;
          object-fit: scale-down;
        }
      }
    }
    .product-details__pre-order-note {
      div {
        column-gap: 7px;
        display: flex;
        p {
          margin-bottom: 0px;
          font-family: $poppinsBold-font;
          font-size: 14px;
        }
        i {
          color: $primary-background-color;
          font-size: 20px;
        }
      }
      span {
        margin-left: 25px;
        font-size: 14px;
        font-family: $poppinsLight-font;
        display: block;
      }
    }
    .product-details__description {
      h6 {
        font-family: $PoppinsSemiBold;
        border-bottom: 2px solid #525453;
        display: inline-block;
      }
      p {
        overflow-wrap: anywhere;
      }
    }
  }
  // .breadcrumb-item + .breadcrumb-item::before {
  //     content: "\003E";
  // }
  .product-details__reviews {
    padding-top: 55px;
    border-top: 2px solid #f0f0f0;
    margin-bottom: 20px;

    .product-details__reviews-ul {
      display: flex;
      align-items: center;
      border-bottom: 5px solid #f1f1f1;
      padding: 0;
      margin: 0;
      padding-bottom: 17px;
      // column-gap: 60px;
      li {
        padding: 0 30px;
        cursor: pointer;
        span {
          padding: 17px 0px;
          font-family: $poppinsBold-font;
          color: $secondary-text-color;
          font-size: 18px;
        }
        .activeTab {
          color: $primary-text-color;
          border-bottom: 5px solid $primary-text-color;
        }
      }
      div {
        width: 2px;
        height: 25px;
        background-color: #e8e8e8;
        margin: 0 10px 0 5px;
        // margin-bottom: -3px;
      }
    }

    // Review Section Start
    .reviews-section {
      // Send Review Start
      .send-review {
        padding: 30px 75px;

        margin-top: 13px;
        background-color: #f2f2f2;
        .send-review__title {
          border-bottom: 2px solid #7070702a;
          padding-bottom: 10px;
          margin-bottom: 10px;
          h5 {
            font-family: $PoppinsSemiBold;
            font-size: 25px;
            margin-bottom: 0;
          }
          span {
          }
        }

        .bar-row {
          margin-bottom: 10px;
          .bar {
            padding: 0;
            margin: 0;
            column-gap: 5px;
            li {
              // padding: 0 3px;
              i {
                color: #d1d1d1;
                font-size: 28px;
              }
              .selectedRate {
                color: $primary-text-color;
              }
            }
          }
        }

        .send-review__input {
          column-gap: 40px;
          textarea {
            height: 60px;
            width: 65%;
            border: 1px solid #707070;
            padding: 10px 15px;
            font-size: 16px;
            &::placeholder {
              color: #70707080;
            }
          }
          .submit-btn {
            width: 35%;

            height: 60px;
          }
        }
      }
      // Send Review End
      // comment-content Start
      .reviews-section__comment-container {
        .comment-content {
          display: flex;
          padding: 25px 30px;
          column-gap: 45px;
          border-bottom: 2px solid #707070;
          .comment-head {
            .user {
              .user-name-rate {
                .user-name {
                  color: $primary-text-color;
                  font-size: 18px;
                  text-transform: capitalize;
                  font-family: $PoppinsSemiBold;
                  white-space: nowrap;
                }
              }
            }
            .date {
              white-space: nowrap;
              font-size: 13px;
              font-family: $poppinsLight-font;
            }
          }
          .user-rate {
            .bar-row {
              margin-bottom: 10px;
              display: flex;
              gap: 10px;
              .bar {
                border-bottom: none;
                padding-bottom: 0;
                display: flex;
                align-items: center;
                padding: 0;
                margin: 0;
                gap: 3px;

                li {
                  i {
                    color: $primary-background-color;
                  }
                }
              }
              .evaluator-number {
                display: flex;
                gap: 5px;
              }
            }
          }
          .comment-text {
            font-size: 14px;
            color: #565656;
            margin: 0;
            word-break: break-all;
          }
        }
        .reviews-section__comment-container__more-comment-loader {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;

          button {
            background-color: transparent;
            color: $primary-text-color;
            font-weight: bold;
            padding: 30px;
          }
        }
      }
      // comment-content End
    }
    // Review Section End
  }
  .special-offer {
    border: 2px solid #ededed;
    padding: 40px;
    display: block;
    border-radius: 5px;
    margin-top: 20px;
    img {
      display: block;
      width: 100%;
      height: 120px;
      object-fit: scale-down;
      margin-bottom: 20px;
    }
    .special-offer__label {
      background-color: #edb55b;
      height: 20px;
      width: 80px;
      border-radius: 2.5px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-family: $poppinsBold-font;
      font-size: 11px;
    }
    p {
      font-size: 16px;
    }

    .product__price__new-price {
      span {
        color: $primary-text-color;
        font-family: $poppinsBold-font;
        font-size: 20px;
        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }

  .checkout-side-bar {
    position: absolute;
    right: 0;
    z-index: 999;
    // height: 100vh;
    height: 100%;
    box-shadow: 0 0 2px 0 silver;
    // top: 66px;
    width: 100%;
    align-items: flex-start;
    a {
      text-align: center;
    }
    .size-gide-img {
      height: 100%;
      img {
        max-height: 100%;
        object-fit: scale-down;
        width: 100%;
      }
    }
    .overlay {
      width: 75%;
      background-color: rgba(0, 0, 0, 0.5);
      height: 100%;
    }
    .checkout-side-bar__contents {
      padding: 20px;
      background-color: #fff;
      width: 25%;
      height: 100%;

      .checkout-side-bar__product {
        column-gap: 10px;
        display: flex;
        align-items: flex-start;
        div {
          background-color: #f9f9f9;
          padding: 10px;
          border-radius: 5px;
          img {
            width: 150px;
            height: 120px;
            object-fit: scale-down;
          }
        }
        .checkout-side-bar__product__title--container {
          background-color: transparent !important;
          padding: 0 !important;
          .checkout-side-bar__product__title {
            font-family: $poppinsBold-font;
            font-size: 18px;
            font-weight: bold;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 0 !important;
          }
          .checkout-side-bar__added--label {
            color: $primary-text-color;
            i {
              color: $pink-bg !important;
            }
          }
        }
      }
      .added--label {
      }
      .checkout-side-bar__footer {
        justify-content: space-between;
        margin: 15px 0;
        span {
          font-family: $poppinsBold-font;
          font-size: 19px;
          &:last-child {
            font-size: 24px;
          }
        }
      }
      .checkout-side-bar__buttons {
        column-gap: 20px;
        .submit-button2,
        .submit-button {
          border-radius: 5px;
          font-size: 16px;
          padding: 7px;
          background-color: transparent;
          border: none;
          width: 100%;
        }
        .submit-button:hover {
          color: $primary-text-color;
        }
        .submit-button2 {
          background-color: $pink-bg;
          border: 1px solid $pink-bg;
        }
        .submit-button2:hover {
          background-color: transparent;
          color: $primary-text-color;
        }
      }
    }
  }

  .showInvoice-button {
    display: none;
  }
  // arabic style
  &.product-Details-arabic {
    // .product-details__contents .image-gallery .image-gallery-right-nav,
    // .product-details__contents .image-gallery .image-gallery-left-nav {
    //     right: -75px;
    //     left: auto;
    // }

    .product-details__contents
      .product-details__contents__center-details
      .product-rates-orders-count
      a {
      margin-right: auto;
      margin-left: 0;
    }
    .product-details__contents
      .product-details__contents__center-details
      .Sizes-Gide-button {
      margin-right: auto;
      margin-left: 0;
    }
    .product-details__contents .product-details__checkout-info .more-info img {
      transform: rotate(180deg);
    }
    .product-details__reviews
      .reviews-section
      .reviews-section__comment-container
      .comment-content {
      padding-right: 0;
      padding-left: 10px;
    }
    .product-details__contents .product-details__description h6,
    .product-details__contents .product-details__description .h6,
    .product-details__contents
      .product-details__contents__center-details
      .product-details-quantity
      p,
    .product-details__contents
      .product-details__contents__center-details
      .product-details__options
      .option-title
      span,
    .product-details__reviews
      .reviews-section
      .send-review
      .send-review__title
      h5,
    .product-details__reviews
      .reviews-section
      .send-review
      .send-review__title
      .h5 {
      font-family: inherit;
    }
  }
  .iiz,
  .image-gallery-slides,
  .image-gallery-slide.center {
    img {
      object-fit: contain;
    }
    height: 100%;
  }
  .product-details__oldPrice {
    display: flex;
    align-items: center;
    column-gap: 10px;
    justify-content: center;
    span:first-child {
      text-decoration: line-through;
      color: $secondary-text-color;
      font-size: 13px;
    }
    span:last-child {
      color: #069644;
      font-size: 13px;
    }
  }
  .outOfStuck-button {
    display: block;
    margin-bottom: 20px;
    min-width: 180px;
    padding: 8px;
    background-color: silver;
    border: 0px;
    color: rgb(255, 255, 255);
    width: 100%;
    border-radius: 5px;
  }
  .product-Details
    .product-details__reviews
    .reviews-section
    .reviews-section__comment-container
    .comment-content {
    padding-right: 0;
    padding-left: 10px;
  }
}
.product-Details-arabic
  .product-details__reviews
  .reviews-section
  .send-review
  .input-group
  .send-btn {
  transition: ease-in-out all 0.4s;
  transform: scale(-1);
}

.shareModal .ShareRow ul {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0;
  li {
    cursor: pointer;
    img {
      width: 31px;
      height: 31px;
      object-fit: scale-down;
    }
    button {
      &:focus {
        outline: none !important;
      }
    }
  }
}

@include respond(laptop) {
  .product-Details
    .product-details__contents
    .image-gallery
    .image-gallery-slide-wrapper,
  .product-Details .product-details__contents .image-gallery {
    //height: 350px !important;
  }

  .checkout-side-bar {
    .checkout-side-bar__contents {
      width: 35% !important;
    }
  }
  .product-Details
    .product-details__contents
    .product-details__contents__center-details
    .product-details__title {
    font-size: 23px;
  }
  .product-Details
    .product-details__contents
    .product-details__contents__center-details {
    .product-rates-orders-count {
      column-gap: 10px;
      ul {
        column-gap: 3px;
        i {
          font-size: 17px;
        }
      }
      span,
      a {
        font-size: 12px;
      }
    }
    .product-details__subTitle {
      font-size: 13px;
      margin-bottom: 5px;
    }
    .product-details__price {
      font-size: 18px;
    }
    .product-details__options .option-title {
      margin-bottom: 10px;
    }
  }
}
@include respond(small-laptop) {
  .product-Details
    .product-details__contents
    .product-details__checkout-info-parent {
    position: absolute;
    top: 61px;
    right: 0;
    .overlay {
      width: 75%;
    }
    .product-details__checkout-info {
      height: 100%;
      width: 25%;
    }
  }
  .product-Details {
    .submit-btn {
      padding: 10px !important;
      font-size: 17px;
    }
  }
  .product-details__contents__center-details {
    margin-top: 40px;
  }
}
@include respond(tablet) {
  // .product-Details .product-details__contents .image-gallery .image-gallery-right-nav,
  // .product-Details .product-details__contents .image-gallery .image-gallery-left-nav {
  //     left: -59px;
  // }

  .product-Details
    .product-details__contents
    .image-gallery
    .image-gallery-thumbnail {
    width: 80px;
    height: 80px;
  }
  .product-Details
    .product-details__contents
    .product-details__checkout-info-parent
    .product-details__checkout-info {
    width: 40%;
  }
  .product-Details
    .product-details__contents
    .product-details__checkout-info-parent
    .overlay {
    width: 60%;
  }
  .product-Details .checkout-side-bar .overlay {
    width: 58%;
  }
  .product-Details .checkout-side-bar .checkout-side-bar__contents {
    width: 42% !important;
  }
}

@include respond(large-phone) {
  .product-Details
    .product-details__contents
    .product-details__contents__center-details
    .inStock-label {
    font-size: 12px;
  }
  .product-Details
    .product-details__contents
    .product-details__contents__center-details
    .product-details__title {
    font-size: 20px;
  }
  .product-Details
    .product-details__contents
    .product-details__contents__center-details
    .product-details__options
    ul
    li {
    min-width: 70px;
    height: 30px;
    font-size: 12px;
  }
  .product-Details
    .product-details__contents
    .product-details__contents__center-details
    .product-details__options
    .option-title {
    margin-bottom: 5px;
  }
  .product-Details.product-Details-arabic
    .product-details__contents
    .product-details__contents__center-details
    .product-rates-orders-count
    a {
    margin: auto;
  }
  .product-Details
    .product-details__contents
    .product-details__contents__center-details
    .product-rates-orders-count {
    flex-wrap: wrap;
    a {
      margin: auto;
    }
  }
  .product-Details .product-details__contents .product-details__description p,
  .product-Details
    .product-details__contents
    .product-details__pre-order-note
    span {
    font-size: 12px;
  }
  .product-Details .product-details__reviews .product-details__reviews-ul li {
    padding: 0 10px;
    span {
      font-size: 16px;
    }
  }
  // Revise Section Start
  .product-Details
    .product-details__reviews
    .reviews-section
    .head
    .rate-total {
    align-items: flex-end;
  }

  .product-Details
    .product-details__contents
    .product-details__checkout-info
    h3,
  .product-Details
    .product-details__contents
    .product-details__checkout-info
    .h3,
  .product-Details
    .product-details__contents
    .product-details__checkout-info
    .h3 {
    font-size: 27px;
  }

  .product-Details
    .product-details__contents
    .product-details__checkout-info
    .estimated-delivery,
  .product-Details
    .product-details__contents
    .product-details__checkout-info
    .in-stock,
  .product-Details
    .product-details__contents
    .product-details__checkout-info
    .location-delivery {
    font-size: 12px;
  }
  .reviews-section__comment-container {
    .comment-content {
      flex-direction: column;
      padding: 20px !important;
    }
  }
  .product-Details .product-details__reviews .reviews-section .send-review {
    padding: 20px;
    .send-review__input {
      flex-direction: column !important;
      textarea {
        width: 100% !important;
      }
      .submit-btn {
        width: 100% !important;
        margin-top: 20px !important;
      }
    }
  }
  .product-Details
    .product-details__contents
    .image-gallery
    .image-gallery-slide-wrapper,
  .product-Details .product-details__contents .image-gallery {
    //height: 250px !important;
  }

  .product-details__video {
    height: 250px !important;
  }
}

@include respond(phone) {
  .product-Details
    .product-details__contents
    .product-details__checkout-info-parent
    .overlay,
  .product-Details
    .product-details__contents
    .product-details__checkout-info-parent
    .product-details__checkout-info {
    width: 50%;
  }

  .product-Details .checkout-side-bar .checkout-side-bar__contents {
    width: 55% !important;
  }
  .product-Details .checkout-side-bar .overlay {
    width: 42%;
  }
}

.spinner-border {
  border: 1px solid $gray_light_dark;
  border-right-color: transparent !important;
  margin-top: 20px !important;
  width: 20px !important;
  height: 20px !important;
}
