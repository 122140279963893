@import "../../abstract/mixins";

.cart-product {
    font-family: "poppinsRegular" !important;
    .CartRow {
        position: relative;
        padding: 30px 0 !important;
        border: 1px solid #d1d1d1;
        border-left: none !important;
        border-right: none !important;
        align-items: stretch !important;
        .cart-product__image {
            display: flex;
            justify-content: center;
            align-items: center;
            a {
                .cart-product__image__img {
                    max-height: 213px;
                }
            }
        }
        .info-container {
            display: flex;
            justify-content: space-around;
            align-items: flex-start;
            flex-direction: column;
            column-gap: 15px;
            .cart-product__info {
                .cart-product__info__link {
                    display: flex;
                    align-items: center;
                    column-gap: 10px;
                    .cart-product__info__title {
                        font-size: 19px;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-bottom: 0;
                        max-width: 298px;
                    }
                    span {
                        display: block;
                        color: #707070;
                        font-size: 15px;
                        font-weight: 400 !important;
                    }
                    .cart-product__info__link__preOrder {
                        font-weight: bold !important;
                    }
                }

                .cart-product__info__options {
                    font-size: 14px;
                    font-family: inherit;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .cart-product__info__price {
                }
            }
        }
        .removeFromCartBtn {
            position: absolute;
            background-color: #707070;
            max-width: 30px;
            max-height: 30px;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;
            right: 20px;
            cursor: pointer;
            transition: 0.3s;
            &:hover {
                background-color: #000;
                transition: 0.3s;
            }
        }
        .cart-product__material-sku {
            column-gap: 10px;
        }
    }
}

@media (max-width: 500px) {
    .cart-product .CartRow .info-container .cart-product__info .cart-product__info__title {
        font-size: 19px !important;
        -webkit-line-clamp: 0;
    }
    .CartRow span {
        font-size: 16px !important;
    }
    .cart-product .CartRow .info-container .cart-product__info .cart-product__info__options {
        -webkit-line-clamp: 1;
    }

    .cart-product .CartRow .info-container .cart-product__info .cart-product__info__link {
        display: block;
        margin-bottom: 10px !important;
        span {
            margin-top: 5px !important;
            font-size: 14px !important;
        }
    }
    .cart-product .CartRow .cart-product__material-sku {
        flex-wrap: wrap;
        span {
            font-size: 14px !important;
        }
    }
}

@media (max-width: 767px) {
    .cart-product__image {
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
    }
    .cart-product__info__title {
        max-width: 100%;
    }
}
