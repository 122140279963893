.products-filter {
    .FilterTitle {
        display: flex;
        justify-content: space-between;
        // border-bottom: 2px solid #f0f0f0;
        padding-bottom: 0px;
        margin-bottom: 23px;

        h2 {
            color: $primary-text-color;
            font-family: $PoppinsSemiBold;
            font-size: 25px;
            margin-bottom: 0;
        }

        a {
            font-size: 16px;
            font-family: "poppinsRegular";
            color: $primary-text-color !important;
            margin-top: 5px;
            cursor: pointer;
        }
    }

    .AccordionFilters {
        margin: 10px 0;

        .accordion-button {
            padding: 13px;
            background-color: $primary-text-color;
            color: #fff;
            font-family: $PoppinsSemiBold;
            border: 1px solid $primary-text-color;
            text-transform: capitalize;

            &::after {
                // color: #fff !important;
                filter: brightness(500);
            }
        }
        .accordion-flush .accordion-collapse {
            border: 1px solid $primary-text-color;
            border-bottom: 0;
            margin-bottom: -1px;
            border-radius: 0;
        }
        .with-border-bottom {
            border-bottom: 1px solid $primary-text-color;
        }
        .accordion-item {
            border-bottom: 1px solid #fff !important;
        }
        .accordion-item:last-of-type .accordion-button.collapsed {
            border-radius: 0 !important;
        }
    }

    // .accordion-button:not(.collapsed) {
    //     color: #515151;
    //     background-color: transparent;
    //     font-family: "poppinsBold";
    // }

    .accordion-body {
        padding: 1rem 9px;

        ul {
            margin: 0;
            padding: 0;
            margin-bottom: 32px;
            margin-top: 13px;
        }
    }

    /* Customize the label (the container) */
    .CehckBoxContainer {
        display: block;
        position: relative;
        padding-left: 32px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 15px;
        -webkit-user-select: none;
        user-select: none;
        font-family: "poppinsRegular";
        color: #575756;

        /* Hide the browser's default checkbox */
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        /* Create a custom checkbox */
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            border-radius: 4px;
            background-color: #fff;
            border: 2px solid #eee;
            color: $primary-text-color;
        }

        /* On mouse-over, add a grey background color */
        .CehckBoxContainer:hover input ~ .checkmark {
            background-color: $primary-text-color;
        }

        /* On mouse-over, add a grey background color */
        .CehckBoxContainer:hover input ~ .checkmark {
            background-color: $primary-text-color;
        }

        /* When the checkbox is checked, add a blue background */
        input:checked ~ .checkmark {
            background-color: $primary-text-color;
            border: 2px solid $primary-text-color;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }

        /* Show the checkmark when checked */
        input:checked ~ .checkmark:after {
            display: block;
            color: $primary-text-color;
        }

        /* Style the checkmark/indicator */
        .checkmark:after {
            border: solid white;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);

            left: 5px;
            top: 1px;
            width: 5px;
            height: 11px;
        }
        .CehckBoxContainer__upText {
            font-size: 14px;
        }
        .fa {
            color: $forth-text-color;
        }
        &.ActiveFa {
            .CehckBoxContainer__upText,
            .fa {
                color: $primary-text-color !important;
            }
        }
    }
    .CehckBoxContainerActive {
        color: $primary-text-color;
    }

    .btn-group {
        column-gap: 14px;
        input {
            padding: 7px 20px;
            background: none;
            border: 1px solid #f7f7f7;
            border-radius: 4px;
            color: #000000;
            text-align: center;
            &::placeholder {
                color: silver;
            }
        }
        button {
            padding: 7px 20px;
            background: none;
            border: 1px solid #fcfcfc;
            border-radius: 4px;
            color: #000000;
        }

        button:last-child {
            padding: 7px 20px;
            background: #fcfcfc;
            border: 1px solid #fcfcfc;
            border-radius: 4px;
            color: #000000;
        }

        button:last-child:hover {
            background: $primary-text-color;
            transition: 0.4s;
            color: #fff;
        }
    }

    /* The switch - the box around the slider */
    .switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 26px;
    }

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #d3d2d2;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 23px;
        width: 23px;
        left: 1px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    input:checked + .open_switch {
        background-color: $primary-text-color;
    }
    input:checked ~ .open_switch::before {
        background-color: #fff !important;
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
    input:focus + .slider {
        box-shadow: 0 0 1px $primary-text-color;
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
    .SwitchButt {
        display: flex;
        justify-content: space-between;
        margin-top: 18px;

        h3 {
            font-size: 16px;
            font-family: $poppinsBold-font;
            color: #575756;
        }
    }
    .input-range {
        margin: 20px auto;
        width: 80%;
        .input-range__label-container {
            bottom: -40px;
        }
        .input-range__label {
            color: $primary-text-color;
        }
        .input-range__label--max {
            display: none;
        }
        .input-range__label--min {
            display: none;
        }
        .input-range__label--value {
            // display: none;
        }
        .input-range__track--active {
            background-color: $primary-background-color;
        }
        .input-range__slider {
            background-color: #fff;
            border-color: $primary-background-color;
        }
    }
    &.products-filter-arabic {
        .FilterTitle {
            padding-left: 0;
        }
        .accordion-button::after {
            margin-left: 0;
            margin-right: auto;
        }

        .accordion-button::after {
            margin-left: 0;
            margin-right: auto;
        }
        .CehckBoxContainer {
            padding-left: 0;
            padding-right: 32px;
            .checkmark {
                left: auto;
                right: 0;
            }
        }
        .input-range {
            direction: ltr;
        }
        .input-range__label-container {
            // left: 50%;
        }
        .input-range__slider-container {
            // left: 100% !important
            // right: 100%;
        }

        .input-range__track--background {
            // left: 0;
            // margin-top: -0.15rem;
            // position: absolute;
            // right: 0;
            // top: 50%;
        }
        .FilterTitle a,
        .CehckBoxContainer,
        .AccordionFilters .accordion-button {
            font-family: inherit;
        }
    }
}

@include respond(laptop) {
    .products-filter {
        .AccordionFilters {
            .accordion-button {
                font-size: 14px;
            }
            .accordion-button::after {
                width: 1rem;
                height: 1rem;
                background-size: 1rem;
            }
        }
        .CehckBoxContainer {
            font-size: 13px;

            .checkmark {
                width: 17px;
                height: 17px;
                &::after {
                    border-width: 0 2px 2px 0;
                    left: 4px;
                    top: 1px;
                    width: 5px;
                    height: 9px;
                }
            }
        }
        .input-range .input-range__label-container {
            font-size: 11px;
            bottom: -35px;
        }
    }

    .products-filter-arabic {
        .FilterTitle a {
            font-size: 14px;
        }
    }
}

@include respond(large-phone) {
    .products-filter {
        width: 100%;
        left: 0;
        right: 0;
        z-index: 99999999999;
        background: white;
        top: 0;
        // height: 100vh;
        padding: 16px;
    }
    .podMob {
        display: inline !important;
    }
    .products-filter-arabic {
        .FilterTitle {
            font-size: 10px;
        }
    }
}

@include respond(phone) {
    .products-filter {
        // position: fixed;
        width: 100%;
        left: 0;
        right: 0;
        z-index: 99999999999;
        background: white;
        top: 0;
        // height: 100vh;
        padding: 16px;
    }

    .podMob {
        display: inline !important;
    }
}
