.AccordionFiltersCheckout {
  .accordion-button {
    color: #515151;
    background-color: $gray_light;
    font-family: "poppinsBold";
    padding: 15px 9px;
    margin-bottom: 13px;
  }

  .accordion-item {
    margin-bottom: 22px;
    border: none;
  }

  .accordion-body {
    padding: 1rem 45px;
    background: $gray_light;
  }
}

.accordion-button:not(.collapsed)::after {
  transform: rotate(-180deg);
  // filter: hue-rotate(67deg) brightness(100%);
  filter: brightness(0);
}
.Locations {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #ededed;
  padding-bottom: 24px;
  padding-top: 25px;

  .LocationInfo {
    display: flex;
    column-gap: 34px;

    h1 {
      // color: $light_base_color;
      font-size: 18px;
      font-family: $poppinsBold-font;
    }

    p {
      font-size: 14px;
      color: $gray_light_dark;
      font-family: "poppinsRegular";
    }

    h5 {
      font-size: 16px;
      color: $gray_light_dark;
      font-family: "poppinsRegular";
    }

    a {
      display: -webkit-inline-box;
      column-gap: 26px;
      line-height: 2.5;
    }
  }
  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 26px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border: 2px solid #ededed;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 21px;
    left: 2px;
    bottom: 1px;
    background-color: #dee2e6;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: $primary-text-color;
  }

  // input:focus + .slider {
  //     box-shadow: 0 0 1px $primary-text-color;
  // }

  input:checked + .slider:before {
    -webkit-transform: translateX(21px);
    -ms-transform: translateX(21px);
    transform: translateX(21px);
    color: #fff;
    background: #fff;
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  .SwitchButt {
    display: flex;
    justify-content: space-between;
    margin-top: 18px;

    h3 {
      font-size: 16px;
      font-family: $poppinsBold-font;
      color: #575756;
    }
  }

  .LocationUserEmailPhone {
    display: flex;
    justify-content: space-between;
  }
}

.LocationsAdd {
  display: flex;
  justify-content: space-between;
  border-bottom: 0px solid #ededed;
  padding-bottom: 0;
  padding-top: 0;
}

.AddToCartSpan span {
  cursor: pointer;
  padding-top: 5px;
}

.AddToCartSpan span:hover {
  color: #611445;
}

.PaymentMethodAcc {
  padding: 0px;
  .CehckBoxContainer_ {
    // display: block;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 46px;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    user-select: none;
    font-family: "poppinsRegular";
    color: #575756;
    line-height: 2;
    .CehckBoxContainer_span {
      font-size: 16px;
    }
    input {
      display: none;
    }
    img {
      width: 45px;
      height: 35px;
      object-fit: scale-down;
    }
    .mark {
      position: absolute;
      // top: 0;
      left: 0;
      height: 27px;
      width: 27px;
      border-radius: 16px;
      background-color: #3333;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 11px;
        height: 11px;
      }
      &:after {
        left: 10px;
        top: 4px;
        width: 7px;
        height: 15px;
      }
    }
    input:checked ~ .mark {
      background-color: $primary-text-color !important;
    }
  }
}

.TotalCart {
  ol {
    padding: 0;
    margin: 9px;
    list-style: none;

    li {
      display: flex;
      justify-content: space-between;
      column-gap: 5px;

      p {
        color: $dark_title;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

        strong {
          color: $primary-text-color;
        }
      }
      span {
        white-space: nowrap;
      }
    }
  }
}

.create-account__container {
  display: block;
  position: relative;
  padding-left: 40px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $secondary-text-color;
  &.create-account__active {
    color: $primary-text-color;
  }
  p {
    margin-top: 2.5px;
  }
}

/* Hide the browser's default checkbox */
.create-account__container .create-account__container__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.create-account__checkmark {
  position: absolute;
  top: 6px;
  left: 10px;
  height: 16px;
  width: 16px;
  background-color: #3333;
  border-radius: 50%;
  transition: all ease-in-out 0.2s;
}

/* On mouse-over, add a grey background color */
.create-account__container:hover
  .create-account__container__input
  ~ .create-account__checkmark {
  background-color: #ccc;
  box-shadow: 0 0 0 0.05rem rgba(233, 233, 233, 0.986);
  transition: all ease-in-out 0.2s;
}

/* When the checkbox is checked, add a blue background */
.create-account__container
  .create-account__container__input:checked
  ~ .create-account__checkmark {
  background-color: $primary-text-color;
  border-radius: 50%;
  box-shadow: 0 0 0 0.05rem rgba(117, 33, 87, 0.8);
  transition: all ease-in-out 0.2s;
}
/* Create the checkmark/indicator (hidden when not checked) */
.create-account__checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.create-account__container
  .create-account__container__input:checked
  ~ .create-account__checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.create-account__container .create-account__checkmark:after {
  left: calc(50% - 2.5px);
  top: calc(50% - 5px);
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.form-select {
  margin-bottom: 26px;
  background-size: 25px 20px;
}

.AddLocationButt {
  display: flex;
  justify-content: space-between;
  margin: 30px 0 0 0;

  a {
    cursor: pointer;
    color: $gray_light_dark;
    padding-top: 10px;

    &:hover {
      color: $primary-text-color;
      transition: 0.5s;
    }
  }

  .Add {
    background: #752157;
    padding: 5px 27px 7px;
    border-radius: 5px;
    color: #ffffff;
  }
}
.valid-coupon-list {
  padding: 0;
  margin-bottom: 0;
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    background-color: #00ff0014;
    border-radius: 5px;
    span {
      font-size: 12px;
      color: $primary-text-color;
      &:last-child {
        color: #ff0000ba;
        cursor: pointer;
      }
    }
  }
}
//
.text-input-block .input-group {
  padding: 8px 15px;
  margin: 0 !important;
  margin-bottom: 20px !important;
  max-width: 100%;
}

.special-instruction-container {
  margin-top: 20px !important;
}
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  // padding: 2px 9px;
}
// Arabic Style Start
.Cart-Arabic {
  .accordion-button::after {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
  .create-account__container {
    padding-left: 0px;
    padding-right: 40px;
  }
  .create-account__checkmark {
    left: auto;
    right: 10px;
  }
}
// Arabic Style End
@include respond(laptop) {
  .TotalsCart .PriceSpan {
    font-size: 14px !important;
  }
}
@include respond(small-laptop) {
  .AccordionFiltersCheckout .accordion-body {
    padding: 1rem 20px;
    background: #f8f8f8;
  }
  .PaymentMethodAcc .CehckBoxContainer_ .CehckBoxContainer_span {
    font-size: 12px;
  }
}

@include respond(large-phone) {
  .TotalsCart .PriceSpan {
    font-size: 14px;
  }
}
