@import "../../../abstract/mixins";

.about {
    h4 {
        font-size: 38px;
        font-family: $PoppinsSemiBold;
        margin-bottom: 0;
        &:first-child {
            margin-top: 80px;
        }
    }
    h2 {
        font-size: 46px;
        font-family: $poppinsBold-font;
        margin-bottom: 25px;
    }
    p {
        font-size: 16px;
    }
    .about__first-section {
        margin-bottom: 100px;
        .col {
            // padding: 0 30px;
        }
        img {
            width: 100%;
        }
    }
    .about__second-section {
        margin-bottom: 100px;

        img {
            width: 100%;
        }
        h4 {
            margin-top: 45px;
            margin-bottom: 22px;
        }
        .col {
            position: relative;
            padding: 0 30px;
            .about__second-section__img {
                position: absolute;
                bottom: -70px;
                left: 0px;
                width: 189px;
                height: 167px;
            }
        }
    }
    &.about-arabic {
        .about__second-section {
            .col {
                .about__second-section__img {
                    bottom: -70px;
                    left: auto;
                    right: 0px;
                }
            }
        }
    }
}

@include respond(laptop) {
    .about {
        h4 {
            font-size: 32px;
        }
        h2 {
            font-size: 40px;
        }
        p {
            font-size: 14px;
        }
        .about__second-section {
            .col {
                .about__second-section__img {
                    width: 172px;
                    height: 150px;
                }
            }
        }
    }
}

@include respond(laptop) {
    .about {
        h4 {
            font-size: 28px;
            &:first-child {
                margin-top: 40px;
            }
        }
        h2 {
            font-size: 32px;
            margin-bottom: 25px;
        }
        p {
            font-size: 13px;
        }
        .about__first-section {
            margin-bottom: 100px;
            .col {
                // padding: 0 30px;
            }
            img {
                width: 100%;
            }
        }
        .about__second-section {
            margin-bottom: 100px;

            img {
                width: 100%;
            }
            h4 {
                margin-top: 45px;
                margin-bottom: 22px;
            }
            .col {
                padding: 0 30px;
                .about__second-section__img {
                    bottom: -80px;
                    left: 0px;
                    width: 130px;
                    height: 110px;
                }
            }
        }
    }
}

@include respond(tablet) {
    .about {
        .about__first-section {
            margin-bottom: 70px;
            .row {
                flex-direction: column-reverse;
            }
        }
        .about__second-section {
            margin-bottom: 50px;

            .col {
                .about__second-section__img {
                    display: none;
                }
            }
        }
    }
}

@include respond(phone) {
    .about {
        h4 {
            font-size: 22px;
            &:first-child {
                margin-top: 25px;
            }
        }
        h2 {
            font-size: 24px;
            margin-bottom: 15px;
        }
        p {
            font-size: 12px;
        }
        .about__first-section {
            margin-bottom: 70px;
        }
        .about__second-section {
            margin-bottom: 50px;
            h4 {
                margin-top: 25px;
                margin-bottom: 15px;
            }
            .col {
                padding: 0 30px;
            }
        }
    }
}
