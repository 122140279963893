@import "../../../abstract/mixins";

.search {
    min-height: 50vh;
}
.Pagination {
    // flex-wrap: wrap;
    ul {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 10px;
        margin-bottom: 0;
        padding: 10px 0;
        li {
            a {
                display: block;
                padding: 5px;
                background-color: #fff;
                text-align: center;
                color: $primary-text-color;
            }
        }
    }
}
.search-title {
    padding: 40px 0 20px;
    color: $dark_title;
    .search-title__Word {
        color: $primary-text-color;
    }
}
@include respond(tablet) {
    .search-title {
        padding: 40px 0 0;
    }
}
