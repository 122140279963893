@import "../abstract/mixins";
:root {
  font-size: 16px;

  @include respond(custom-media, 0, 576px) {
    font-size: 12px;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: $main-font;

  min-height: 100vh;
  text-transform: capitalize;

  position: relative;
  color: $primary-text-color;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  &::-webkit-scrollbar-thumb {
    background: darken($primary-text-color, 10%);
    border-radius: 20px;
  }
}
.container-fluid {
  max-width: 1570px !important;
}
ul {
  list-style: none;
}

a {
  text-decoration: none !important;
  color: inherit;

  &:hover,
  &:active,
  &:focus {
    color: inherit;
  }
}

button,
input,
textarea {
  font-size: 16px;
  border: none;
}

input,
select,
textarea {
  font-family: inherit;
  width: 100%;
}
.no-padd {
  padding: 0 !important;
}
.no-margin {
  margin: 0 !important;
}
.margin-auto {
  margin: auto !important;
}
button:not(:disabled),
select,
input[type="submit"],
input[type="date"],
input[type="color"] {
  cursor: pointer;
}
.no-wrap {
  flex-wrap: nowrap !important;
}
input,
textarea {
  color: inherit;

  &::-webkit-input-placeholder {
    font: 500 smaller sans-serif;
    text-transform: capitalize;
    color: inherit;
    transition: opacity 0.3s;
  }

  &:focus::-webkit-input-placeholder {
    opacity: 0;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    color: $primary-text-color !important;
    box-shadow: 0 0 0 5rem white inset !important;
  }
}

input {
  &[type="number"] {
    appearance: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      margin: 0;
      appearance: none;
    }
  }

  &[type="color"] {
    appearance: none;

    &::-webkit-color-swatch {
      border: none;
    }
  }
}

textarea {
  resize: none;
}

button:disabled {
  cursor: auto;
}

button:focus,
:focus {
  outline: none !important;
  box-shadow: none !important;
}
.flex {
  display: flex;
  align-items: center;
}
.clickable {
  cursor: pointer;
}
.capitalize {
  text-transform: capitalize;
}
.primary-color {
  color: $primary-text-color;
}

.d-block {
  display: block;
}

.validation-message--underline {
  font-size: 90%;
  color: red;
}
.div-radio {
  width: 20px;
  height: 20px;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 7px;
  border-radius: 50%;
  border: 2px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 2px;
  div {
    background-color: $primary-background-color;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    opacity: 0;
  }
  &.div-radio-active {
    border-color: #707070;
    div {
      opacity: 1;
    }
  }
}
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 49px;
  margin-top: 32px;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  text-transform: capitalize;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 5px;
  left: 5px;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #000;
}
:lang(ar) {
  .addresses__block .checkmark {
    right: auto !important;
    left: 13px !important;
  }
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #000;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 6px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.phone-number {
  border-radius: 0 !important;
  border-left: none !important;
  // height: 49px !important;
  height: 42px !important;
  font-size: inherit;
  font-family: inherit;
  margin-bottom: 20px;
  .text-input-block {
    border: 1px solid #707070 !important;
    border-left: none !important;
    margin: 0 !important;
    border-radius: 0 !important;
    width: 100% !important;

    .input-group {
      border: none !important;
      margin: 0 !important;
      // height: 47px !important;
      height: 40px !important;
      .form-control {
        &::placeholder {
          color: #707070 !important;
          font-family: inherit !important;
        }
      }
    }
  }

  .phone-zipcode {
    background-color: #fff;
    border: 1px solid #707070;
    color: #000;
    border-radius: 0 !important;
  }
  .register-phone-zipCode {
    padding: 0 !important;
    select {
      width: 90px;
      height: 100%;
      padding: 0 5px;

      appearance: none !important;
      background-color: white;

      /* ///// */
      background: #fff url("../../../ddd.svg") no-repeat !important;
      background-position-y: center !important;
      background-position-x: 75px !important;
      border: 0;
    }
  }
}
html[dir="rtl"] {
  .phone-number {
    .text-input-block {
      border-left: 1px solid #707070 !important;
      border-right: none !important;
    }
  }
}

.border-none {
  border: none !important;
}

.border-bot {
  border-bottom: 1px solid #d1d1d1;
}
.border-top {
  border-top: 1px solid #d1d1d1;
}
.bold-18 {
  font-size: 18px;
  font-family: $poppinsBold-font;
}
// quantiti
.quantity-input {
  display: flex;
  align-items: center;
}
.quantity-input__modifier--left {
  padding-top: 2px;
}
.quantity-input__modifier,
.quantity-input__screen {
  user-select: none;
  outline: none;
}

.margin-top-40 {
  margin-top: 40px !important;
}
.text-start {
  text-align: start !important;
}
.quantity-input__modifier {
  width: 47px;
  font-size: 2rem;
  height: 48px;
  background: #000;
  color: #fff;
  border: 1px solid #000;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background: #fff;
    color: #000;
    transition: 0.3s;
  }
}

.quantity-input__screen {
  width: 4rem;
  font-size: 17px;
  height: 48px;
  border: 0;
  border: 1px solid #000;
  border-right: 0;
  border-left: none;
  text-align: center;
}

@media (max-width: 500px) {
  .quantity-input__screen {
    height: 38px !important;
  }
  .quantity-input__modifier {
    height: 38px !important;
  }
}

// Active slide border
.active-bar-container {
  max-width: 478px;
  height: 4px;
  margin: auto;
  position: relative;
  .active-bar {
    display: block;
    height: 100%;
    max-width: 210px;
    background-color: #000;
    position: absolute;
    right: 0;
  }
}
.swal-overlay {
  font-family: "Segoe UI", sans-serif;

  .swal-button--confirm,
  .swal-button--Login,
  .swal-button--Register {
    background-color: $primary-text-color;

    &:hover {
      background-color: darken($primary-text-color, 4%);
    }
  }
}
.noButtons {
  // opacity: 0.9 !important;
  background-color: #ffffffde;
  .swal-icon--success:after,
  .swal-icon--success__hide-corners,
  .swal-icon--success:before {
    background-color: transparent;
  }
  .swal-button-container {
    display: none !important;
  }
}

/* loader section start  */

.lds-ring {
  display: block;
  position: relative !important;
  width: 24.5px !important;
  height: 24.5px !important;
  margin: auto;
  border: 0 !important;
  top: 50% !important;
  div {
    box-sizing: border-box !important;
    display: block !important;
    position: absolute !important;
    width: 25px !important;
    height: 25px !important;
    /* margin: 8px; */
    border: 6px solid white !important;
    border-radius: 50% !important;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite !important;
    border-color: white transparent transparent transparent !important;
    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.select__value-container--has-value {
  .select__single-value {
    font-weight: bold;
    // color: $primary-text-color !important;
    color: #000 !important;
  }
}
.css-1s2u09g-control {
  &:hover {
    border-color: $primary-text-color !important;
  }
}
sup {
  padding: 0 3px;
}

.submit-btn {
  text-transform: uppercase;
  width: 100%;
  background-color: $primary-background-color;
  color: $white-color;
  // font-weight: 600;
  font-size: 20px;
  border: 1px solid #000;
  transition: 0.7s;
  &:hover {
    background-color: #fff;
    color: #000;
    transition: 0.7s;
    border: 1px solid #000;
  }
}
.social-btns {
  justify-content: space-between;
  column-gap: 10px !important;
  div {
    label {
      width: 100% !important;
    }
    .kep-login-facebook,
    .gl-btn {
      background-color: transparent !important;
      content: "";
      width: 207px !important;
      height: 49px !important;
      border: none;
      z-index: 99999;
      transition: opacity 0.5s ease 0s;
      opacity: 0;
      box-shadow: none !important;
    }
    // .gl-btn {
    //     div {
    //         display: none;
    //     }
    // }
  }
  &.social-btns-signUp {
    div {
      .gl-btn {
        div {
          display: none;
        }
      }
    }
  }
  .login-google-label {
    min-width: 207px !important;
  }
  .signUp-google-label {
    min-width: 207px !important;
    margin-top: -20px;
  }
  .socialBtn-login {
    width: 100% !important;
    height: 100% !important;
    display: block !important;
    opacity: 0 !important;
    position: relative !important;
    div {
      width: 100%;
      z-index: 999999999999;
      height: 100%;
      position: relative;
      margin: 0 !important;
    }
  }
}
.signup-login-arabic .phone-number .register-phone-zipCode select {
  background-position-x: 5px !important;
}

.pr-3 {
  padding-right: 25px;
  margin-bottom: 50px;
}
.pl-3 {
  padding-left: 25px;
  margin-bottom: 50px;
}

.product-details__options {
  .option-title {
    justify-content: space-between;
    border-bottom: 1px solid #c6c6c6;
    padding-bottom: 5px;
    margin-bottom: 15px;
    margin-top: 10px;
    span {
      color: $secondary-text-color;
      font-family: $PoppinsSemiBold;
      font-size: 16px;
      display: block;
      text-transform: capitalize;
    }
    .Sizes-Gide-button {
      color: #444444;
      font-size: 14px;
      cursor: pointer;
      width: fit-content;
      text-decoration: underline;
      font-family: $poppinsMedium-font;
      margin-bottom: 0;
      &:hover {
      }
    }
  }
  ul {
    padding: 0;
    margin-bottom: 2px;
    column-gap: 6px;
    flex-wrap: wrap;
    li {
      cursor: pointer;
      padding: 5px;
      border: 1px solid #707070;
      opacity: 0.7;
      min-width: 90px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 5px;
      border-radius: 10px;
      span {
        padding-top: 3px;
      }
      &.option-img {
        width: 27px !important;
        height: 27px !important;
        min-width: 0 !important;
        border-radius: 50%;
        padding: 0;
        overflow: hidden;
        // border-color: transparent;
        border-style: hidden;
      }
      img {
        width: 100%;
        height: 100%;
      }
      &:hover {
        opacity: 1;
      }
      &.optionSelected {
        background-color: $primary-background-color;
        border-color: $primary-background-color;

        border-style: solid;
        opacity: 1;
        color: #fff;
      }
    }
  }
}

@media (max-width: 576px) {
  .social-col {
    padding-bottom: 45px !important;
    .social-btns {
      margin-top: 30px !important;
      .gl-label {
        margin-top: 0 !important;
      }
      .socialBtn {
        width: 100% !important;
      }
    }
  }
}
@for $i from 1 through 3 {
  .ls-#{$i} {
    letter-spacing: #{$i}px;
  }
}

.btn--primary {
  font-weight: 500;
  display: block;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 10px;
  padding: 11px 0;
  color: white;
  background-color: $primary-text-color;
  border: 0;

  &:disabled {
    background-color: $secondary-text-color;
  }
}
.section-title {
  color: $primary-text-color;
  font-family: $poppinsBold-font;
  font-size: 30px;
  margin-bottom: 20px;
}
.table {
  margin-bottom: 0;
}
.submit-button {
  display: block;
  margin-bottom: 20px;
  min-width: 180px;
  border-radius: 5px;
  padding: 7px;
  transition: ease-in-out 0.4s;
  border: 2px solid $primary-text-color;
  background-color: transparent;
  color: $primary-text-color;
  font-size: 20px;
  &:hover {
    background-color: $primary-text-color;
    color: #fff;
    transition: ease-in-out 0.4s;
  }
}
.submit-button2 {
  display: block;
  margin-bottom: 20px;
  background-color: $primary-text-color;
  color: #fff;
  min-width: 180px;
  // border-radius: 20px;
  padding: 8px;
  transition: ease-in-out 0.4s;
  border: 1px solid $primary-text-color;
  &:hover {
    background-color: #fff;
    color: $primary-text-color;
    transition: ease-in-out 0.4s;
  }
}
.btn {
  border: 0;
}
.main-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .lds-default {
    position: relative;
    width: 80px;
    height: 80px;
  }

  .lds-default div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: $primary-text-color;
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
  }

  .lds-default div:nth-child(1) {
    top: 37px;
    left: 66px;
    animation-delay: 0s;
  }

  .lds-default div:nth-child(2) {
    top: 22px;
    left: 62px;
    animation-delay: -0.1s;
  }

  .lds-default div:nth-child(3) {
    top: 11px;
    left: 52px;
    animation-delay: -0.2s;
  }

  .lds-default div:nth-child(4) {
    top: 7px;
    left: 37px;
    animation-delay: -0.3s;
  }

  .lds-default div:nth-child(5) {
    top: 11px;
    left: 22px;
    animation-delay: -0.4s;
  }

  .lds-default div:nth-child(6) {
    top: 22px;
    left: 11px;
    animation-delay: -0.5s;
  }

  .lds-default div:nth-child(7) {
    top: 37px;
    left: 7px;
    animation-delay: -0.6s;
  }

  .lds-default div:nth-child(8) {
    top: 52px;
    left: 11px;
    animation-delay: -0.7s;
  }

  .lds-default div:nth-child(9) {
    top: 62px;
    left: 22px;
    animation-delay: -0.8s;
  }

  .lds-default div:nth-child(10) {
    top: 66px;
    left: 37px;
    animation-delay: -0.9s;
  }

  .lds-default div:nth-child(11) {
    top: 62px;
    left: 52px;
    animation-delay: -1s;
  }

  .lds-default div:nth-child(12) {
    top: 52px;
    left: 62px;
    animation-delay: -1.1s;
  }

  @keyframes lds-default {
    0%,
    20%,
    80%,
    100% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.5);
    }
  }
}
.radio-type-field {
  column-gap: 20px;
}
.dateBlock .react-datepicker-wrapper {
  width: 100%;
}

.css-6j8wv5-Input {
  margin: 0px !important;
  padding: 0px !important;
}

.react-multi-carousel-list {
  direction: ltr !important;
}
.carousel-item-margin-25-px {
  padding: 0 15px;
}
.empty-products {
  text-align: center;
  opacity: 0.8;
  img {
    width: 100%;
    // max-width: 200px;
    max-width: 400px;
  }
  p {
    color: $primary-text-color;
    text-align: center;
    font-size: 1.1rem;
  }
}
@include respond(small-laptop) {
  .submit-button {
    font-size: 14px;
  }

  .column-reverse-under992 {
    flex-direction: column-reverse;
  }

  .section-title {
    font-size: 25px;
    margin-bottom: 20px;
  }

  .phone-number .register-phone-zipCode select {
    width: 75px;
    background-position-x: 60px !important;
    font-size: 12px;
  }
}

@include respond(tablet) {
  .column_reverse_under768 {
    flex-direction: column-reverse;
  }
  .section-title {
    font-size: 22px;
  }
  .signup-login {
    .phone-number {
      .phone-number__zipcode {
        width: 104px !important;
      }
    }
  }
}

@include respond(small-laptop) {
  .pr-3 {
    padding-right: 15px;
    margin-bottom: 30px;
  }
  .pl-3 {
    padding-left: 15px;
    margin-bottom: 30px;
  }
}

@include respond(large-phone) {
  .section-title {
    font-size: 18px;
  }
  .login-google-label {
    margin-top: 15px;
  }
  .pr-3 {
    padding-right: 0.75rem;
    margin-bottom: 1.5rem;
  }
  .pl-3 {
    padding-left: 0.75rem;
    margin-bottom: 1.5rem;
  }
}
.swal-icon.swal-icon--warning {
  border-color: red !important;
  * {
    border-color: red !important;
  }
}

.swal-icon--warning__body,
.swal-icon--warning__dot {
  background-color: red !important;
}

.my-input,
.my-input .select__control {
  min-height: 45px !important;
  height: 45px !important;
  max-height: 45px !important;
}
