@import "../../../abstract/mixins";
@import "../Cart/checkout";
@import "./cartSummaryBlock";
@import "./newCheckOut";

.Cart {
    overflow: hidden;
    // min-height: 50vh;
    height: 100% !important;
    .cart-summary-container {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }
}

@media (max-width: 991px) {
    .Cart {
        .cart-summary-container {
            display: block;
        }
    }
}

.CartTitle {
    background: $gray_light;
    padding: 12px 0;
    margin-bottom: 32px;

    h2 {
        color: $dark_title;
        font-size: 25px;
        font-family: "poppinsRegular";
        display: flex;
        column-gap: 39px;
        margin-bottom: 0;
        text-transform: capitalize;
        h4 {
            color: #c2c2c2;
            font-size: 17px;
            padding-top: 4px;
        }
    }
}
.CartAds {
    a {
        display: block;
        img {
            width: 100%;
        }
    }
}
.CartRow {
    display: flex;
    align-items: center;
    margin: 0 !important;
    width: 100% !important;
    sup {
        vertical-align: super !important;
        top: 0 !important;
    }

    .product__title {
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: bold !important;
    }

    span {
        color: $primary-text-color;
        font-size: 20px;
        font-family: "poppinsBold";
    }

    a:hover {
        color: $primary-text-color;
        transition: 0.5s;
    }

    .product__quantity {
        margin-top: 29px;
        padding: 0px 73px 0 0;
    }

    .product__quantity__ar {
        padding: 0px 0 0 73px;
    }
}
.clear-cart {
    text-align: center;
    text-decoration: underline;
    font-size: 18px;
    cursor: pointer;
    width: fit-content;
    margin: auto;
}
// .TotalCart {
//     background: #f9f9f9;
//     padding: 8px;
//     border-radius: 4px;
//     margin-bottom: 28px;

//     h3 {
//         font-size: 18px;
//         color: #3d3d3d;
//         font-family: "poppinsBold";
//     }

//     .btn-outline-secondary {
//         color: #fff;
//         border-color: #6c757d;
//         background: $secondary-background-color;
//         border-radius: 7px;
//     }

//     .TotalsCart {
//         display: flex;
//         justify-content: space-between;
//         padding: 5px;
//         border-radius: 4px;
//         margin-top: 0px;

//         .SubTotalSpan {
//             column-gap: 5px;
//             font-family: "poppinsBold";
//             color: #515151;
//             padding-top: 7px;
//             font-size: 16px;
//         }

//         .SubTotalSpanCount {
//             font-family: "poppinsRegular";
//             font-size: 13px;
//             padding: 0 12px;
//             white-space: nowrap;
//         }

//         .PriceSpan {
//             color: $primary-text-color;
//             font-family: "poppinsRegular";
//             font-size: 25px;
//         }

//         .button-addon3 {
//             width: 100%;
//             text-align: center;
//         }

//         .buttAr {
//             border-top-left-radius: 7px !important;
//             border-bottom-left-radius: 7px !important;
//             border-bottom-right-radius: 0 !important;
//             border-top-right-radius: 0 !important;
//         }
//     }
// }

// .buttAr {
//     border-top-left-radius: 7px;
//     border-bottom-left-radius: 7px;
//     border-bottom-right-radius: 0 !important;
//     border-top-right-radius: 0 !important;
// }

// .SeeMore {
//     width: 100px;
// }

// .CartFav {
//     margin-top: 80px;

//     h1 {
//         color: $primary-text-color;
//         font-size: 30px;
//         font-family: "poppinsBold", sans-serif;
//         white-space: nowrap;
//         margin-bottom: 37px;
//     }

//     a {
//         font-family: $poppinsMedium-font;
//         font-size: 14px;
//     }

//     // .block__header {
//     //   margin: 0 0px;
//     //   display: flex;
//     //   align-items: baseline;
//     //   justify-content: space-between;
//     //   margin-bottom: 0;

//     //   .hr {
//     //     width: -webkit-fill-available;
//     //     height: 2px;
//     //     background-color: #e2e2e2;
//     //     margin: 0 20px;
//     //     margin-bottom: 44px;
//     //     margin-top: auto;
//     //   }
//     // }

//     // .wishlist-view-title {
//     //   display: flex;
//     //   justify-content: space-between;
//     //   gap: 15px;
//     //   align-items: baseline;
//     //   .wishlist-title {
//     //     display: flex;
//     //     margin: auto 0;
//     //     h1 {
//     //       margin: auto 0;
//     //     }
//     //   }
//     //   span {
//     //     height: 2px;
//     //     width: 100%;
//     //     display: block;
//     //     background-color: #e2e2e2;
//     //     margin-top: 20px;
//     //   }
//     // }

//     .categories-block__header {
//         margin: 0 15px;

//         display: flex;
//         align-items: baseline;
//         justify-content: space-between;
//         margin-bottom: 25px;
//         h3 {
//             font-size: 30px;
//             color: $primary-text-color;
//             font-family: $poppinsBold-font;
//             white-space: nowrap;
//             margin-bottom: 0;
//         }
//         .hr {
//             width: -webkit-fill-available;
//             height: 2px;
//             background-color: #e2e2e2;
//             margin: 0 20px;
//             margin-bottom: 7px;
//             margin-top: auto;
//         }
//         .view-all {
//             color: $primary-text-color;
//             display: flex;
//             align-items: center;
//             column-gap: 10px;
//             span {
//                 font-family: $poppinsBold-font;
//                 white-space: nowrap;
//             }
//         }
//     }

//     a:first-child {
//         color: $primary-text-color;
//     }

//     a:last-child:hover {
//         color: $primary-text-color;
//         transition: 0.5s;
//     }
// }

// .CartAction {
//     display: flex;
//     column-gap: 34px;
//     div {
//         display: flex;
//         align-items: center;
//         cursor: pointer;
//         transition: ease-in-out 0.3s;

//         // &:hover {
//         //     color: $primary-text-color;
//         //     transition: ease-in-out 0.3s;
//         // }
//         img {
//             width: 15px;
//         }
//         .fa {
//             margin-top: 1px;
//         }
//     }
// }
// .Cart .cart-product-footer {
//     margin-top: 20px;
// }
// // Arabic Style Start
// .Cart-Arabic .CartFav .categories-block__header .view-all img {
//     transform: scale(-1);
// }
// // Arabic Style End
// @include respond(laptop) {
//     .Cart {
//         .product__quantity {
//             padding: 0px 3px 0 0;
//         }
//         .SeeMore {
//             width: 120px;
//         }
//     }
// }
// @include respond(small-laptop) {
//     .FavAction {
//         flex-direction: column;
//         gap: 4px;
//     }
// }
// @include respond(tablet) {
//     .Cart .product__price__new-price {
//         margin-top: 0px;
//     }
//     .Cart .cart-product-footer {
//         display: flex;
//         justify-content: flex-end;
//         gap: 10px;
//         margin-top: 13px;
//         align-items: baseline;
//         margin-top: 0;
//     }
//     .CartRow .product__quantity {
//         //
//         margin-top: 0px;
// 
// }

// @include respond(phone) {
//     .Cart {
//         //
//         .product__price__new-price {
//             margin-top: 0px;
//         }

//         .CartRow {
//             align-items: center;
//             padding: 0 12px;

//             .product__quantity {
//                 padding: 0px 3px 0 0;
//             }
//         }
//         .SeeMore {
//             width: 207px;
//         }
//         //
//         .CartAction {
//             row-gap: 10px;
//             flex-wrap: wrap;
//         }
//     }
// }
