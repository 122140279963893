@import "../../../abstract/mixins";
@import "./profile";
@import "./addresses";
@import "./orders";
@import "./layalty";

.account-page {
    margin-top: 45px;
    margin-bottom: 98px;
    // title start
    .account-page__title {
        h3 {
            font-size: 24px;
            color: $primary-text-color;
            text-transform: uppercase;
        }
        h4 {
            font-size: 16px;
            color: $secondary-text-color;
            margin-bottom: 33px;
        }
    }
    .account-page__title__gift-card {
        h3 {
            font-size: 22px;
            color: $primary-text-color;
            text-transform: uppercase;
            font-family: $poppinsMedium-font;
            margin-bottom: 25px;
        }
    }
    // title end
    // Menu Start
    .board {
        h3 {
            font-size: 24px;
            color: #515151;
        }

        .board__signOut {
            display: inline-block;
            font-size: 16px;
            color: $secondary-text-color;
            margin-bottom: 33px;

            &:hover {
                color: $primary-text-color;
                transform: scale(1.05);
                transition: ease-in 0.1s;
            }
        }
        .menu {
            // background-color: #f7f7f7;
            border: 1px solid $primary-text-color;
            // border-radius: 10px;

            ul {
                display: flex;
                flex-direction: column;
                gap: 0px;
                padding: 0;
                margin: 0;

                li {
                    transition: ease-in-out all 0.3s;
                    cursor: pointer;
                    padding: 7px 25px;
                    border-bottom: 1px solid #d1d1d1;
                    &:last-child {
                        border-bottom: 0;
                    }

                    img {
                        object-fit: scale-down;
                    }
                    h6 {
                        font-size: 18px;
                        padding-top: 7px;
                    }
                    &:hover {
                        background-color: $primary-text-color;
                        transition: ease-in-out all 0.3s;
                    }
                    a {
                        width: 100%;

                        justify-content: space-between;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }
    // Menu End
}

.account-page {
    // Mobile Side Menu start
    .mobile-push-menu {
        .display-none {
            display: none;
        }
        display: none;
        color: white;
        width: 45px;
        position: fixed;
        z-index: 9;
        right: 10px;
        top: 70px;
        ul {
            padding: 33px 0px;
            li {
                padding: 12px 24px;
                transition: ease-in-out all 0.4s;
                a {
                    display: flex;
                    // gap: 12px;
                    justify-content: space-between;
                }

                img {
                    width: 25px;
                    height: 24px;
                    object-fit: scale-down;
                    filter: brightness(500);
                }

                &:hover {
                    background-color: white;
                    transition: ease-in-out all 0.4s;
                    h6 {
                        color: $primary-text-color;
                    }
                    img {
                        filter: none;
                    }
                }
            }
        }
        .bm-icon {
            font-size: 35px;
            color: $secondary-text-color;
            color: white;
        }

        .bm-cross-button {
            position: absolute;
            width: 24px;
            height: 24px;
            right: 0px !important;
            left: auto !important;
            top: 8px;
        }
    }
    .bm-overlay {
        top: 0;
        left: 0;
        z-index: 9888888 !important;
    }

    .bm-burger-button {
        position: relative !important;
        margin-right: auto;
        color: black;
        padding-top: 3px;
        font-size: 15px;
        background: $primary-background-color;
        text-align: center;
        box-shadow: 0px -1px 6px #515151e8;
    }

    .bm-menu-wrap {
        top: 0;
        left: 0;
        z-index: 9999999999 !important;
        width: 40% !important;
        background-color: $primary-text-color;
    }

    .sideMenu_logo {
        text-align: center;
        background-color: #fff;

        img {
            margin: auto;
            padding: 8px 0;
        }

        &:focus {
            outline: none !important;
        }
    }

    .sideMenu_List {
        margin-top: 20px;
        padding: 0 10px;
        h3 {
            color: #fff;
            border-bottom: 1px solid #fff;
            padding-bottom: 10px;
            margin-left: -20px;
            padding-left: 5px;
        }
        span,
        a,
        button {
            display: block;
            padding: 5px;
            color: #ffffff !important;
            text-align: left;
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
                padding: 5px;
                border-radius: 50px;
                width: 35px;
                height: 35px;
                background-color: yellow;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border: 0;
                font-size: 13px;
                i {
                    font-size: 10px;
                }
            }
        }

        span {
            font-weight: bold;
            letter-spacing: 1px;
        }

        li {
            text-transform: capitalize;
            border-bottom: 1px solid #ffffff6e;

            &:not(:last-of-type) {
                margin-bottom: 1rem;
            }
        }

        button {
            width: 100%;
            text-align: left;
            background: transparent;
        }
    }

    .sideMenu_loginButtons {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;

        span {
            width: 50%;
            text-align: center;
            border: 0;

            &:first-child {
                padding-right: 5px;
                border-right: 2px solid white;
            }
        }
    }
    //Mobile Side Menu end
}
// Arabic Style Start
.account-page-arabic {
    .mobile-push-menu .bm-cross-button {
        right: auto !important;
        left: 0px !important;
    }
    .board .menu ul li img {
        transform: scale(-1);
    }
}

// Arabic style End

@include respond(small-laptop) {
    .account-page {
        margin-top: 25px;
        .board {
            h3 {
                font-size: 20px;
            }
            .board__signOut {
                font-size: 13px;
            }
            .menu {
                ul {
                    li {
                        padding: 6px 5px;
                        img {
                            width: 15px;
                            height: 15px;
                        }
                        h6 {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}
@include respond(tablet) {
    .account-page {
        .board {
            .menu {
                display: none;
                ul {
                    flex-direction: row;
                    justify-content: space-around;
                    align-items: center;
                    padding: 10px;
                    margin: 0;
                    li {
                        flex-direction: column;
                        gap: 2px;
                    }
                }
            }
        }
        // burger menu start
        .mobile-push-menu {
            display: block;
            h6 {
                margin-top: 2px;
            }
        }
        // burger menu end
    }
}
// Arabic Style tablet start
.account-page-arabic {
    .mobile-push-menu {
        left: 30px;
        right: auto;
    }
}
// Arabic Style tablet end

@include respond(large-phone) {
    .account-page {
        // burger menu start
        .mobile-push-menu {
            ul {
                padding: 33px 0px;
                li {
                    padding: 7px 12px;
                    gap: 10px;
                    img {
                        width: 19px;
                        height: 19px;
                    }
                }
            }
        }
        // burger menu end
    }
}
@include respond(phone) {
    .account-page {
        // burger menu start
        .mobile-push-menu {
            h6 {
                font-size: 10px;
            }
        }
        // burger menu end
    }
}
