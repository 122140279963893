@import "../../../abstract/mixins";
.layalty {
    .account-page__title {
        h3 {
            font-size: 32px !important;
            font-family: $poppinsMedium-font;
            text-transform: capitalize !important;
            span {
                font-size: 30px;
                font-family: $PoppinsSemiBold;
                text-transform: capitalize !important;
            }
        }
        h4 {
            color: #707070 !important;
            font-size: 20px !important;
        }
        i {
            font-size: 26px;
            color: #222222;
        }
    }
    &.wallet {
        .account-page__title {
            h4 {
                font-size: 16px !important;
            }
            span {
                color: #f3656b;
            }
        }
    }

    // layalty table start
    .layalty-table {
        overflow: auto;
        padding: 1px;

        table {
            width: 100%;
            box-shadow: 0 0 0px 1px #d1d1d1;

            tr {
                &:nth-child(even) {
                    background-color: #f2f2f2;
                }
            }
            td {
                padding: 25px;
                height: 84px;
                font-size: 16px;
                white-space: nowrap;

                &:last-child {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    column-gap: 5px;
                    font-size: 20px;
                    font-family: $poppinsMedium-font;
                    color: $primary-background-color;
                    img {
                        width: 20px;
                        height: 20px;
                    }
                }
                span {
                    font-size: 16px;
                    font-family: $PoppinsSemiBold;
                }
            }

            .layalty-table__item {
                border-bottom: 1px solid #d1d1d1;
                td {
                    &:first-child {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    // layalty table end

    // rewards table start
    .rewards-title {
        font-size: 30px;
        font-family: $poppinsMedium-font;
        margin-bottom: 10px;
    }
    .rewards-table {
        padding: 25px !important;
        background-color: #f2f2f2;
        overflow: auto;
        border-radius: 10px;
        padding: 1px;

        table {
            width: 100%;

            td {
                height: 84px;
                font-size: 16px;

                &:last-child {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    column-gap: 5px;
                    font-size: 20px;
                    font-family: $poppinsMedium-font;
                    color: $primary-background-color;
                    img {
                        width: 20px;
                        height: 20px;
                    }
                }
                span {
                    font-size: 16px;
                    font-family: $PoppinsSemiBold;
                }
                .submit-btn {
                    border-radius: 5px;
                    font-size: 16px;
                    font-family: $poppinsRegular-font;
                    text-transform: capitalize !important;
                    width: 100px;
                    height: 40px;
                }
            }

            .rewards-table__item {
                border-bottom: 1px solid #d1d1d1;
                &:last-child {
                    border: 0;
                }
                td {
                    white-space: nowrap;
                    &:first-child {
                        span {
                            font-size: 20px;
                        }
                    }
                    .FREEDEL {
                        font-family: $poppinsRegular-font;
                    }
                }
            }
        }
    }
    // rewards table end
}
// Arabic Style Start
.addresses-arabic {
    .button-container {
        justify-content: left;
    }

    .addresses__block .checkmark {
        right: auto;
        left: 13px;
    }
}

// Arabic style End
@include respond(laptop) {
    .layalty .account-page__title h3 {
        font-size: 26px !important;
        span {
            font-size: 26px !important;
        }
    }

    .layalty .account-page__title h4 {
        font-size: 16px !important;
        i {
            font-size: 20px;
        }
    }

    .layalty .layalty-table table td {
        padding: 20px;
        height: 60px;
    }
    .layalty .layalty-table table td:last-child {
        font-size: 18px;
        img {
            width: 18px;
            height: 18px;
        }
    }
    .layalty .rewards-table table td .submit-btn,
    .layalty .rewards-table table .rewards-table__item td {
        font-size: 14px;
    }
    .layalty .rewards-table table .rewards-table__item td:first-child span {
        font-size: 17px;
    }
    .layalty .rewards-table table td span {
        font-size: 15px;
    }
}

@include respond(small-laptop) {
    .layalty .account-page__title h3 {
        font-size: 23px !important;
        span {
            font-size: 23px !important;
        }
    }

    .layalty .account-page__title h4 {
        font-size: 14px !important;
        i {
            font-size: 18px;
        }
    }

    .layalty .layalty-table table td {
        padding: 20px;
        height: 60px;
        font-size: 14px;
    }
    .layalty .layalty-table table td:last-child {
        font-size: 16px;
        img {
            width: 16px;
            height: 16px;
        }
    }
    .layalty .layalty-table table td span {
        font-size: 14px;
    }
    .layalty .rewards-table table td {
        padding: 10px;
    }
    .layalty .rewards-table table td .submit-btn {
        width: 80px;
        height: 30px;
        font-size: 13px;
    }
    .layalty .rewards-table table .rewards-table__item td {
        font-size: 13px;
    }
    .layalty .rewards-table table .rewards-table__item td:first-child span {
        font-size: 15px;
    }
    .layalty .rewards-table table td span {
        font-size: 14px;
    }

    .layalty {
        &.wallet {
            .account-page__title {
                h4 {
                    font-size: 14px !important;
                }
            }
        }
    }
}

@include respond(large-phone) {
    .layalty .account-page__title h3 {
        font-size: 20px !important;
        span {
            font-size: 20px !important;
        }
    }

    .layalty .account-page__title h4 {
        font-size: 13px !important;
        i {
            font-size: 16px;
        }
    }
}
@include respond(large-phone) {
    .layalty .account-page__title h3 {
        font-size: 18px !important;
        span {
            font-size: 16px !important;
        }
    }

    .layalty .account-page__title h4 {
        font-size: 12px !important;
        i {
            font-size: 14px;
        }
    }
}
