.boxes {
    margin-top: 50px;
    .categories-block__header {
        span {
            color: #f3656b;
        }
        h3 {
            color: $primary-text-color;
        }
    }
    .react-multi-carousel-list {
        padding-bottom: 50px;
        margin-top: 45px;

        .react-multi-carousel-dot button {
            width: 14px;
            height: 14px;
            background-color: #000;
            border: 0;
        }
        .react-multi-carousel-dot--active button {
            background-color: #fff;
            border: 1px solid #000000;
            width: 15px;
            height: 15px;
        }
    }
    &.boxes-arabic {
        .categories-block__header span {
            letter-spacing: 0;
        }
    }
}

@include respond(tablet) {
    .boxes {
        .carousel-item-margin-25-px {
            padding: 0 10px !important;
        }
        .categories-block__header {
            span {
                // font-size: 14px;
            }
            h3 {
                font-size: 22px;
            }
        }
    }
}

@include respond(large-phone) {
    .boxes {
        .carousel-item-margin-25-px {
            padding: 0 10px !important;
        }
        .categories-block__header {
            span {
                font-size: 12px;
            }
            h3 {
                font-size: 18px;
            }
        }
    }
}
