@import "../../../abstract/mixins";
.profile {
    // general information Start section
    .general-info {
        // border-bottom: 1px solid silver;
        padding-bottom: 20px;
        margin-bottom: 20px;
        .general-info__title {
            font-size: 22px;
            font-family: $poppinsMedium-font;
            text-transform: uppercase;
            margin-bottom: 23px;
            color: $primary-text-color;
        }
    }
    // general information End section

    .profile-info__border-bottom {
        border-bottom: 1px solid #d1d1d1;
    }
    // Security  Start section
    .Security {
        .Security__title {
            font-size: 18px;
            font-family: $poppinsBold-font;
            text-transform: capitalize;
            margin-bottom: 23px;
        }
    }
    // Security  End section
    // button start
    .button-container {
        display: flex;
        justify-content: flex-start;
        column-gap: 20px;
        .submit-button2 {
            text-transform: uppercase;
            border-radius: 5px;
            font-size: 18px;
            font-family: $poppinsMedium-font;
        }
    }
    .profile-form__buttons {
        button {
            border-radius: 0 !important;
            &:last-child {
                background-color: #d1d1d1;
                color: $primary-text-color;
                border-color: transparent;
                &:hover {
                    background-color: $primary-background-color;
                    color: #fff;
                }
            }
        }
    }

    // button end

    .phone-number__zipcode-select {
        padding: 0 !important;
        // padding-left: 35px !important;
    }
}
.accountInformation {
    .general-info {
        background-color: #f2f2f2;
        padding: 30px 50px;
        border-radius: 10px;
    }
}
.profile-info__box {
    span {
        font-size: 16px;
        text-transform: capitalize;
    }
    p {
        text-transform: capitalize;
        font-size: 20px;
        overflow-wrap: anywhere;
    }
}
// Arabic language Style Start
.profile-arabic {
    .button-container {
        // justify-content: left;
    }
    .phone-number__zipcode {
        .phone-number__zipcode-select {
            padding-left: 0px !important;
            padding-right: 35px !important;
            border-left: none !important;
            border-right: 1px solid #707070 !important ;
        }
    }
    .phone-number .text-input-block {
        border-left: 1px solid #707070 !important;
        border-right: none !important;
    }
}
// Arabic language Style End

@include respond(small-laptop) {
    .profile {
        h3 {
            font-size: 20px;
        }
        h4 {
            font-size: 13px;
        }
        .profile-info__box p {
            font-size: 16px;
        }
    }
}

@include respond(large-phone) {
    .profile {
        .general-info .general-info__title {
            font-size: 18px;
        }

        .profile-form__buttons {
            .submit-button2 {
                font-size: 15px;
                min-width: 150px;
            }
        }
    }
}

@include respond(phone) {
    .profile {
        .profile-info__box p {
            font-size: 14px;
        }

        .profile-form__buttons {
            column-gap: 10px;
            // flex-direction: column;
            .submit-button2 {
                padding: 8px;
                font-size: 13px;
                min-width: 100px;
            }
        }
    }
    .accountInformation .general-info {
        padding: 20px 30px;
    }
}
