@import "../../../abstract/mixins";

.faq {
    .faq-contents {
        .accordion-item {
            padding-top: 30px;

            border-bottom: 1px solid #d1d1d1;
            .accordion-button {
                font-size: 22px;
                font-family: $PoppinsSemiBold;
                background-color: transparent !important;
                transition: ease-in-out 0.3s;
                box-shadow: none;
                padding: 0 20px;
                padding-bottom: 25px;
                align-items: flex-start;

                &:after {
                    // transform: rotate(-90deg);
                    background-image: url("../../../../../arq.svg") !important;
                    background-size: contain;
                    filter: none;
                }
            }
            .accordion-body {
                padding: 0 20px;
                padding-bottom: 40px;

                P {
                    margin-bottom: 0;
                }
            }
        }
        .activeFaqAccordion {
            .accordion-item {
                transition: ease-in-out 0.3s;
                background-color: #fff7f7;
                .accordion-button {
                    &:after {
                        transform: rotate(0deg);
                        background-image: url("../../../../../ar.svg") !important;
                    }
                }
            }
        }
    }
    &.faq-Arabic {
        .faq-contents {
            .accordion-item {
                .accordion-button {
                    text-align: right;

                    &:after {
                        margin-right: auto;
                        margin-left: 0;
                        background-image: url("../../../../../aqa.svg") !important;
                    }
                }
            }
        }
        .activeFaqAccordion {
            .accordion-item {
                transition: ease-in-out 0.3s;
                background-color: #fff7f7;
                .accordion-button {
                    &:after {
                        transform: rotate(0deg);
                        background-image: url("../../../../../ar.svg") !important;
                    }
                }
            }
        }
    }
}

@include respond(laptop) {
    .faq {
        .faq-contents {
            .accordion-item {
                padding-top: 30px;
                .accordion-button {
                    font-size: 18px;
                    padding: 0 20px;
                    padding-bottom: 25px;
                }
                .accordion-body {
                    padding: 0 20px;
                    padding-bottom: 40px;
                    P {
                        font-size: 13px;
                    }
                }
            }
        }
    }
}
@include respond(small-laptop) {
    .faq {
        .faq-contents {
            .accordion-item {
                padding-top: 30px;
                .accordion-button {
                    font-size: 17px;
                    padding: 0 15px;
                    padding-bottom: 25px;
                }
                .accordion-body {
                    padding: 0 15px;
                    padding-bottom: 40px;
                    P {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
@include respond(tablet) {
    .faq {
        .faq-contents {
            .accordion-item {
                padding-top: 30px;
                .accordion-button {
                    font-size: 16px;
                    padding: 0 15px;
                    padding-bottom: 25px;
                }
                .accordion-body {
                    padding: 0 15px;
                    padding-bottom: 40px;
                    P {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
@include respond(large-phone) {
    .faq {
        .faq-contents {
            .accordion-item {
                padding-top: 30px;
                .accordion-button {
                    font-size: 14px;
                    padding: 0 10px;
                    padding-bottom: 25px;
                }
                .accordion-body {
                    padding: 0 10px;
                    padding-bottom: 40px;
                    P {
                        font-size: 11px;
                    }
                }
            }
        }
    }
}
