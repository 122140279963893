.header {
  .header__top {
    // background-color: $secondary-background-color;
    background-color: #f7f7f7;
    font-family: $main-font;
    padding: 13px 30px;
    border-bottom: 2px solid #e9e9e9;
    .header__top__content {
      justify-content: flex-end;

      column-gap: 25px;
      // padding: 10px 0;
      ul {
        align-items: center;
        padding: 0;
        margin: 0;
        column-gap: 10px;
        li {
          font-size: 13px;

          column-gap: 5px;
          img {
            // width: 15px;
            // height: 15px;
            // object-fit: scale-down;

            width: 22px;
            object-fit: contain;
            height: 16px;
          }
        }

        .vertical-hr {
          height: 18px;
          width: 1px;
          background-color: #d1d1d1;
        }
        .login-buttons {
          a {
            transition: ease-in-out 0.1s;

            &:hover {
              font-weight: bold;
              transition: ease-in-out 0.1s;
            }
          }
        }
        .header-lang {
          span {
            font-family: $PoppinsLanguage-font;
            font-weight: bold;
            cursor: pointer;
          }
        }
        .header-currency {
          .dropdown-menu {
            min-width: 4rem !important;
            .dropdown-item {
              font-size: 13px;
            }
          }
          button {
            font-size: 13px;

            padding: 0;
            background-color: transparent !important;
            color: $primary-text-color;
          }
        }
      }
    }

    .header__language-dropDown {
      border: 0 !important;
      min-width: auto !important;
      column-gap: 0 !important;
    }
    .header__language-dropDown .dropdown-menu {
      min-width: 6rem;
      transform: translate(0px, 45px) !important;
    }

    .header__language-dropDown .dropdown-menu:after {
      left: calc(50% - 15px) !important;
    }

    .header__count {
      column-gap: 20px;
      a {
        display: block;
        position: relative;
        img {
          width: 27px;
        }

        span {
          position: absolute;
          background-color: $third-text-color;
          color: $primary-text-color;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 15px;
          height: 15px;
          border-radius: 50px;
          font-size: 10px;
          top: -5px;
          right: -8px;
          border: 1px solid $primary-text-color;
        }
      }
    }
    .header__userButton {
      button {
        padding: 0;
        .button-contents {
          column-gap: 3px;
          font-size: 13px;
          text-transform: capitalize;
          color: $primary-text-color !important;
          img {
            margin-bottom: 2px;
          }
        }
        &:hover {
          background-color: transparent !important;
          color: $primary-text-color !important;
          border: 0 !important;
        }
        &::after {
          display: none !important;
        }
      }

      .dropdown-menu {
        background-color: $primary-text-color !important;
        border-radius: 5px;
        transform: translate(0px, 50px) !important;
        border: 0;
        box-shadow: 0 0 0px 1px #e3e3e3d1;
        padding: 0;
        min-width: 8rem !important;
        &:after {
          content: "";
          position: absolute;
          top: -15px;
          left: calc(50% - 15px);
          clear: both;
          width: 0;
          height: 0;
          border-right: 15px solid transparent;
          border-bottom: 15px solid #000;
          border-left: 15px solid transparent;
        }
        .dropdown-item {
          color: #fff;
          display: flex;
          font-size: 13px;
          align-items: center;
          justify-content: center;
          column-gap: 10px;
          padding: 0.425rem 0.5rem;
          &:first-child {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          }
          &:last-child {
            border-top: 1px solid #ededed;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
          }
          &:hover {
            color: #fff;
            background-color: #242424 !important;
          }
          // img {
          //     width: 20px;
          //     object-fit: scale-down;
          //     height: 20px;
          // }
        }
      }
      // .dropdown-toggle {
      //     width: 100%;
      //     height: 100%;
      //     padding: 1px 10px;

      //     &::after {
      //         display: none;
      //     }
      //     &:hover {
      //         background-color: transparent;
      //     }

      // }
    }
  }
  .Dropdown_dropdown__3BzFl
    .Dropdown_button__3ifpF.Dropdown_button-secondary__131Mm {
    background-color: transparent !important;
    padding: 0 !important;
    font-weight: inherit !important;
    font-size: 16px;
    height: auto !important;

    div {
      column-gap: 5px;
    }
  }

  .Item_item__1S3xD {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    border-bottom: 1px solid #dadada;
  }
  .library-dropdown {
    background-color: transparent !important;
    padding: 0 !important;
    font-weight: inherit !important;
    font-size: 16px;
    height: auto !important;
    div {
      column-gap: 5px !important;
    }
  }
  .library-dropdown__item {
    font-size: 14px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    height: 40px !important;
    border-bottom: 1px solid #dadada !important;
    .library-dropdown__item__sub-item {
      top: 0px !important;
      left: 100% !important;
      right: auto !important;
      border-radius: 0 !important;
    }
  }
  .library-dropdown__menu {
    left: 0 !important;
    right: auto !important;
    .library-dropdown__item__sub-item {
      top: 0px !important;
      left: 100% !important;
      right: auto !important;
      border-radius: 0 !important;
      ul {
        li {
          font-size: 14px !important;
          display: flex !important;
          align-items: center !important;
          justify-content: space-between !important;
          height: 40px !important;
          border-bottom: 1px solid #dadada !important;
          &:hover {
            background-color: #f7f7f7 !important;
          }
        }
      }
    }
    & > ul {
      // .Item_item__1hdHM {
      //     font-size: 14px !important;
      //     display: flex !important;
      //     align-items: center !important;
      //     justify-content: space-between !important;
      //     height: 40px !important;
      //     border-bottom: 1px solid #dadada !important;
      //     &:hover {
      //         background-color: #f7f7f7 !important;
      //     }

      //     // .Submenu_left__1VaDf {
      //     //     top: 0px !important;
      //     //     left: 100% !important;
      //     //     right: auto !important;
      //     //     border-radius: 0 !important;
      //     // }
      // }
    }
  }
  .drop-down-library-child.active > a {
    border-color: $primary-background-color !important;
  }

  .header__bottom {
    padding: 14px 30px;
    .header-logo {
      img {
        width: 160px;
        height: 50px;
      }
    }
    .header__bottom-content {
      justify-content: space-between;

      ul {
        column-gap: 20px;
        margin: 0;
        padding: 0;

        .library-dropdown__item {
          // font-weight: bold;
          padding-bottom: 1px;
          color: $primary-background-color;

          a {
            border-bottom: 2px solid transparent;
            transition: ease-in-out 0.3s;
            text-transform: uppercase;
            font-family: $poppinsRegular-font;
            font-size: 16px;
            letter-spacing: 0.5px;
            transition: ease-in-out 0.3s;

            &:hover,
            &:active,
            &:focus {
              border-color: $primary-background-color;
            }
          }
          &.active > a {
            border-color: $primary-background-color;
          }
        }
      }
      // .Dropdown_dropdown__1iFeq {
      //     button {
      //         background-color: transparent !important;
      //         padding: 0 !important;
      //         font-weight: inherit !important;
      //         font-size: 16px;
      //         height: auto !important;

      //         div {
      //             column-gap: 5px;
      //         }
      //     }
      //     .Dropdown_menu__1bmYk {
      //         left: 0;
      //         right: auto;
      //         & > ul {
      //             .Item_item__1hdHM {
      //                 font-size: 14px;
      //                 display: flex;
      //                 align-items: center;
      //                 justify-content: space-between;
      //                 height: 40px;
      //                 border-bottom: 1px solid #dadada;
      //                 &:hover {
      //                     background-color: #f7f7f7;
      //                 }

      //                 .Submenu_left__1VaDf {
      //                     top: 0px;
      //                     left: 100%;
      //                     right: auto;
      //                     border-radius: 0;
      //                 }
      //             }
      //         }
      //     }
      // }
    }

    .header__cart-fav-search {
      column-gap: 20px;
      a {
        display: block;
        position: relative;
        img {
          cursor: pointer;
          width: 25px;
          height: 25px;
        }

        .cart-fav-span {
          position: absolute;
          background-color: $primary-background-color;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 15px;
          height: 15px;
          border-radius: 50px;
          font-size: 10px;
          bottom: -2px;
          right: -5px;
          padding-top: 2px;

          // border: 1px solid #fff;
        }
      }
      img {
        cursor: pointer;
      }
    }
    .header__search {
      display: none;
    }
    .header__searchDropdown {
      position: relative;

      .header__searchDropdown__content {
        padding: 5px;
        &:after {
          content: "";
          position: absolute;
          top: -15px;
          left: calc(50% - 15px);
          clear: both;
          width: 0;
          height: 0;
          border-right: 15px solid transparent;
          border-bottom: 15px solid #000;
          border-left: 15px solid transparent;
        }
        z-index: 9999;

        background-color: $primary-text-color !important;
        border-radius: 5px;
        transform: translate(-116px, 25px) !important;
        border: 0;
        box-shadow: 0 0 0px 1px #e3e3e3d1;
        min-width: 16rem !important;
        position: absolute;
        inset: 0px auto auto 0px;
      }
      .header__search {
        display: flex !important;
        .form-control {
          padding: 5px;
          height: 30px;
        }
        .input-group-text {
          background-color: #000;
          cursor: pointer;
          height: 30px;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }

      button {
        padding: 0;
        .button-contents {
          column-gap: 3px;
          font-size: 13px;
          text-transform: capitalize;
          color: $primary-text-color !important;
          img {
            margin-bottom: 2px;
          }
        }
        &:hover {
          background-color: transparent !important;
          color: $primary-text-color !important;
          border: 0 !important;
        }
        &::after {
          display: none !important;
        }
      }

      .dropdown-menu {
        background-color: $primary-text-color !important;
        border-radius: 5px;
        transform: translate(116px, 50px) !important;
        border: 0;
        box-shadow: 0 0 0px 1px #e3e3e3d1;
        padding: 0;
        min-width: 16rem !important;
        &:after {
          content: "";
          position: absolute;
          top: -15px;
          left: calc(50% - 15px);
          clear: both;
          width: 0;
          height: 0;
          border-right: 15px solid transparent;
          border-bottom: 15px solid #000;
          border-left: 15px solid transparent;
        }
        .dropdown-item {
          color: #fff;
          display: flex;
          font-size: 13px;
          align-items: center;
          justify-content: center;
          column-gap: 10px;
          padding: 0.425rem 0.5rem;
          border-radius: 5px;

          &:hover {
            color: #fff;
            background-color: #000 !important;
          }

          // img {
          //     width: 20px;
          //     object-fit: scale-down;
          //     height: 20px;
          // }
        }
      }
    }
    .bm-item-list {
      ul {
        display: block !important;
      }
    }
  }
  &:lang(ar) {
    .library-dropdown__menu {
      left: auto !important;
      right: 0 !important;
      & > ul {
        .Item_item__1hdHM {
          img {
            transform: scale(-1) !important;
          }

          .Submenu_left__1VaDf {
            left: auto !important;
            right: 100% !important;
          }
        }
      }

      .library-dropdown__item__sub-item {
        left: auto !important;
        right: 100% !important;
      }
    }

    // .header__bottom .header__bottom-content .Dropdown_dropdown__1iFeq .Dropdown_menu__1bmYk {
    //     left: auto;
    //     right: 0;
    // }
    // .header__bottom .header__bottom-content .Dropdown_dropdown__1iFeq .Dropdown_menu__1bmYk > ul .Item_item__1hdHM .Submenu_left__1VaDf {
    //     left: auto;
    //     right: 100%;
    // }
    // .header__bottom .header__bottom-content .Dropdown_dropdown__1iFeq .Dropdown_menu__1bmYk > ul .Item_item__1hdHM img {
    //     transform: scale(-1);
    // }
    .header__bottom .header__searchDropdown .header__search .form-control {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
    .header__bottom .header__search .input-group-text {
      border-top-left-radius: 0.25rem !important;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0.25rem !important;
      border-bottom-right-radius: 0;
    }
  }
}

@include respond(laptop) {
}





@include respond(laptop) {
  .header .header__bottom .header__bottom-content ul {
    li a {
      font-size: 14px;
    }
  }
  .header
    .header__bottom
    .header__bottom-content
    .Dropdown_dropdown__1iFeq
    button {
    font-size: 14px;
  }
  .header
    .header__top
    .header__top__content
    ul
    .header-currency
    .dropdown-menu
    .dropdown-item,
  .header .header__top .header__top__content ul .header-currency button,
  .header .header__top .header__top__content ul li {
    font-size: 14px;
  }
}

@include respond(small-laptop) {
  .header .header__bottom .header-logo img {
    width: 105px;
    height: auto;
  }
  .header .header__bottom .header__bottom-content ul {
    column-gap: 10px;
    li a {
      font-size: 12px;
    }
  }
  .header
    .header__bottom
    .header__bottom-content
    .Dropdown_dropdown__1iFeq
    button {
    font-size: 12px;
  }
  .header .header__bottom .header__cart-fav-search a img {
    width: 22px;
    height: 22px;
  }
  .header
    .header__top
    .header__top__content
    ul
    .header-currency
    .dropdown-menu
    .dropdown-item,
  .header .header__top .header__top__content ul .header-currency button,
  .header .header__top .header__top__content ul li {
    font-size: 12px;
  }
}


















@include respond(tablet) {
  .header .header__bottom .header__cart-fav-search,
  .header .header__bottom .header__bottom-content ul {
    display: none;
  }
  .header .header__bottom .header__search {
    display: flex;
    max-width: 55%;
    .input-group-text {
      background-color: $primary-background-color;
      border: 1px solid $primary-background-color;
    }
  }
}

@include respond(large-phone) {
  .header
    .header__top
    .header__top__content
    ul
    .header-currency
    .dropdown-menu
    .dropdown-item,
  .header .header__top .header__top__content ul .header-currency button,
  .header .header__top .header__top__content ul li {
    font-size: 11px;
  }
  .header .header__top,
  .header .header__bottom {
    padding: 13px 15px;
  }
  .header .header__bottom .header-logo img {
    width: 90px;
  }
}

@include respond(phone) {
}
