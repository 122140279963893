.settings-info-field {
    span {
        font-size: 14px;
        color: black;
        &:nth-child(1) {
            width: 199px;
        }
        &:nth-child(2) {
            width: 54px;
            // font-weight: 600;
        }
    }
    .selectBlock {
        margin-bottom: 0 !important;
        width: 200px;
        .css-1pahdxg-control,
        .css-1s2u09g-control {
            border-radius: 5px !important;
            height: 45px;
        }
    }
    .switch {
        position: relative;
        display: inline-block;
        width: 75px !important;
        height: 33px;
        span {
            justify-content: flex-end;
        }
        .switchActive {
            justify-content: flex-start;
        }
    }

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        width: 75px !important;
        position: absolute;
        display: flex;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: transparent;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border: 2px solid #ededed;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 25px;
        width: 27px;
        left: 2px;
        background-color: #dee2e6;
        transition: 0.4s;
    }

    input:checked + .slider {
        background-color: $primary-text-color;
    }

    // input:focus + .slider {
    //     box-shadow: 0 0 1px $primary-text-color;
    // }

    input:checked + .slider:before {
        -webkit-transform: translateX(40px);
        -ms-transform: translateX(30px);
        transform: translateX(40px);
        color: #fff;
        background: #d1d1d1;
    }

    /* Rounded sliders */

    .slider.round {
        border-radius: 34px;
        color: #fff;
        text-align: left !important;
        background-color: black;
    }

    .slider.round:before {
        border-radius: 50%;
        background-color: #707070;
    }
    .SwitchButt {
        display: flex;
        justify-content: space-between;
        h3 {
            font-size: 16px;
            font-family: $poppinsBold-font;
            color: #575756;
        }
    }
}
.profile {
    .m-end {
        margin-left: auto;
        // margin-left: 0;
    }
}
.profile-arabic {
    .switch {
        span {
            justify-content: flex-start;
        }
        .switchActive {
            justify-content: flex-end;
        }
    }
    .m-end {
        margin-right: auto;
        margin-left: 0;
    }
}

@include respond(large-phone) {
    .settings-info-field {
        flex-direction: column;
        align-items: flex-start !important;
        span:nth-child(2) {
            display: none;
        }
        .SwitchButt {
            span {
                display: flex;
            }
        }
    }
}
