.page-title {
    background: url(../../../../InnerBanner.png);
    height: 102px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    max-width: 100% !important;
    margin-bottom: 50px;
    div {
        p {
            color: #fff;
        }
        .title {
            font-size: 30px;
            font-family: $poppinsBold-font;
            text-transform: uppercase;
        }
        .bread {
            a {
                color: #fff !important;
                text-decoration: none !important;
                font-size: 13px !important;
            }
            .breadcrumb {
                justify-content: center !important;
            }
            .active {
                background-color: transparent !important;
                color: #fff !important;
                text-decoration: none;
                border-bottom: none !important;
            }
        }
    }

    &.page-title-arabic {
        .breadcrumb-item + .breadcrumb-item::before {
            float: right !important;
            padding-left: 0.5rem !important;
            padding-right: 0.5rem !important;
        }
    }
}

@include respond(laptop) {
    .page-title {
        div {
            .title {
                font-size: 25px;
            }
        }
    }
}

@include respond(tablet) {
    .page-title {
        div {
            .title {
                font-size: 22px;
            }
        }
    }
}

@include respond(large-phone) {
    .page-title {
        div {
            .title {
                font-size: 19px !important;
            }
        }
    }
}
