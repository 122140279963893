.location-modal {
    .modal-title {
        margin-bottom: 20px;
        justify-content: space-between;
    }
    .modal-content {
        background-color: #fbfbfb;
        padding: 20px;
    }
    h5 {
        color: $primary-text-color;
        font-weight: bold;
        margin-bottom: 0;
    }
    .submit-button2 {
        margin-top: 30px;
        border-radius: 5px;
        margin-left: auto;
    }
    textarea,
    .form-control {
        font-weight: 100 !important;
    }

    select {
        background-color: #f1f1f1;
        color: #50a433 !important;
        font-weight: bold;
        font-size: 18px;
        border: 0;
        border-radius: 10px;
        border: 1px solid #ccc;
        border-color: #ccc !important;
    }
    .margin-top-0 {
        margin-top: 0;
    }
}

// Arabic style start
.location-modal-arabic {
    .submit-button2 {
        margin-left: 0px;
        margin-right: auto;
    }
}
// Arabic style End
