.invoice-block-container {
    display: flex;
    max-width: 994px;
    background-color: #f2f2f2;
    padding: 38px;
    border-radius: 20px;
    .invoice-block-content {
        .invoice-block-header {
            font-size: 22px;
            text-transform: uppercase;
            font-family: $PoppinsSemiBold;
            text-align: start !important;
        }
        .cart-summary__section__body__summary-field {
            .product-container {
                .summary-product {
                    border-bottom: 1px solid #d1d1d1;
                    padding-bottom: 21px;
                    .summary-product__info {
                        .summary-product__info_title {
                            p {
                                text-align: start;
                            }
                        }
                    }
                }
            }
        }

        .invoice-btns-container {
            .invoice-btn {
                display: inline-block;
                margin: 0 35px;
                margin-top: 46px;
                width: 143px;
                padding: 15px 27px;
                font-size: 18px;
                font-family: $poppinsMedium-font;
                border-radius: 5px;
                transition: 0.3s;
            }
            .black-one {
                background-color: #000;
                color: #fff;
                &:hover {
                    background-color: #d1d1d1;
                    color: #000;
                    transition: 0.3s;
                }
            }
            .gray-one {
                background-color: #d1d1d1;
                color: #000;
                &:hover {
                    background-color: #000;
                    color: #fff;
                    transition: 0.3s;
                }
            }
        }
    }
    .summary-product {
        .summary-product__img {
            height: 100%;
            display: flex;
            align-items: center;
            img {
                width: 143px;
                height: 136px;
                object-fit: cover;
            }
        }
        .summary-product__info {
            .summary-product__info__title {
                p {
                    text-align: start;
                    font-size: 20px;
                }
            }
            .summary-product__info__options {
                height: auto;
                p {
                    text-align: start;
                    font-size: 16px;
                }
            }
        }
        .summary-product__info__qty-price {
            align-items: stretch;
            .price {
                font-size: 20px;
                font-family: $PoppinsSemiBold;
            }
            .duration {
                text-align: start;
                span {
                    max-width: 100%;
                    font-size: 16px;
                }
            }
        }
    }
    .invoice-info__container {
        .invoice-info__block {
            width: 346px;
            .invoice-block__body {
                font-size: 15px;
            }
        }
    }
}

@media (max-width: 768px) {
    .invoice-block-container {
        padding: 20px !important;
    }
    .invoice-info__container {
        flex-direction: column !important;
        .invoice-info__block {
            margin-top: 10px;
            width: 100% !important;
        }
    }
    .summary-product {
        margin-top: 21px;
        .summary-product__img {
            width: 100% !important;
            margin-bottom: 21px;
            img {
                width: 100% !important;
                object-fit: contain !important;
            }
        }
        .summary-product__info__qty-price {
            margin-top: 10px !important;
            .price {
                font-size: 15px !important;
            }
            .duration {
                span {
                    font-size: 13px !important;
                }
            }
        }
    }
}

@media (max-width: 484px) {
    .invoice-btns-container {
        .invoice-btn {
            width: 100% !important;
            margin: 0 !important;
            margin-top: 10px !important;
        }
    }
}
