.first-time-modal {
    .modal-body {
        padding: 0 !important;
        // height: 470px !important;
        margin: 0 !important;
    }
    .modal-content {
        height: 470px;
    }
    .login-modal__header {
        padding: 0;
        height: 0;
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 1 !important;
    }
    .login-modal__body {
        .login-modal__body__header {
            font-size: 35px;
        }
        .login-modal__body__right {
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .notify-btn {
        margin-top: 10px !important;
    }
    .modal-header .btn-close {
        padding: 0.25rem;
        background-color: #fff;
    }
}
// 358px
@include respond(tablet) {
    .first-time-modal {
        .modal-body {
            .login-modal__body__left {
                .login-form {
                    padding: 28px !important;
                    .login-modal__body__header {
                        font-size: 25px;
                    }
                }
            }
        }
        .modal-content {
            height: 358px !important;
        }
    }
}

@media (max-width: 800px) {
    .first-time-modal {
        .modal-content {
            height: 430px;
        }
    }
    .first-time-modal .login-modal__body .login-modal__body__header {
        font-size: 32px;
    }
}

@media (max-width: 1000px) {
    .first-time-modal {
        .modal-content {
            height: 430px;
        }
    }
    .first-time-modal .login-modal__body .login-modal__body__header {
        font-size: 32px;
    }
}

@media (max-width: 576px) {
    .first-time-modal {
        .modal-body {
            .login-modal__body__right {
                padding: 0 !important;
            }
            .login-modal__body__left {
                .login-form {
                    padding: 20px !important;
                    .login-modal__body__header {
                        font-size: 20px !important;
                        margin-top: 20px !important;
                    }
                    .submit-btn {
                        margin-top: 1rem !important;
                    }
                }
            }
        }
    }
}

@include respond(mobile) {
    .first-time-modal .login-modal__body .login-modal__body__header {
        font-size: 18px !important;
    }
}
