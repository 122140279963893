.phone-input {
    .phone-input__content {
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #e0e0e0;
        border-radius: 7px !important;
        height: 41px !important;
        .phone-input__content__country--code {
            padding: 10px;
            border-right: 1px solid #e0e0e0;
        }
        .phone-input__content__container {
            background-color: transparent;
            border: none !important;
            .input-group {
                background-color: transparent;
            }
        }
    }
}

.verify-btn {
    background-color: $primary-text-color;
    color: #fff;
    padding: 12px 20px !important;  
}

.phone-input-arabic {
    .phone-input__content {
        .phone-input__content__country--code {
            padding: 10px;
            border-right: none;
            border-left: 1px solid #e0e0e0;
        }
    }
}
