.just-for-you {
    background-image: url("../../../../Rectangle\ 2\ copy.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 470px;
    margin-top: 50px;
    .just-for-you__content {
        justify-content: space-between;
        // justify-content: flex-end;
        position: relative;
        .just-for-you__content__details {
            border: 2px solid #fff;
            padding: 30px 20px;
            width: 50%;
            color: #fff;
            p {
                font-size: 24px;
                max-width: 60%;
            }
            h2 {
                font-family: $PoppinsSemiBold;
                margin-bottom: 25px;
                font-size: 60px;
            }
            a {
                text-decoration: underline !important;
                font-family: $PoppinsSemiBold;
                font-size: 18px;
                display: block;
                text-align: right;
            }
        }
        .just-for-you__content__img {
            position: absolute;
            right: 0;
            bottom: 0;
            top: auto;
            max-width: 465px;
            max-height: 500px;
        }
    }
    &.just-for-you-arabic {
        .just-for-you__content__img {
            right: auto;
            left: 0;
        }
        .just-for-you__content .just-for-you__content__details h2,
        .just-for-you__content .just-for-you__content__details .h2,
        .just-for-you__content .just-for-you__content__details a {
            font-family: inherit;
        }
    }
}

@include respond(laptop) {
    .just-for-you .just-for-you__content {
        .just-for-you__content__details {
            p {
                font-size: 21px;
                max-width: 70%;
            }
            h2 {
                margin-bottom: 25px;
                font-size: 55px;
            }
            a {
                font-size: 17px;
            }
        }
    }
}
@include respond(small-laptop) {
    .just-for-you {
        height: 350px;

        .just-for-you__content {
            .just-for-you__content__details {
                p {
                    font-size: 20px;
                    max-width: 80%;
                }
                h2 {
                    margin-bottom: 25px;
                    font-size: 42px;
                }
                a {
                    font-size: 15px;
                }
            }
            .just-for-you__content__img {
                max-width: 350px;
                max-height: 377px;
            }
        }
    }
}
@include respond(tablet) {
    .just-for-you {
        height: 615px;

        .just-for-you__content {
            flex-direction: column;
            // justify-content: flex-end;
            .just-for-you__content__details {
                width: 100%;
                margin-top: 357px;
            }
            .just-for-you__content__img {
                max-width: 350px;
                height: 377px;
                bottom: auto;
                top: -20px;
                left: calc(50% - 180px);
            }
        }
        &.just-for-you-arabic {
            .just-for-you__content {
                .just-for-you__content__img {
                    right: calc(50% - 180px);
                    left: auto;
                }
            }
        }
    }
}
@include respond(phone) {
    .just-for-you {
        height: 590px;
        .just-for-you__content {
            flex-direction: column;
            // justify-content: flex-end;
            .just-for-you__content__details {
                width: 100%;
                margin-top: 357px;

                p {
                    font-size: 17px;
                    max-width: 100%;
                }
                h2 {
                    margin-bottom: 20px;
                    font-size: 35px;
                }
                a {
                    font-size: 14px;
                }
            }
            .just-for-you__content__img {
                max-width: 320px;
                height: 377px;
                bottom: auto;
                top: -20px;
                left: calc(50% - 160px);
            }
        }
        &.just-for-you-arabic {
            .just-for-you__content {
                .just-for-you__content__img {
                    right: calc(50% - 160px);
                }
            }
        }
    }
}
