.reusable-label {
    display: flex;
    align-items: center;
    column-gap: 5px;
    justify-content: space-between;

    .requiredField {
        color: $third-text-color;
    }
    span {
        letter-spacing: 0.5px;
        margin-bottom: 10px;
        display: block;
        font-size: 16px;
    }

    img {
        margin: 0 10px;
    }
    .addNew_label {
        padding: 3px 6px;
        // width: 70px;
        color: white;
        background-color: #259e25;
        text-align: center;
        border-radius: 20px;
        font-weight: 100;
        font-size: 13px;
    }

    .check-isChild {
        display: flex;
        align-items: center;
        column-gap: 5px;
        input {
            width: 15px;
            cursor: pointer;
        }
    }
}

@include respond(large-phone) {
    .reusable-label span {
        margin-bottom: 0;
    }
}
