@font-face {
    font-family: PoppinsRegular;
    src: url(../../fonts/newFont/Poppins-Regular.ttf);
    font-display: swap;
}

@font-face {
    font-family: PoppinsBold;
    src: url(../../fonts/newFont/Poppins-Bold.ttf);
    font-display: swap;
}

@font-face {
    font-family: PoppinsSemiBold;
    src: url(../../fonts/newFont/Poppins-SemiBold.ttf);
    font-display: swap;
}

@font-face {
    font-family: PoppinsLanguage;
    src: url(../../fonts/newFont/Hacen-Saudi-Arabia.ttf);
    font-display: swap;
}

@font-face {
    font-family: PoppinsLight;
    src: url(../../fonts/newFont/Poppins-Light.ttf);
    font-display: swap;
}
@font-face {
    font-family: PoppinsExtraLight;
    src: url(../../fonts/newFont/Poppins-ExtraLight.ttf);
    font-display: swap;
}

//-*----------  START FONTS BASE  ----------*/
$poppinsRegular-font: "PoppinsRegular", sans-serif;
$poppinsBold-font: "PoppinsBold", sans-serif;
$PoppinsSemiBold: "PoppinsSemiBold";
$poppinsMedium-font: "PoppinsMedium", sans-serif;
$poppinsLight-font: "PoppinsLight", sans-serif;
$poppinsExtraLight-font: "PoppinsExtraLight", sans-serif;
$PoppinsLanguage-font: "PoppinsLanguage", sans-serif;

// $helveticaArabic-font: "HelveticaArabic", sans-serif;
// $helveticaArabicBold-font: "HelveticaArabicBold", sans-serif;
$base-fonts: -apple-system, BlinkMacSystemFont, "Segoe UI", Poppins, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

//-*----------  END FONTS BASE  ----------*/

//%-*=====  End of GLOBAL STYLE SECTION  ======*-//

$main-font: $poppinsRegular-font, $base-fonts;

$primary-text-color: #000000;
$white-color: #fff;
$dark-white-color: #e9e9e9;
$secondary-text-color: #333333;
$third-text-color: #eeeeee;
$forth-text-color: #ffa700;
$gray-color: #b8b8b8;
$dark_title: #515151;
$pink-bg: #b31983;
// $primary-text-color: #752157;
// $secondary-color: #878787;
$primary-background-color: #000000;

$gray_light_dark: #6d6f72;
$gray_light: #f8f8f8;
$secondary-background-color: #752157;
$light_base_color: #89336e;
$default-font-size: 14px;
