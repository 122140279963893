@import "../../../abstract/mixins";

.subscriptions {
    .subscriptions__note {
        margin-bottom: 70px;
        h4 {
            font-size: 22px;
            text-transform: uppercase;
        }
        p {
            font-size: 17px;
        }
    }
    .subscriptions__cards {
        .card {
            padding: 10px;
            border-radius: 0;
            border: 1px solid #707070;
            height: 100%;
            .card-img-top {
                width: 100%;
                border-radius: 0;
                height: 295px;
                object-fit: cover;
            }
            .card-body {
                padding: 20px 0 0 0;
                .card-title {
                    font-size: 20px;
                    font-family: $PoppinsSemiBold;
                    text-transform: capitalize;
                }
                .card-text {
                    p {
                        font-size: 18px;
                        span {
                            color: #f3656b;
                            margin: 0 10px;
                            font-family: $poppinsMedium-font;
                        }
                    }
                    ul {
                        padding: 0;
                        margin: 0;
                        li {
                            column-gap: 10px;
                            font-size: 14px;
                            margin-bottom: 20px;
                            display: flex;
                            align-items: flex-start;
                            text-transform: capitalize;
                            // align-items: center;
                            img {
                                margin-top: 2px;
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }
                }
                .submit-btn {
                    height: 42px;
                    font-size: 18px;
                }
            }
            .card-footer {
                padding: 0;
                background-color: transparent;
                border: 0;
                .submit-btn {
                    height: 42px;
                }
            }
        }
    }
    .subscription__questions {
        .checkbox-container {
            margin-top: 45px;
            .checkmark {
                border-radius: 50%;
                padding: 2px;
                border: 1px solid #707070;
                background-color: #fff;
                &::after {
                    border: 0;
                }
            }
        }
    }
    &.subscription-arabic {
        .subscriptions__cards .card .card-body .card-title {
            font-family: inherit;
        }
    }
}
.subscription-invoice-btns {
    button {
        padding: 5px 27px !important;
    }
}
@include respond(laptop) {
    .subscriptions .subscriptions__cards .card .card-img-top {
        height: 220px;
    }
}

@include respond(small-laptop) {
    .subscriptions .subscriptions__cards .card {
        .card-img-top {
            height: 180px;
        }

        .card-body {
            .card-title {
                font-size: 17px;
            }
            .card-text {
                p {
                    font-size: 14px;
                }
                ul {
                    li {
                        font-size: 13px;
                        column-gap: 7px;
                    }
                }
            }
        }
    }
}

@include respond(phone) {
    .subscriptions .subscriptions__note {
        margin-bottom: 40px;

        h4 {
            font-size: 19px;
        }
        p {
            font-size: 14px;
        }
    }
}
