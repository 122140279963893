.product {
  max-width: 100%;
  max-height: 404px;
  background-color: #fff !important;
  cursor: pointer;
  box-shadow: 0 0 0px 1px #c0c0c04f;

  .product-label-soldOut {
    background: #000 !important;
    color: #fff !important;
  }

  .product__img-container {
    position: relative;
    .product__img-container__img img {
      width: 100%;
      max-height: 272px;
      height: 272px;
      object-fit: contain;
      @media (max-width: 567px) {
        max-height: 200px;
        height: 200px;
      }
    }
    .fav-container {
      position: absolute;
      top: 0;
      width: 100%;
      text-align: end;
      padding: 13px !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .product-label {
        padding: 2px 5px;
        text-transform: capitalize;
      }
      button {
        background-color: transparent;
        i {
          font-size: 28px;
          color: #fff;
          background-color: #fff2;
          border-radius: 50%;
          width: 40px;
          height: 40px;
        }
      }
    }
    .product__img-container__inhover-effect {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: auto;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      display: flex;

      .add-to-cart-container {
        // display: none;
        opacity: 0;
        transition: ease-in-out 0.3s;

        padding-bottom: 20px !important;
        button {
          background-color: $dark-white-color;
          width: 160px;
          height: 42px;
          border-radius: 20px !important;
          text-transform: uppercase;
          font-weight: bold;
          transition: ease-in-out 0.5s;
          // &:hover {
          //     background-color: $primary-background-color;
          //     color: #fff !important;
          //     transition: ease-in-out 0.5s;
          // }
        }
        .add-to-cart-container-active {
          background-color: $primary-background-color;
          color: #fff !important;
        }
        .sold-out-btn {
          background-color: $gray_light_dark;
        }
      }
    }
  }
  .product__body {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px 10px;
    height: 89px !important;
    .product__body__title {
      color: $primary-text-color;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 5px !important;
      // margin-bottom: 0px !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      // height: 32px;
    }
    .product__body__price {
      color: $primary-text-color;

      display: flex;
      font-size: 15px;
      font-weight: 500;
      column-gap: 5px;
      justify-content: center;
      @media (max-width: 576px) {
        font-size: 12px;
      }
    }
    .product__body__price__old {
      color: $primary-text-color;

      display: flex;
      font-size: 12px;
      font-weight: bold;
      column-gap: 5px;
      justify-content: center;
      @media (max-width: 576px) {
        font-size: 12px;
      }
    }
  }
  &:hover {
    .product__img-container {
      .product__img-container__inhover-effect {
        .add-to-cart-container {
          // display: block;
          opacity: 1;
          transition: ease-in-out 0.3s;
        }
      }
    }
  }
  &.product-arabic {
    .product__img-container
      .product__img-container__inhover-effect
      .fav-container {
      direction: rtl;
    }
  }
}

.dailyDeal .product {
  width: 100%;
  display: inline-block;
}

.rec-carousel-item {
  margin: 0px;
}
// Favourite Section Start (FavoriteProductBlock.jsx)
.fav-product {
  text-align: center;
  position: relative;
  i {
    position: absolute;
    top: -15px;
    right: -15px;
    font-size: 30px;
    background-color: #fff;
    color: $primary-text-color;
    height: 28px;
    cursor: pointer;
    border-radius: 5px;
  }
  .product__img {
    width: 100%;
    height: 320px;
    object-fit: fill;
    margin-bottom: 17px;
  }
  h5 {
    font-size: 18px;
    font-family: $PoppinsSemiBold;
    margin-bottom: 8px;
  }
  .product__price {
    font-family: $poppinsMedium-font;
    font-size: 18px;
    color: #f3656b;
    margin-bottom: 17px;
  }
  .submit-button2 {
    margin: auto;
    min-width: 125px;
    text-transform: uppercase;
    font-size: 14px;
    font-family: $PoppinsSemiBold;
  }
  &.fav-product-arabic {
    i {
      right: auto;
      left: -15px;
    }
  }
}
// Favourite Section end
// Media Query
@include respond(laptop) {
  .product .product__body .product__body__title {
    font-size: 16px;
  }
}
@include respond(small-laptop) {
}
@include respond(tablet) {
}
@include respond(large-phone) {
  .fav-product {
    margin: 0 15px;
  }
  .product .product__body .product__body__title {
    font-size: 13px;
  }
}

@include respond(phone) {
}

.product-image-Skeleton {
  height: 272px;
  @media (max-width: 567px) {
    height: 200px;
  }
  * {
    height: 100%;
  }
}
