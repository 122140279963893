.Ads-section {
    margin-bottom: 20px;
    a {
        display: block;
        margin-bottom: 15px;
        img {
            width: 100%;
            margin-top: 19px;
            object-fit: fill;
            height: 300px;
        }
    }
}

@include respond(laptop) {
    .Ads-section a img {
        height: 200px;
    }
}
@include respond(small-laptop) {
    .Ads-section a img {
        height: 150px;
    }
}
@include respond(large-phone) {
    .Ads-section a img {
        height: auto;
    }
}
