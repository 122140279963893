.Public-pages-data {
    padding: 50px 0;
    min-height: 50vh;
    h3 {
        text-align: center;
        color: $primary-text-color;
        font-size: 40px;
    }
    p {
        margin-top: 30px;
        text-align: left;
        font-size: 18px;
    }
}
// Arabic Style
.Public-pages-data-arabic {
    p {
        text-align: right;
    }
}
