@import "../../../abstract/mixins";

.track-your-oreder-tabs {
    .tab {
        background-color: #fff;
        color: #d1d1d1;
        font-size: 18px;
        font-weight: 600;
        padding: 10px;
    }
    .active-tab {
        color: #000;
        border-bottom: 4px solid #000;
    }
}

@include respond(large-phone) {
    .track-your-oreder-tabs {
        .tab {
            font-size: 15px;
        }
    }
}
@media (max-width: 425px) {
    .steps {
        .status-steps {
            ul {
                li {
                    div {
                        p {
                            top: 2px;
                            bottom: 0 !important;
                            left: 50px;
                        }
                    }
                }
            }
        }
    }

    .steps-arabic {
        .status-steps {
            ul {
                li {
                    div {
                        p {
                            top: 2px;
                            bottom: 0 !important;
                            left: 0 !important;
                            right: 50px !important;
                        }
                    }
                }
            }
        }
    }
}
@include respond(large-phone) {
    .track-your-oreder-tabs {
        .tab {
            font-size: 13px;
        }
    }
}
