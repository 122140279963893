@import "../../../abstract/mixins";

.gift-cards-details {
    .thank-you-block {
        padding: 30px;
        flex-direction: column;
        justify-content: center;
        background-color: #f2f2f2;
        height: 215px;
        border-radius: 20px;
        h3 {
            font-size: 39px;
            font-family: $poppinsMedium-font;
        }
        p {
            font-size: 20px;

            margin: 0;
            text-align: center;
        }
    }
    .gift-card-table {
        overflow: auto;

        table {
            width: 100%;

            .table-header {
                th {
                    text-align: center;
                    white-space: nowrap;
                    padding: 10px;
                    color: #00465f;
                    font-size: 18px;
                    font-family: $PoppinsSemiBold;
                }
            }
            .gift-card-table__item {
                background-color: #f5f5f5;
                td {
                    white-space: nowrap;

                    text-align: center;
                    padding: 15px;
                    font-size: 19px;
                    &:nth-child(2) {
                        font-family: $PoppinsSemiBold;
                        color: #00465f;
                    }

                    img {
                        width: 125px;
                        height: 75px;
                        border-radius: 15px;
                        margin-bottom: 10px;
                    }
                    p {
                        margin: 0;
                    }
                }
            }
        }
    }
    .gift-card-label-value {
        margin-bottom: 20px;
        p {
            font-size: 20px;
            font-family: $poppinsMedium-font;
            margin-bottom: 8px;
        }
        span {
            font-size: 15px;
        }
    }
    .gift-card__buttons {
        column-gap: 12px;
        button {
            font-size: 18px;
            font-family: $poppinsMedium-font;
            min-width: 140px;
            padding: 5px;
            &:first-child {
                border-radius: 5px;
            }
            &:last-child {
                background-color: #d1d1d1;
                border-color: #d1d1d1;
                color: $primary-background-color;
            }
        }
    }
    .gift-card__how-use {
        h2 {
            font-size: 30px;
            font-family: $poppinsMedium-font;
            color: #00465f;
            margin-bottom: 25px;
        }
        ul {
            padding: 0;
            margin: 0;
            li {
                color: #817e7e;
                font-size: 20px;
                margin-bottom: 10px;
                a {
                    color: #eb0404;
                }
            }
        }
    }
}

@include respond(laptop) {
}

@include respond(small-laptop) {
    .gift-cards-details {
        .thank-you-block {
            padding: 30px;
            height: 180px;
            h3 {
                font-size: 32px;
            }
            p {
                font-size: 17px;
            }
        }

        .gift-card-table {
            overflow: auto;

            table {
                width: 100%;

                .table-header {
                    th {
                        padding: 10px;
                        font-size: 15px;
                    }
                }
                .gift-card-table__item {
                    td {
                        padding: 10px;
                        font-size: 15px;

                        img {
                            width: 105px;
                            height: 65px;
                        }
                        p {
                            margin: 0;
                        }
                    }
                }
            }
        }

        .gift-card-label-value {
            p {
                font-size: 18px;
            }
            span {
                font-size: 14px;
            }
        }

        .gift-card__buttons {
            button {
                font-size: 16px;
                min-width: 120px;
            }
        }
        .gift-card__how-use {
            h2 {
                font-size: 26px;
                margin-bottom: 20px;
            }
            ul {
                li {
                    font-size: 18px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}

@include respond(tablet) {
    .gift-cards-details {
        .thank-you-block {
            padding: 25px;
            height: 130px;
            h3 {
                font-size: 28px;
            }
            p {
                font-size: 14px;
            }
        }

        .gift-card-table {
            table {
                .table-header {
                    th {
                        padding: 10px;
                        font-size: 14px;
                    }
                }
                .gift-card-table__item {
                    td {
                        padding: 10px;
                        font-size: 15px;
                        img {
                            width: 100px;
                            height: 55px;
                        }
                    }
                }
            }
        }
    }
}
@include respond(large-phone) {
    .gift-cards-details {
        .gift-card-label-value {
            margin-bottom: 20px;
            p {
                font-size: 16px;
                margin-bottom: 8px;
            }
            span {
                font-size: 13px;
            }
        }

        .gift-card__buttons {
            button {
                font-size: 14px;
                min-width: 120px;
            }
        }
        .gift-card__how-use {
            h2 {
                font-size: 20px;
                margin-bottom: 20px;
            }
            ul {
                li {
                    font-size: 14px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}

@include respond(phone) {
    .gift-cards-details {
        .thank-you-block {
            padding: 20px;
            height: 110px;
            h3 {
                font-size: 24px;
            }
            p {
                font-size: 13px;
            }
        }

        .gift-card-table {
            table {
                .table-header {
                    th {
                        padding: 10px;
                        font-size: 13px;
                    }
                }
                .gift-card-table__item {
                    td {
                        padding: 10px;
                        font-size: 14px;

                        img {
                            width: 90px;
                            height: 45px;
                        }
                    }
                }
            }
        }
    }
}
