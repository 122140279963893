.text-input-block {
    .input-group {
        background-color: white !important;
        // border-radius: 7px;
        padding: 4px 15px;
        width: 100%;
        // color: $primary-text-color !important;
        // margin-bottom: 15px !important;
        border: 1px solid $primary-text-color;
        height: auto;
        align-items: center;
        img {
            margin-top: 6px;
            width: 20px;
            object-fit: scale-down;
            height: 20px;
            cursor: pointer;
        }
        .form-control,
        textarea {
            resize: none;
            padding: 0;
            border: 0;
            background-color: white !important;
            display: flex;
            justify-content: center;
            align-items: center;
            // color: $secondary-text-color !important;
            color: #000 !important;
            font-size: 16px;
            font-family: $poppinsRegular-font;

            &::placeholder {
                font-size: 16px;
                color: #000;
                font-weight: normal;
            }
        }
        textarea {
            // min-height: 100px;
        }
    }

    .validate_Message {
        font-size: 15px;
        display: block;
        height: 0;
        margin: 3px 5px 0 5px;
        color: $primary-text-color;
    }
}

//
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
    background-color: transparent !important;
    box-shadow: #fff !important;
    -webkit-text-fill-color: $primary-text-color !important;
}

@include respond(laptop) {
    .text-input-block {
        .input-group {
            padding: 8px 15px;
            align-items: center;
            // .form-control {
            //     height: 18px;
            // }
            .form-control,
            textarea {
                font-size: 16px;
                // margin-bottom: -5px;

                &::placeholder {
                    font-size: 16px;
                }
            }
        }
    }
}
