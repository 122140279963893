@import "../../../abstract/mixins";

.thank-u-page {
    .thank-u-page-subscription-header {
        font-size: 32px;
        margin-bottom: 43px;
    }
}

@media (max-width: 768px) {
    .thank-u-page-subscription-header {
        font-size: 20px !important;
    }
}