@import "../../../abstract/mixins";
.orders {
    .order-tabs {
        column-gap: 20px;
        justify-content: flex-start;
        margin-bottom: 25px;
        p {
            font-size: 20px;
            font-family: $PoppinsSemiBold;
            width: 200px;
            text-align: center;
            padding: 8px;
            margin-bottom: 0;
            cursor: pointer;
        }
        .order-tab-active {
            background-color: $primary-background-color;
            color: #fff;
            position: relative;
            &::after {
                content: "";
                width: 0;
                height: 0;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-top: 11px solid $primary-background-color;
                clear: both;

                position: absolute;
                top: 48px;
                left: calc(50% - 10px);
            }
        }
    }
    .order-table {
        overflow: auto;
        padding: 1px;
        min-height: 435px;
        table {
            width: 100%;
            box-shadow: 0 0 0px 1px #d1d1d1;

            .table-header {
                background-color: #ececec;
                th {
                    font-size: 16px;

                    font-family: $PoppinsSemiBold;
                }
            }

            .order-table__item {
                border-bottom: 1px solid #d1d1d1;
                td {
                    font-size: 14px;
                    &:first-child {
                        font-family: $PoppinsSemiBold;
                    }
                }
            }

            .order-table__buttons {
                column-gap: 18px;
                button {
                    border-radius: 5px;
                    font-size: 14px;
                    padding: 5px;
                }
            }

            th,
            td {
                padding: 20px;
                white-space: nowrap;
            }
        }
    }
}
@include respond(small-laptop) {
    .orders {
        .order-tabs {
            p {
                font-size: 15px;
                width: 130px;
                padding: 5px;
            }
            .order-tab-active::after {
                top: 34px;
            }
        }
        .order-table {
            min-height: 300px;

            table {
                .table-header {
                    th {
                        font-size: 14px;
                    }
                }
                th,
                td {
                    padding: 10px;
                    font-size: 14px;
                }
                .order-table__buttons button {
                    font-size: 13px;
                    padding: 3px;
                }
            }
        }
    }
}
// Arabic Style Small laptop screen Start
.orders-arabic {
    .filter-bar {
        .date-section {
            .input-group {
                .input1 {
                    border-top-left-radius: 5px !important;
                    border-bottom-left-radius: 5px !important;
                    border-top-right-radius: 0 !important;
                    border-bottom-right-radius: 0 !important;
                    border-right: white 1px solid;
                }
                .input-group-text2 {
                    border-top-right-radius: 5px !important;
                    border-bottom-right-radius: 5px !important;
                    border-top-left-radius: 0px !important;
                    border-bottom-left-radius: 0px !important;
                }
            }
        }
    }
}
// Arabic Style Small laptop screen End

.status-label {
    max-width: 77px;
    overflow: hidden;
    text-overflow: ellipsis;
}
@include respond(phone) {
    .orders {
        .order {
            .first,
            .third {
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
            }
        }
    }
}
